import { createSlice } from '@reduxjs/toolkit';

const initialState = {  
  ejecucionInicio: false,
  ejecucionVentasPorProducto: false,
  ejecucionVentasPorTipoPago: false,
  paginaRender: "/",
};

export const ejecucionPaginasSlice = createSlice({
  name: 'ejecucionPaginas',
  initialState,
  reducers: {
    cambioPaginaRender: {
      reducer: (state, action) => {
        state.paginaRender = action.payload.page;
      },
      prepare: (page) => {
        return {
          payload: {
            page,
          }
        };
      }
    },
    ejecutarInicio: (state) => {
      state.ejecucionInicio = true;
    },
    ejecutarVentasPorProducto: (state) => {
      state.ejecucionVentasPorProducto = true;
    },
    ejecutarVentasPorTipoPago: (state) => {
      state.ejecucionVentasPorTipoPago = true;
    }
  }
});

export const { cambioPaginaRender, ejecutarInicio, ejecutarVentasPorProducto, ejecutarVentasPorTipoPago, ejecucionVentasPorProducto, ejecucionVentasPorTipoPago } = ejecucionPaginasSlice.actions;

export default ejecucionPaginasSlice.reducer;