import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@mui/styles";
import MultipleEmails from "../MultipleEmails/MultipleEmails";
import { Grid, TextField } from "@mui/material";
import Buton from "../Button/Button";
import SendIcon from '@mui/icons-material/Send';
import { showSendChartModal } from "../../../store/slices/modalsSlice";
import { enviarGraficosPorMail } from "../../../store/slices/enviarGraficosSlice";
import html2canvas from 'html2canvas';
import { enqueueSnackbar } from "../../../store/slices/snackbarSlice";

const useStyles = makeStyles({
  subject: {
    width: '100%',
    fontSize: 'small',
  },
  message: {
    width: '100%',
    fontSize: 'small',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  spinnerItems: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  graficos: {
    minWidth: 'fit-content'
  }
});

const EnviarChartEmailModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const chart2Ref = useRef(null);

  //#region Dispatches
  const chartRef = useSelector(state => state.modals.sendChartModal.chartRef);
  const tituloChart = useSelector(state => state.modals.sendChartModal.titulo);
  const enviarGraficos = (data, success) => dispatch(enviarGraficosPorMail({ data, success }));
  const closeChartModal = () => dispatch(showSendChartModal(false, null));  
  const sendNotification = (notification) => dispatch(enqueueSnackbar(notification));

  //#endregion

  const userEmail = sessionStorage.getItem("userEmail");
  const [chartHTML, setChartHTML] = useState(null);
  const [requiredMessage] = useState("Campo requerido");
  const [emails, setEmails] = useState([userEmail]);
  const [subject, setSubject] = useState(tituloChart !== '' ? `Enviar gráfico - ${tituloChart}` : 'Enviar gráfico');
  const [message, setMessage] = useState("");
  const [errorEmails, setErrorEmails] = useState(false);
  const [errorSubject, setErrorSubject] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [sending, setSending] = useState(false);

  const isvalid = (item) => {
    if (item === null || item === undefined) {
      return false;
    } else {
      if (typeof item === 'string' || item instanceof String) {
        const itemTrim = item.trim();

        if (itemTrim === "") {
          return false;
        }
      }
    }

    return true;
  }

  const formDisabled = () => {
    return errorEmails || errorSubject || errorSubject || !emails || emails.length === 0 || !isvalid(subject) || !isvalid(message);
  }

  const removeNodesFromChart = useCallback((stringContent) => {
    const el = document.createElement('div');
    el.innerHTML = stringContent;
    const toolBars = el.querySelectorAll('.apexcharts-toolbar');

    for (let toolBar of toolBars) {
      toolBar.remove();
    }

    const itemsToRemove = el.querySelectorAll(".remove-send-chart");

    for (let itemToRemove of itemsToRemove) {
      itemToRemove.remove();
    }

    return el.outerHTML;
  }, []);

  useEffect(() => {
    if (chartHTML === null && chartRef && chartRef.current) {
      let tempHTML = removeNodesFromChart(chartRef.current.outerHTML);
      setChartHTML(tempHTML);
    }
  }, [chartHTML, chartRef, removeNodesFromChart])

  const onEmailsChange = useCallback((items) => {
    setEmails(items);
    setErrorEmails(items === null || items.length === 0);
  }, []);

  const onSubjectChange = useCallback((evt) => {
    const value = evt.target.value;
    setErrorSubject(!isvalid(value));
    setSubject(value);
  }, []);

  const onMessageChange = useCallback((evt) => {
    const value = evt.target.value;
    setErrorMessage(!isvalid(value));
    setMessage(value);
  }, []);

  const onSendChartSuccess = () => {
    closeChartModal();
    setTimeout(() => setSending(false), 500);
  }

  const onSendChart = () => {
    setSending(true);
    setErrorSubject(!isvalid(subject));
    setErrorMessage(!isvalid(message));
    setErrorEmails(emails === null || emails.length === 0);

    if (!errorEmails && !errorSubject && !errorMessage && chart2Ref) {
      sendImage(chart2Ref.current);
    }
  }

  const sendImage = (element) => {
    setTimeout(() => {
      html2canvas(element, { logging: false })
        .then(function (canvas) {
          canvas.getContext('2d');
          const dataUrl = canvas.toDataURL('image/png', 1);

          const data = {
            destinatarios: emails,
            asunto: subject,
            mensaje: message,
            graficos: [
              { filename: 'chart.png', content: dataUrl, type: 'image/png' }],
          };

          enviarGraficos(data, onSendChartSuccess);
        })
        .catch((error) => {
          console.log(error);
          sendNotification({
            message: `Error al generar el PDF, intente nuevamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          });
        })
        .finally(() => closeChartModal());

    }, 1000);
  }

  return (
    <Grid container spacing={1} >
      <Grid item xs={12} sm={12} md={12}>
        <MultipleEmails
          items={emails}
          setItems={onEmailsChange}
          errorItems={errorEmails}
          errorItemsMessage={requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          label="Asunto"
          variant="outlined"
          size="small"
          className={classes.subject}
          value={subject}
          onChange={(e) => onSubjectChange(e)}
          required={true}
          error={errorSubject}
          helperText={errorSubject && requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          label="Mensaje"
          variant="outlined"
          size="small"
          className={classes.subject}
          value={message}
          multiline
          rows={4}
          onChange={(e) => onMessageChange(e)}
          required={true}
          inputProps={{
            maxLength: 500,
          }}
          error={errorMessage}
          helperText={errorSubject && requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} container className={classes.buttonContainer} >
        <Grid item xs={6} sm={4} md={3}>
          <Buton
            style={{ width: '95%', padding: "4px" }}
            color="primary"
            startIcon={<SendIcon />}
            onClick={onSendChart}
            disabled={sending || formDisabled()}
          >
            Enviar
          </Buton>
        </Grid>
      </Grid>
      <Grid ref={chart2Ref} item xs={12} sm={12} md={12} className={classes.graficos}>
        <div dangerouslySetInnerHTML={{ __html: chartHTML }} />
      </Grid>
    </Grid>
  )
}

export default EnviarChartEmailModal;