import React from "react";
import classes from './SinConfigurar.module.css';
import { Grid, Typography } from "@mui/material";
import JaliscoLogo from '../../assets/images/Jalisco-Logo.png';
import GaussLogo from '../../assets/images/LogoGauss.png';
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";

const SinConfigurar = () => {
  sessionStorage.setItem('seccionNombre', 'Inicio');

  return (
    <ConteinerWithEjecutarPagina
      showMultiempresa={true}
      isExecuted={false}
    >
      <Grid container className={classes.SinConfigurarWrapper}>
        <Grid item xs={12} lg={6} container display='flex' direction='row' justifyContent='center' alignContent='center'
          className={classes.SinConfigurarContainer}>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignContent: 'center' }}>
            <Typography className={classes.TextoInfo}>Esta aplicación le muestra todas las ventas de su farmacia en forma clara y ágil.</Typography>
            <Typography className={classes.TextoInfo}>Podrá ver la información desglosada por laboratorio, departamento, entre otras muchas opciones. </Typography>
            <Typography className={classes.TextoInfo}>Para habilitar la aplicación o solicitar una demo, debe comunicarse con Gauss Sistemas.</Typography>
          </Grid>
          <Grid item xs={12} container display='flex' direction='row' justifyContent='space-between' alignItems='flex-end' style={{ marginTop: '20px' }}>
            <Grid item xs={4} lg={2}>
              <img alt="" src={JaliscoLogo} width='100%' />
            </Grid>
            <Grid item xs={4} lg={2}>
              <img alt="" src={GaussLogo} width='auto' />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConteinerWithEjecutarPagina>
  )
}

export default SinConfigurar;