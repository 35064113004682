import React, { useCallback, useEffect } from "react";
import Dialog from "../../UI/Dialog/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import classes from "./ListaFiltrosPorIndicadorModal.module.css";
import Spinner from "../../UI/Spinner/Spinner";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { storageEmpresaId } from "../../../assets/shared/sessionData";
import { useSelector } from "react-redux";
import { showListFiltersModal } from "../../../store/slices/modalsSlice";
import { useDispatch } from "react-redux";
import { loadComprobantesVentas } from "../../../store/slices/comprobantesSlice";

const ListaFiltrosPorIndicadorModal = (props) => {
  const dispatch = useDispatch();
  const { disableCloseButton, fullWidth, maxWidth, } = props;

  const show = useSelector(state => state.modals.listFiltersModal.show);
  const title = useSelector(state =>  state.modals.listFiltersModal.title);
  const filterTypes = useSelector(state => state.modals.listFiltersModal.filterTypes);

  const ventasFiltros = useSelector(state => state.comprobantes.comprobantesVentas.comprobantes);
  const ventasLoading = useSelector(state => state.comprobantes.comprobantesVentas.loading);
  const ventasEstaCargado = useSelector(state => state.comprobantes.comprobantesVentas.estaCargado);
  const venta = filterTypes && filterTypes.includes("venta");

  const onCloseShowModal = useCallback(() => {
    dispatch(showListFiltersModal(false, '', ['venta']));
  }, [dispatch]);
  const onLoadFiltrosVentas = useCallback(() => {
    dispatch(loadComprobantesVentas(storageEmpresaId()));
  }, [dispatch]);

  useEffect(() => {
    if (venta && !ventasEstaCargado)
      onLoadFiltrosVentas();
  }, [venta, ventasEstaCargado]);

  const filtrarSeleccionados = (loading, datas, columnTitle) => {
    const filtrosSeleccionados = datas.filter(
      (data) => data.estaSeleccionado === false
    );

    const listarFiltros = [];
    for (var i = 0; i <= filtrosSeleccionados.length / 12; i++) {
      listarFiltros.push(filtrosSeleccionados.slice(i * 12, i * 12 + 12));
    }

    return (
      <Accordion classes={{ root: classes.AccordionRoot }} square>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={columnTitle}>
          <Typography variant="button">{columnTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {loading ? (
            <Spinner personalSize="20px" />
          ) : filtrosSeleccionados.length === 0 ? (
            "No hay filtros activos"
          ) : (
            listarFiltros.map((filtros, key) => {
              return (
                <div key={key} style={{ marginLeft: key !== 0 && "15px" }}>
                  {filtros.map((filtro, index) => {
                    return <div key={index}>{filtro.nombre}</div>;
                  })}
                </div>
              );
            })
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const body = () => {
    return (
      <div>
        {venta &&
          filtrarSeleccionados(
            ventasLoading,
            ventasFiltros,
            "Filtros comprobantes de venta"
          )}
      </div>
    );
  };

  return (
    <Dialog
      title={
        <div classes={classes.TitleDialogContent} style={{ width: "100%" }}>
          <div className={classes.TitleDialogTexto}>
            <Typography variant="h5" style={{ paddingRight: "10px" }}>
              {title}
            </Typography>
            {!disableCloseButton && (
              <div>
                <IconButton
                  aria-label="close"
                  onClick={() => onCloseShowModal()}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
          </div>
          <div className={classes.subtitleTexto}>
            <Typography variant="caption">
              Filtros aplicados sobre el indicador
            </Typography>
          </div>
        </div>
      }
      fullWidth={fullWidth}
      maxWidth={maxWidth ? maxWidth : false}
      show={show}
      body={body()}
      onClose={() => onCloseShowModal()}
    />
  );
};

export default ListaFiltrosPorIndicadorModal;