import React, { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const CustomSwitch = (props) => {
  const { value, onChange, title, firstLabel, secondLabel, color } = props;
  const [checked, setChecked] = useState(value);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <FormGroup>
      <Typography component="div" style={{ textAlign: 'center' }}>
        <Grid container justifyContent='center' alignItems="center" spacing={1}>
          <Grid item xs={12} container spacing={1} justifyContent='center' alignItems="center">
            <Grid item style={{ fontSize: 'small' }}>{title}</Grid>
          </Grid>          
          <Grid item xs={12} container spacing={1} justifyContent='center' alignItems="center" component="label">
            <Grid item style={{ fontSize: 'small' }}>{firstLabel}</Grid>
            <Grid item>
              <Switch
                checked={checked}
                onChange={handleChange}
                color={ color ? color : "primary" }
                name="customSwitch"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
            <Grid item style={{ fontSize: 'small', padding: '0px' }}>{secondLabel}</Grid>
          </Grid>
        </Grid>
      </Typography>
    </FormGroup>
  )
}

export default CustomSwitch;