import React, { Fragment, useCallback, useState, useEffect } from "react";
import classes from "./NavigationItems.module.css";

import List from "@mui/material/List";
import DashboardIcon from '@mui/icons-material/Dashboard';
import NavigationItem from "../NavigationItem/NavigationItem";
import { useSelector } from 'react-redux';
import { appName, getUserInfo, userDemo, userGauss } from "../../../assets/shared/sessionData";

const NavigationItems = (props) => {
  const isAuth = useSelector(state => state.auth.token) !== null;
  const userData = useSelector(state => state.auth.userInfo);

  const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];

    if(userInfo !== null && userInfo !== undefined 
      && userInfo.perfil !== null && userInfo.perfil !== undefined
      && userInfo.perfil.perfilAcceso !== null && userInfo.perfil.perfilAcceso !== undefined) {
        const perfilesAccesos = userInfo.perfil.perfilAcceso;
        const filtrados = (userGauss() || userDemo())
          ? perfilesAccesos
          : perfilesAccesos.filter((pa) => pa.aplicacionKey === appName());
        
        if(filtrados)
          filtrados.forEach((fil) => items.push(fil.key));        
    }
    setSecciones(items);
  }, []);

  useEffect(() => {
    if(userInfo === null || userInfo === undefined || userInfo.id === null || userInfo.id === undefined){
      const item = userData !== null && userData !== undefined 
        && userData.id !== null && userData.id !== undefined
        ? userData
        : getUserInfo();   
      setUserInfo(item);
      obtenerSecciones(item);
    }
  }, [userData, userInfo, setUserInfo, obtenerSecciones]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec === key);
    return userGauss() || userDemo() || (seccion !== null && seccion !== undefined);
  }

  return (
    <Fragment>
      <List className={classes.NavigationItems}>
        {isAuth && (
          <React.Fragment>
            {tieneAcceso('ventasProducto') && (
              <NavigationItem link="/ventasProducto" title="Ventas por Producto"
                onClickItem={props.onClickItem}>
                <DashboardIcon className={classes.NavigationItemIcon} />
              </NavigationItem>
            )}
          </React.Fragment>
        )}
      </List>
    </Fragment> 
  );
};

export default NavigationItems;