import { createSlice } from '@reduxjs/toolkit';

const initialState = {

};

export const actualizarPaginasSlice = createSlice({
  name: 'actualizarPaginas',
  initialState,
  reducers: {
    actualizarIndicadoresInicio: {
      reducer: (state, action) => {
      },
      prepare: () => {
        return {
        };
      }
    },
    actualizarIndicadoresVentasPorProducto: {
      reducer: (state, action) => {
      },
      prepare: () => {
        return {
        };
      }
    },
    actualizarIndicadoresVentasPorTipoPago: {
      reducer: (state, action) => {
      },
      prepare: () => {
        return {
        };
      }
    }
  },
});

export const { actualizarIndicadoresInicio, actualizarIndicadoresVentasPorProducto,
  actualizarIndicadoresVentasPorTipoPago
} = actualizarPaginasSlice.actions;

export default actualizarPaginasSlice.reducer;