import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch } from 'react-redux';
import DateRangeIcon from "@mui/icons-material/DateRange";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Dialog from "../../UI/Dialog/Dialog";
import Button from "../../UI/Button/Button";
import { storageFechaFija } from "../../../assets/shared/sessionData";

import moment from "moment";
import "moment/locale/es";
import { Fragment } from "react";
import { compareFechaActualFechaFija } from "../../../assets/shared/fechas";
import LightTooltip from "../../UI/LightTooltip/LightTooltip";
import { Typography, TextField } from "@mui/material";
import { cambiarFechaFija } from "../../../store/slices/configuracionesSlice";

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from "@mui/x-date-pickers";

moment.locale("es");

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'white',
    }
  },
  toolbar: {
    backgroundColor: '#00bcd4',
    '& .MuiPickersToolbar-toolbar': {
      backgroundColor: '#00bcd4',
    }
  }
}));

const FechaFijaSelector = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(new Date(storageFechaFija()));
  const [showCalendar, setShowCalendar] = useState(false);

  const onCambiarFechaFija = (nuevaFechaFija) => dispatch(cambiarFechaFija(nuevaFechaFija));

  const onChangeFechaFijaClick = () => {
    onCambiarFechaFija(new Date(selectedDate));
  };

  const handleFechaFijaChange = (date) => {
    setSelectedDate(date);
  };

  const onCloseClick = () => {
    setSelectedDate(new Date(storageFechaFija()));
    setShowCalendar(false);
  };

  const onHoyFechaFijaClick = () => {
    onCambiarFechaFija(new Date());
  };

  return (
    <Fragment>
      <div className={classes.root}
        onClick={() => !props.disabled && setShowCalendar(true)}
        disabled={props.disabled ? props.disabled : false}
      >
        <LightTooltip
          arrow
          title={
            <Typography className={classes.tooltip}>
              Fecha seleccionada: {moment(selectedDate).format("DD/MM/YYYY")}
            </Typography>
          }
        >
          <DateRangeIcon
            className={classes.icon}
            style={{
              color: props.color,
              fontSize: props.iconSize,
            }}
          />
        </LightTooltip>
        <Typography className={classes.iconMobile}>
          Fecha selec.: {moment(storageFechaFija()).format('DD/MM/YYYY')}
        </Typography>
      </div>
      {showCalendar && (
        <Dialog
          show={showCalendar}
          title={
            <div style={{ width: "98%", textAlign: "right" }}>
              <IconButton aria-label="close" onClick={() => onCloseClick()}>
                <CloseIcon />
              </IconButton>
            </div>
          }
          onClose={() => onCloseClick()}
          body={
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale={"es"}
            >
              <StaticDatePicker
                autoOk
                maxDate={new Date()}
                onChange={(date) => handleFechaFijaChange(date)}
                openTo="day"
                value={moment(selectedDate.toISOString())}
                renderInput={(params) => <TextField {...params} />}
                orientation='landscape'
                toolbarTitle={selectedDate && moment(selectedDate.toISOString()).year()}
                toolbarFormat={"ddd, MMM DD"}
                componentsProps={{
                  actionBar: { actions: [] },
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  float: "right",
                }}
              >
                {!compareFechaActualFechaFija() && (
                  <Button
                    size="small"
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      opacity: 0.5,
                      border: "none",
                    }}
                    onClick={() => onHoyFechaFijaClick()}
                  >
                    Hoy
                  </Button>
                )}
                <Button onClick={() => onChangeFechaFijaClick()}>
                  Aceptar
                </Button>
              </div>
            </LocalizationProvider>
          }
        />
      )}
    </Fragment>
  );
};

export default FechaFijaSelector;