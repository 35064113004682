import React, { useCallback, useEffect, useState } from "react";
import { storageEmpresaId, storageUsuarioId } from "../../../../assets/shared/sessionData";
import { Button, Grid, Typography } from "@mui/material";
import ButtonPivotGrid from "../ButtonPivotGrid/ButtonPivotGrid";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Table from '../../Table/Table';
import SaveIcon from '@mui/icons-material/Save';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { pivotGridInformacionesItems, pivotGridInformacionesLoading, loadPivotGridInformaciones, guardarPivotGridInformacion } from '../../../../store/slices/pivotGrid/pivotGridConfiguracionSlice';
import { selectedTotalRowsState } from "../../../../store/slices/pivotGrid/pivotGridDataSlice";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  actionButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none !important',
    padding: '8px',
    minWidth: '10px',
    minHeight: '10px',
    borderRadius: '50%'
  }
}));

const AdministrarCubo = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pivotGridKey, pivotGridRef, onClose } = props;
  const pivotGridInformaciones = useSelector(state => pivotGridInformacionesItems(state, pivotGridKey));
  const pivotGridLoading = useSelector(state => pivotGridInformacionesLoading(state, pivotGridKey));
  const selectedTotalRows = useSelector(state => selectedTotalRowsState(state));
  const { onLoadData, onCargarFormato, onUpdateDataSource } = props;
  const { datos, filtros } = props;
  const [idUsuario] = useState(storageUsuarioId());
  const [formatData, setFormatData] = useState({});

  const onLoadInformaciones = useCallback((key, idUsuario) => {
    dispatch(loadPivotGridInformaciones({ key, idUsuario }));
  }, [dispatch]);
  const onSaveInformacion = useCallback((configuracion, onSuccess, onError) => {
    dispatch(guardarPivotGridInformacion(configuracion, onSuccess, onError));
  }, [dispatch]);

  const selectedRows = useCallback(() => {
    let rows = selectedTotalRows
      ? selectedTotalRows.find((i) => i.key === pivotGridKey)
        ? selectedTotalRows.find((i) => i.key === pivotGridKey).rows
        : []
      : [];
    return JSON.stringify(rows);
  }, [selectedTotalRows, pivotGridKey]);

  useEffect(() => {
    if (!pivotGridInformaciones || (pivotGridInformaciones && !pivotGridInformaciones.estaCargado && !pivotGridLoading))
      onLoadInformaciones(pivotGridKey, idUsuario);
  }, [pivotGridInformaciones, pivotGridKey, idUsuario, onLoadInformaciones, pivotGridLoading]);

  // NEW
  const cellStyle = {
    fontSize: '11px',
    padding: '5px'
  };
  const headerStyle = {
    fontSize: '13px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220 220 220)'
  };
  const columns = [
    { title: 'ID', field: 'id', align: 'left', width: "5%", cellStyle: cellStyle, headerStyle: headerStyle, hidden: true },
    { title: 'Nombre', field: 'nombre', align: 'left', width: "60%", cellStyle: cellStyle, headerStyle: headerStyle, defaultSort: 'asc', validate: rowData => Boolean(rowData.nombre && rowData.nombre.trim()), },
    { title: 'Público*', field: 'esPublica', align: 'center', width: "10%", cellStyle: cellStyle, headerStyle: headerStyle, type: 'boolean' },
    { title: 'Creador', field: 'usuarioNombre', align: 'center', width: "15%", cellStyle: cellStyle, headerStyle: headerStyle, editable: 'never', type: 'string' },
    { title: 'Actualizado', field: 'fechaImportacion', align: 'center', width: "2%", cellStyle: cellStyle, headerStyle: headerStyle, editable: 'never', type: 'datetime' },
  ];

  const [isLoading, setIsLoading] = useState(false);

  const onCancelClick = () => {
    onClose();
  }

  //#region ACTUALIZAR CUBO
  // Actualizar cubo confirm dialog
  const [actualizarCuboConfirmDialogOpen, setActualizarCuboConfirmDialogOpen] = useState(false);

  const handleActualizarCuboConfirmDialogOpen = () => {
    setActualizarCuboConfirmDialogOpen(true);
  };

  const handleActualizarCuboConfirmDialogClose = () => {
    setActualizarCuboConfirmDialogOpen(false);
  };

  const actualizarCuboConfirmDialog = () => (
    <ConfirmDialog
      show={actualizarCuboConfirmDialogOpen}
      title="Actualizar Cubo"
      body="Si confirma la acción, se sobre escribirán los datos actuales con los nuevos."
      onConfirm={() => {
        handleActualizarCuboConfirmDialogClose();
        onActualizarCubo(formatData);
      }}
      onClose={handleActualizarCuboConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - confirm dialog

  const onActualizarSuccess = () => {
    setIsLoading(false);
    onLoadInformaciones(pivotGridKey, idUsuario);
    alert("La actualización de la estructura del cubo fue guardada correctamente");
  }

  const onActualizarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onActualizarCubo = (rowData) => {
    if (datos === null) {
      alert("No se puede actualizar el cubo debido a que no hay datos en el informe.");
      return;
    }

    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey) ?
          localStorage.getItem(pivotGridKey).fields : [];
      }

      let config = {
        id: rowData.id,
        nombre: rowData.nombre,
        key: pivotGridKey,
        informacion: JSON.stringify(datos),
        configuracion: JSON.stringify(fields),
        esPublica: rowData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: JSON.stringify(filtros),
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onActualizarSuccess, onActualizarFail);
    }
  }
  //#endregion ACTUALIZAR CUBO

  //#region USAR CUBO
  const onUsarCuboSuccess = () => {
    onUpdateDataSource();
    setIsLoading(false);
    onClose();
  }

  const onUsarCuboFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onUsarCubo = (rowData) => {

    if (rowData) {
      var form = document.getElementById("adminPivotGrid");
      form.classList.add('was-validated');

      if (form.checkValidity()) {
        setIsLoading(true);
        localStorage.removeItem(pivotGridKey);

        onCargarFormato(rowData);

        const request = {
          idEmpresa: storageEmpresaId(),
          idUsuario: idUsuario,
          importacion: true,
          idInformacion: rowData.id,
          nombre: rowData.nombre
        };

        if (rowData.filtro) {
          const filtroAplicado = JSON.parse(rowData.filtro);

          if (filtroAplicado) {
            request.semanaDesde = filtroAplicado.semanaDesde;
            request.semanaHasta = filtroAplicado.semanaHasta;
            request.anioDesde = filtroAplicado.anioDesde;
            request.anioHasta = filtroAplicado.anioHasta;
            request.anioComparacion = filtroAplicado.anioComparacion;
            request.desde = filtroAplicado.desde;
            request.hasta = filtroAplicado.hasta;
          }
        }

        onLoadData(request, onUsarCuboSuccess, onUsarCuboFail);
      }
    }
  }
  //#endregion USAR CUBO

  //#region EDITAR CUBO
  const onEditarSuccess = () => {
    onLoadInformaciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onEditarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onEditarCubo = (newData, oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: newData.nombre,
        key: pivotGridKey,
        informacion: oldData.informacion,
        configuracion: oldData.configuracion,
        esPublica: newData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: oldData.filtro,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onEditarSuccess, onEditarFail);
    }
    resolve();
  }
  //#endregion EDITAR CUBO

  //#region CREAR CUBO
  const onCrearSuccess = useCallback(() => {
    onLoadInformaciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }, [pivotGridKey, idUsuario, onLoadInformaciones]);

  const onCrearFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onCrearCubo = useCallback((newData, resolve) => {
    if (datos === null) {
      alert("No se puede crear el cubo debido a que no hay datos en el informe.");
      resolve();
      return;
    }

    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey) ?
          localStorage.getItem(pivotGridKey).fields : [];
      }

      let config = {
        id: null,
        nombre: newData.nombre,
        key: pivotGridKey,
        informacion: JSON.stringify(datos),
        configuracion: JSON.stringify(fields),
        esPublica: newData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: JSON.stringify(filtros),
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onCrearSuccess, onCrearFail);
    }
    resolve();
  }, [datos, pivotGridRef, pivotGridKey, idUsuario, filtros,
    onCrearSuccess, onSaveInformacion, selectedRows]);
  //#endregion CREAR CUBO

  //#region BORRAR CUBO
  const onBorrarSuccess = () => {
    onLoadInformaciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onBorrarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onBorrarCubo = (oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: oldData.nombre,
        key: pivotGridKey,
        informacion: oldData.informacion,
        configuracion: oldData.configuracion,
        esPublica: oldData.esPublica,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filtro: oldData.filtro,
        borrado: true,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveInformacion(config, onBorrarSuccess, onBorrarFail);
    }
    resolve();
  }
  //#endregion BORRAR CUBO

  const data = () => pivotGridInformaciones && pivotGridInformaciones.informaciones
    && pivotGridInformaciones.informaciones.filter(c => !c.borrado).map(o => ({ ...o }));

  return (
    <form id="adminPivotGrid" autoComplete="off">
      <Grid container>
        <Grid item xs={12} container spacing={1}
          justifyContent='space-between'>
          <Grid item xs={12} container alignContent='flex-start'>
            <Grid item xs={12} >
              <Typography variant="h5" fontWeight={'600'}>
                Administrar Cubos
              </Typography>
              <Typography variant="h6" align="justify" fontSize={'small'} fontWeight={'400'}>
                El cubo son los datos y el formato armado por usted.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Table
                style={{ width: '100%' }}
                title=""
                noBoxShadow
                columns={columns}
                data={data()}
                loading={isLoading || pivotGridLoading}
                editable={{
                  isEditable: rowData => rowData.usuarioId === idUsuario,
                  isDeletable: rowData => rowData.usuarioId === idUsuario,
                  editTooltip: rowData => "edit",
                  deleteTooltip: rowData => "delete",
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve) => {
                      onEditarCubo(newData, oldData, resolve);
                    }),
                  onRowAdd: (newData) =>
                    new Promise((resolve) => {
                      onCrearCubo(newData, resolve);
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve) => {
                      onBorrarCubo(oldData, resolve);
                    }),
                }}
                actions={[
                  {
                    icon: 'save',
                    tooltip: "Actualizar cubo",
                    onClick: (event, rowData) => {
                      setFormatData(rowData)
                      handleActualizarCuboConfirmDialogOpen();
                    },
                  },
                  {
                    icon: 'apply',
                    tooltip: "Aplicar cubo",
                    onClick: (event, rowData) => onUsarCubo(rowData),
                  },
                ]}
                components={{
                  Action: (props) => {
                    var buttonKey = props.action && props.action.icon ? props.action.icon : props.action(props.data).tooltip;
                    const active = props.data && props.data.usuarioId === idUsuario;

                    if (buttonKey === "save") {
                      return (
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          variant="outlined"
                          size="small"
                          color="primary"
                          title={props.action.tooltip}
                          disabled={!active}
                          className={classes.actionButton}
                        >
                          <SaveIcon style={{ color: (active ? '#f88d37' : '#bdbdbd') }} />
                        </Button>
                      );
                    } else if (buttonKey === "apply") {
                      return (
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          variant="outlined"
                          size="small"
                          color="primary"
                          title={props.action.tooltip}
                          className={classes.actionButton}
                        >
                          <GetAppIcon style={{ color: '#10c5ff' }} />
                        </Button>
                      );
                    } else if (buttonKey === "edit") {
                      return (
                        <Button
                          onClick={(event) => props.action(props.data).onClick(event, props.data)}
                          variant="outlined"
                          size="small"
                          color="primary"
                          title="Editar cubo"
                          disabled={!active}
                          className={classes.actionButton}
                        >
                          <EditIcon style={{ color: '#626262' }} />
                        </Button>
                      );
                    } else if (buttonKey === "delete") {
                      return (
                        <Button
                          onClick={(event) => props.action(props.data).onClick(event, props.data)}
                          variant="outlined"
                          size="small"
                          color="primary"
                          title="Eliminar cubo"
                          disabled={!active}
                          className={classes.actionButton}
                        >
                          <DeleteOutlineIcon style={{ color: '#626262' }} />
                        </Button>
                      );
                    } else if (typeof buttonKey !== "string") {
                      buttonKey = props.action.tooltip;
                      return (
                        <Button
                          onClick={(event) => props.action.onClick(event, props.data)}
                          variant="outlined"
                          size="small"
                          color="primary"
                          title={props.action.tooltip}
                          className={classes.actionButton}
                        >
                          {buttonKey === "Nuevo"
                            ? (
                              <AddBoxIcon style={{ color: '#626262' }} />
                            ) : buttonKey === "Confirmar" ? (
                              <CheckIcon style={{ color: '#626262' }} />
                            ) : (
                              <CloseIcon style={{ color: '#626262' }} />
                            )
                          }
                        </Button>
                      );
                    }
                  }
                }}
                customOptions={{
                  thirdSortClick: false,
                  sorting: true,
                  grouping: false,
                  draggable: false,
                  search: false,
                  paging: true,
                  toolbar: true,
                  showTitle: false,
                  maxBodyHeight: 320,
                  addRowPosition: 'first',
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50]
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontSize: 'x-small' }}>
              *Tildando esta opción, usted estará compartiendo el cubo con toda la organización, para que puedan acceder a visualizarlo.
            </Typography>
          </Grid>
          <Grid item xs={12} container display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
            <Grid item xs={5} md={3}>
              <ButtonPivotGrid
                tooltipTitle="Cerrar ventana"
                buttonSize="small"
                text="Cerrar"
                disabled={false}
                onClick={onCancelClick}
                loading={false}
                icon={<CloseOutlinedIcon fontSize="small" />}
                backgroundImage="linear-gradient(45deg, #fbb040, #f15a29)"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {actualizarCuboConfirmDialog()}
    </form>
  )
}

export default AdministrarCubo;