import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import actualizarPaginasReducer from "../store/slices/actualizarPaginasSlice";
import authReducer from "../store/slices/authSlice";
import configuracionesReducer from "../store/slices/configuracionesSlice";
import ejecucionPaginasReducer from "../store/slices/ejecucionPaginasSlice";
import modalsReducer from "../store/slices/modalsSlice";
import multiempresaReducer from "../store/slices/multiempresaSlice";
import enviarGraficosReducer from "../store/slices/enviarGraficosSlice";
import snackbarReducer from "../store/slices/snackbarSlice";
import sucursalesReducer from "../store/slices/sucursalesSlice";
import ultimaEjecucionReducer from "../store/slices/ultimaEjecucionSlice";
import monedasReducer from "../store/slices/monedaSlice";
import ventasDesagregadasReducer from './slices/ventas/ventasDesagregadasSlice';
import pivotGridDataSlice from './slices/pivotGrid/pivotGridDataSlice';
import pivotGridConfiguracionSlice from './slices/pivotGrid/pivotGridConfiguracionSlice';
import comprobantesSlice from './slices/comprobantesSlice';
import ventasPorProductoSlice from './slices/ventas/ventasPorProductoSlice';
import ventasDesagregadasPorProductoSlice from './slices/ventas/ventasDesagregadasPorProductoSlice';

export const store = configureStore({
  reducer: {
    actualizarPaginas: actualizarPaginasReducer,
    auth: authReducer,
    configuraciones: configuracionesReducer,
    ejecucionPaginas: ejecucionPaginasReducer,
    modals: modalsReducer,
    monedas: monedasReducer,
    multiempresa: multiempresaReducer,
    enviarGraficos: enviarGraficosReducer,
    snackbar: snackbarReducer,
    sucursales: sucursalesReducer,
    ultimaEjecucion: ultimaEjecucionReducer,
    ventasDesagregadas: ventasDesagregadasReducer,
    ventasDesagregadasPorProducto: ventasDesagregadasPorProductoSlice,
    pivotGridData: pivotGridDataSlice,
    pivotGridConfiguracion: pivotGridConfiguracionSlice,
    ventasPorProducto: ventasPorProductoSlice,
    comprobantes: comprobantesSlice
  },
  middleware: (getDefaultMiddleware)
});
