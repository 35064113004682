import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/es";
import Spinner from "../../../UI/Spinner/Spinner";
import FiltroFechaSelector from '../../../UI/FiltroFechaSelector/FiltroFechaSelector';
import { PlayCircleFilledWhiteOutlined } from "@mui/icons-material";
import ButtonPivotGrid from "../../../UI/CustomPivotGrid/ButtonPivotGrid/ButtonPivotGrid";

moment.locale("es");

const PieTableVentasFiltro = (props) => {
  const { aplicarFiltros } = props;
  const { filtros, onUpdateFiltros } = props;
  const [fechaDesde, setFechaDesde] = useState(null);
  const [loadingDesde, setLoadingDesde] = useState(false);
  const [fechaHasta, setFechaHasta] = useState(null);
  const [loadingHasta, setLoadingHasta] = useState(false);
  const [minDate] = useState(new Date(new Date().getFullYear() - 10, 0, 1));
  const [maxDate] = useState(new Date(new Date().getFullYear() + 10, 11, 31));
  const [cambiosAplicados, setCambiosAplicados] = useState(true);

  const habilitar = useCallback(() => {
    const dateValid = (fechaDesde !== null && fechaDesde !== undefined && fechaHasta !== null && fechaHasta !== undefined);
    return !dateValid;
  }, [fechaDesde, fechaHasta]);

  useEffect(() => {
    if (filtros && !fechaDesde && filtros.desde) {
      const tempDate = new Date(filtros.desde);

      if (tempDate !== fechaDesde) {
        setLoadingDesde(true);
        setFechaDesde(tempDate);
        setTimeout(() => setLoadingDesde(false), 100);
      }
    }
  }, [filtros, fechaDesde]);

  useEffect(() => {
    if (filtros && !fechaHasta && filtros.hasta) {
      const tempDate = new Date(filtros.hasta);

      if (tempDate !== fechaHasta) {
        setLoadingHasta(true);
        setFechaHasta(tempDate);
        setTimeout(() => setLoadingHasta(false), 100);
      }
    }
  }, [filtros, fechaHasta]);

  const onAplicarFiltroClick = () => {
    let desdeDate = fechaDesde ? new Date(fechaDesde) : null;
    let hastaDate = fechaHasta ? new Date(fechaHasta) : null;
    onUpdateFiltros(desdeDate, hastaDate);
    aplicarFiltros(desdeDate, hastaDate);
    setCambiosAplicados(true);
  }

  const onDesdeChange = (value) => {
    setFechaDesde(value)
    let hastaDate = fechaHasta ? new Date(fechaHasta) : null;
    onUpdateFiltros(value, hastaDate);
    setCambiosAplicados(false);
  };

  const onHastaChange = (value) => {
    setFechaHasta(value);
    let desdeDate = fechaDesde ? new Date(fechaDesde) : null;
    onUpdateFiltros(desdeDate, value);
    setCambiosAplicados(false);
  };

  return (
    <Fragment>
      <Grid item xs={12} md={10} lg={8} xl={6} container spacing={1} justifyContent="flex-start">
        <Grid item xs={6} md={3}>
          {loadingDesde ? (
            <Spinner size="small" />
          ) : (
            <FiltroFechaSelector
              label="Fecha desde"
              value={fechaDesde}
              onDateChange={(value) => onDesdeChange(value)}
              format="DD/MM/YYYY"
              minDate={minDate}
              maxDate={fechaHasta}
              required={true}
              helperText={fechaDesde ? null : "Este campo es requerido"}
              displayWeekNumber={true}
            />)
          }
        </Grid>
        <Grid item xs={6} md={3}>
          {loadingHasta ? (
            <Spinner size="small" />
          ) : (
            <FiltroFechaSelector
              label="Fecha hasta"
              value={fechaHasta}
              onDateChange={(value) => onHastaChange(value)}
              format="DD/MM/YYYY"
              minDate={fechaDesde}
              maxDate={maxDate}
              required={true}
              helperText={fechaHasta ? null : "Este campo es requerido"}
              displayWeekNumber={true}
            />)
          }
        </Grid>
        <Grid item xs={6} md={2}>
          <ButtonPivotGrid
            tooltipTitle="Aplicar filtros"
            buttonSize="small"
            text="Aplicar"
            onClick={onAplicarFiltroClick}
            loading={false}
            icon={<PlayCircleFilledWhiteOutlined fontSize="small" />}
            disabled={habilitar()}
            backgroundImage={cambiosAplicados ? undefined : "linear-gradient(45deg, #fbb040, #f15a29)"}
          />
        </Grid>
      </Grid>
    </Fragment>
  )
}

export default PieTableVentasFiltro;