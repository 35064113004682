import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  inicio: "",
  ventasPorProducto: "",
  ventasPorTipoPago: "",
};

export const ultimaEjecucionSlice = createSlice({
  name: 'ultimaEjecucion',
  initialState,
  reducers: {
    updateUltimaActualizacionInicio: {
      reducer: (state, action) => {
        state.inicio = `Inicio: ${action.payload.fechaHora}`;
      },
      prepare: (fechaHora) => {
        return { payload: { fechaHora } };
      }
    },
    clearUltimaActualizacionInicio: (state) => {
      state.inicio = "";
    },
    updateUltimaActualizacionVentasPorProducto: {
      reducer: (state, action) => {
        state.ventasPorProducto = `Ventas por Producto: ${action.payload.fechaHora}`;
      },
      prepare: (fechaHora) => {
        return { payload: { fechaHora } };
      }
    },
    clearUltimaActualizacionVentasPorProducto: (state) => {
      state.ventasPorProducto = "";
    },
    updateUltimaActualizacionVentasPorTipoPago: {
      reducer: (state, action) => {
        state.ventasPorTipoPago = `Ventas por Tipo de Pago: ${action.payload.fechaHora}`;
      },
      prepare: (fechaHora) => {
        return { payload: { fechaHora } };
      }
    },
    clearUltimaActualizacionVentasPorTipoPago: (state) => {
      state.ventasPorTipoPago = "";
    },
  },
});

export const { updateUltimaActualizacionInicio, clearUltimaActualizacionInicio,
  updateUltimaActualizacionVentasPorProducto, clearUltimaActualizacionVentasPorProducto,
  updateUltimaActualizacionVentasPorTipoPago, clearUltimaActualizacionVentasPorTipoPago
} = ultimaEjecucionSlice.actions;

export default ultimaEjecucionSlice.reducer;