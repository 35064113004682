import React from 'react';
import { IconButton } from '@mui/material';
import BackupTableIcon from '@mui/icons-material/BackupTable';

const AdminFormatIcon = (props) => {
  const { tooltip, iconSize, filterSize, rootClass, iconClass, onClick } = props;

  return (
    <span className={rootClass} title={tooltip ? tooltip : "Recuperar y grabar formatos - cubos "}>
      <IconButton
        className={iconClass} size={iconSize ? iconSize : "small"}
        onClick={onClick}>
        <BackupTableIcon fontSize={filterSize ? filterSize : "small"} />
      </IconButton>
    </span>
  )
}

export default AdminFormatIcon;