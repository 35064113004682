import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken } from "../../../assets/shared/sessionData";
import { baseUrlDashboard } from '../../../assets/shared/urlApps';
import moment from "moment";
import "moment/locale/es";
import axios from '../../../axios-dashboard';

moment.locale("es");

const initialState = {
  selectedTotalRows: {
    items: []
  },
  pivotGridConfiguracionFields: {
    items: []
  },
  ventasPorProductoData: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: null,
    config: null,
    anioInforme: null,
    filtros: {},
  },
  ventasPorProductoFiltros: {
    semanaDesde: null,
    semanaHasta: null,
    anioDesde: null,
    anioHasta: null,
    desde: null,
    hasta: null,
    anioComparacion: null,
  },
  ventasPorProductoFiltrosChart: {
    optionSelectedId: 'anio',
    fieldSelectedId: 'unidades',
    orderSelectedId: 'totalUnidades',
    extraFilters: undefined,
    relatedFilters: undefined,
  },
  ventasPorTipoPagoData: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: null,
    config: null,
    anioInforme: null,
    filtros: {},
  },
  ventasPorTipoPagoFiltros: {
    semanaDesde: null,
    semanaHasta: null,
    anioDesde: null,
    anioHasta: null,
    desde: null,
    hasta: null,
    anioComparacion: null,
  },
  ventasPorTipoPagoFiltrosChart: {
    optionSelectedId: 'anio',
    fieldSelectedId: 'unidades',
    orderSelectedId: 'totalUnidades',
    extraFilters: undefined,
    relatedFilters: undefined,
  },
};

export const loadVentasPorProducto = createAsyncThunk(
  'pivotGridData/loadVentasPorProducto',
  async ({ request, onSuccess = undefined, onFail = undefined, config = undefined }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios
      .post(`${baseUrlDashboard()}farmacias/GenerarCuboVentasPorProducto`, request, requestOptions);

    let filtro = {
      semanaDesde: request.semanaDesde,
      semanaHasta: request.semanaHasta,
      anioDesde: request.anioDesde,
      anioHasta: request.anioHasta,
      anioComparacion: request.anioComparacion
    };

    const result = {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      onSuccess,
      onFail,
      config,
      requestFiltro: filtro,
      request
    };
    return result;
  }
);

export const loadVentasPorTipoPago = createAsyncThunk(
  'pivotGridData/loadVentasPorTipoPago',
  async ({ request, onSuccess = undefined, onFail = undefined, config = undefined }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios
      .post(`${baseUrlDashboard()}farmacias/GenerarCuboVentasPorTipoPago`, request, requestOptions);

    let filtro = {
      semanaDesde: request.semanaDesde,
      semanaHasta: request.semanaHasta,
      anioDesde: request.anioDesde,
      anioHasta: request.anioHasta,
      anioComparacion: request.anioComparacion,
      desde: request.desde,
      hasta: request.hasta
    };

    const result = {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      onSuccess,
      onFail,
      config,
      requestFiltro: filtro,
    };

    return result;
  }
);

export const pivotGridDataSlice = createSlice({
  name: 'pivotGridData',
  initialState,
  reducers: {
    updateSelectedTotalRows: {
      reducer: (state, action) => {
        let tempItems = state.selectedTotalRows.items.filter((item) => item.key !== action.payload.key);

        let item = {
          key: action.payload.key,
          rows: action.payload.rows
        };

        tempItems.push(item);

        state.selectedTotalRows = {
          items: tempItems
        };
      },
      prepare: (key, rows) => {
        return {
          payload: {
            key,
            rows
          }
        }
      }
    },
    updateVentasPorProductoData: {
      reducer: (state, action) => {
        state.ventasPorProductoData.datos = action.payload.datos;
        state.ventasPorProductoData.config = action.payload.configuracion;
        state.ventasPorProductoData.filtros = action.payload.filtros;
      },
      prepare: (datos, configuracion, filtros) => {
        return {
          payload: { datos, configuracion, filtros }
        }
      }
    },
    clearVentasPorProductoData: (state) => {
      state.pivotGridConfiguracionFields = {
        items: []
      };
      state.ventasPorProductoData = {
        loading: false,
        estaCargado: false,
        error: null,
        datos: null,
        config: null,
        anioInforme: null,
        filtros: {},
      };
      state.ventasPorProductoFiltros = {
        semanaDesde: null,
        semanaHasta: null,
        anioDesde: null,
        anioHasta: null,
        desde: null,
        hasta: null,
        anioComparacion: null,
      };
      state.ventasPorProductoFiltrosChart = {
        optionSelectedId: 'anio',
        fieldSelectedId: 'unidades',
        orderSelectedId: 'totalUnidades',
        extraFilters: undefined,
        relatedFilters: undefined,
      };
    },
    updateVentasPorProductoFiltros: {
      reducer: (state, action) => {
        state.ventasPorProductoFiltros = action.payload;
      },
      prepare: (fechaDesde, fechaHasta, anioComparacion) => {
        return {
          payload: {
            semanaDesde: fechaDesde ? moment(fechaDesde).week() : 0,
            semanaHasta: fechaHasta ? moment(fechaHasta).week() : 0,
            anioDesde: fechaDesde ? fechaDesde.getFullYear() : new Date().getFullYear(),
            anioHasta: fechaHasta ? fechaHasta.getFullYear() : new Date().getFullYear(),
            desde: fechaDesde ? fechaDesde.toDateString() : null,
            hasta: fechaHasta ? fechaHasta.toDateString() : null,
            anioComparacion,
          }
        }
      }
    },
    updateVentasPorProductoFiltrosChart: {
      reducer: (state, action) => {
        state.ventasPorProductoFiltrosChart = action.payload.filtro;
      },
      prepare: (filtro) => {
        return {
          payload: { filtro }
        }
      }
    },
    updateVentasPorTipoPagoData: {
      reducer: (state, action) => {
        state.ventasPorTipoPagoData.datos = action.payload.datos;
        state.ventasPorTipoPagoData.config = action.payload.configuracion;
        state.ventasPorTipoPagoData.filtros = action.payload.filtros;
      },
      prepare: (datos, configuracion, filtros) => {
        return {
          payload: { datos, configuracion, filtros }
        }
      }
    },
    updateVentasPorTipoPagoFiltros: {
      reducer: (state, action) => {
        state.ventasPorTipoPagoFiltros = action.payload;
      },
      prepare: (fechaDesde, fechaHasta, anioComparacion) => {
        return {
          payload: {
            semanaDesde: fechaDesde ? moment(fechaDesde).week() : 0,
            semanaHasta: fechaHasta ? moment(fechaHasta).week() : 0,
            anioDesde: fechaDesde ? fechaDesde.getFullYear() : new Date().getFullYear(),
            anioHasta: fechaHasta ? fechaHasta.getFullYear() : new Date().getFullYear(),
            desde: fechaDesde ? fechaDesde.toDateString() : null,
            hasta: fechaHasta ? fechaHasta.toDateString() : null,
            anioComparacion,
          }
        }
      }
    },
    updateVentasPorTipoPagoFiltrosChart: {
      reducer: (state, action) => {
        state.ventasPorTipoPagoFiltrosChart = action.payload.filtro;
      },
      prepare: (filtro) => {
        return {
          payload: { filtro }
        }
      }
    },
    updateConfiguracionFields: {
      reducer: (state, action) => {
        let items = state.pivotGridConfiguracionFields.items
          .filter((item) => item.key !== action.key);

        let item = {
          key: action.key,
          config: action.config
        };

        items.push(item);

        state.pivotGridConfiguracionFields.items = items;
      },
      prepare: (key, config) => {
        return {
          payload: { key, config }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVentasPorProducto.pending, (state) => {
        state.ventasPorProductoData = {
          loading: true,
          error: null,
          datos: null,
          config: null,
          estaCargado: false,
          anioInforme: null,
          filtros: {}
        };
      })
      .addCase(loadVentasPorProducto.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          let datos = action.payload.data.datos ? action.payload.data.datos : [];
          let configuracion = action.payload.data.config ? JSON.parse(action.payload.data.config) : action.payload.config;
          let filtros = action.payload.data.filtros ? action.payload.data.filtros : action.payload.requestFiltro;

          state.ventasPorProductoData = {
            loading: false,
            error: null,
            datos,
            config: configuracion,
            estaCargado: true,
            anioInforme: filtros ? filtros.anioFiscal : null,
            filtros,
          };
          state.ventasPorProductoFiltros = filtros;

          //#region Sub Totales
          let key = action.payload.request.key;
          let tempItems = state.selectedTotalRows.items.filter((item) => item.key !== key);
          let selectedRows = action.payload.data.filasSubtotales ? action.payload.data.filasSubtotales : [];
          let item = { key, rows: selectedRows };

          tempItems.push(item);

          state.selectedTotalRows = { items: tempItems };
          //#endregion
          /*
                    filasSubtotales: selectedRows,
                        configuracion,
                        nombre: action.payload.request.nombre,
                        datos
          */
          if (action.payload.onSuccess)
            action.payload.onSuccess();
        }
      })
      .addCase(loadVentasPorProducto.rejected, (state, error) => {
        state.ventasPorProductoData.loading = false;
        state.ventasPorProductoData.error = error;
        state.ventasPorProductoData.estaCargado = true;
      })
      .addCase(loadVentasPorTipoPago.pending, (state) => {
        state.ventasPorTipoPagoData = {
          loading: true,
          error: null,
          datos: null,
          config: null,
          estaCargado: false,
          anioInforme: null,
          filtros: {}
        };
      })
      .addCase(loadVentasPorTipoPago.fulfilled, (state, action) => {
        if (action.payload.data) {
          let datos = action.payload.data.datos ? action.payload.data.datos : [];
          let configuracion = action.payload.data.config ? JSON.parse(action.payload.data.config) : action.config;
          let filtros = action.payload.data.filtros ? action.payload.data.filtros : action.requestFiltro;

          state.ventasPorTipoPagoData = {
            loading: false,
            error: null,
            datos,
            config: configuracion,
            estaCargado: true,
            anioInforme: filtros ? filtros.anioFiscal : null,
            filtros: action.payload.data.filtros ? action.payload.data.filtros : action.requestFiltro,
          };
          state.ventasPorTipoPagoFiltros = filtros;

          let selectedRows = action.payload.data.filasSubtotales ? action.payload.data.filasSubtotales : [];

          if (action.onSuccess) {
            const rowData = {
              filasSubtotales: selectedRows,
              configuracion,
              nombre: action.request.nombre,
              datos
            };

            action.onSuccess(rowData);
          }
        }
      })
      .addCase(loadVentasPorTipoPago.rejected, (state, error) => {
        state.ventasPorTipoPagoData.loading = false;
        state.ventasPorTipoPagoData.error = error;
        state.ventasPorTipoPagoData.estaCargado = true;
      })
  }
});

export const { updateSelectedTotalRows, updateVentasPorProductoData, clearVentasPorProductoData, updateVentasPorProductoFiltros,
  updateVentasPorTipoPagoData, updateVentasPorTipoPagoFiltros, updateConfiguracionFields,
  updateVentasPorProductoFiltrosChart, updateVentasPorTipoPagoFiltrosChart } = pivotGridDataSlice.actions;
export default pivotGridDataSlice.reducer;
export const selectedTotalRowsState = state => state.selectedTotalRows ? state.selectedTotalRows.items : [];
export const ventasPorProductoConfiguracion = state => state.pivotGridData.ventasPorProductoData.config;
export const ventasPorTipoPagoConfiguracion = state => state.pivotGridData.ventasPorTipoPagoData.config;