import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';

import { enqueueSnackbar } from './snackbarSlice';
import { baseUrlDashboard } from '../../assets/shared/urlApps';
import { storageToken } from '../../assets/shared/sessionData';

const initialState = {};

export const enviarGraficosPorMail = createAsyncThunk(
  'enviarGraficos/enviarGraficosPorMail',
  async ({ data, success }) => {
    const authStr = 'Bearer '.concat(storageToken());
    const requestOptions = {
      headers: {
        Authorization: authStr,
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };

    const response = axios
      .post(`${baseUrlDashboard()}notificaciones/EnviarGraficosPorMail`, data, requestOptions);

    if (success)
      success();

    return response;
  });

export const enviarArchivosPorMail = createAsyncThunk(
  'enviarGraficos/enviarArchivosPorMail',
  async ({ data, success, onError }) => {
    const authStr = 'Bearer '.concat(storageToken());
    const requestOptions = {
      headers: {
        Authorization: authStr,
        'Content-Type': 'multipart/form-data'
      }
    };

    const response = axios
      .post(`${baseUrlDashboard()}notificaciones/EnviarArchivosPorMail`, data, requestOptions);

    response
      .then((result) => {
        if (success)
          success(result);
      })
      .catch((error) => {
        console.log(error);

        if (onError)
          onError(error);
      });

    return response;
  });

export const enviarGraficosSlice = createSlice({
  name: 'enviarGraficos',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(enviarGraficosPorMail.pending, (state) => { })
      .addCase(enviarGraficosPorMail.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(enviarGraficosPorMail.rejected, (state, error) => {
        console.log(error);
      })

      .addCase(enviarArchivosPorMail.pending, (state) => { })
      .addCase(enviarArchivosPorMail.fulfilled, (state, action) => {
        console.log(action);
      })
      .addCase(enviarArchivosPorMail.rejected, (state, error) => {
        console.log(error);
      })
  }
});

export default enviarGraficosSlice.reducer;