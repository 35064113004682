import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageEmpresaId, storageMonedaId, storageToken } from "../../../assets/shared/sessionData";
import axios from '../../../axios-dashboard';
import moment from "moment";
import "moment/locale/es";
import idIndicadores from "../../../assets/shared/idIndicadores";
import { baseUrlDashboard } from "../../../assets/shared/urlApps";

const initialState = {
  tipoVentasDesagregadasSelected: 'laboratorio',
  filtros: {
    semanaDesde: null,
    semanaHasta: null,
    anioDesde: null,
    anioHasta: null,
    desde: null,
    hasta: null,
    aplicado: false,
  },
  ventasDesagregadas: {
    loading: false,
    estaCargado: false,
    error: null,
    ventas: null,
    totalMonto: 0,
    totalUnidades: 0
  },
  exportVentasDesagregadas: {
    loading: false,
    estaCargado: false,
    error: null,
    ventas: null
  }
}

export const loadVentasDesagregadasDelMesPorTipoDeVenta = createAsyncThunk(
  'ventasDesagregadasPorProducto/loadVentasDesagregadasDelMesPorTipoDeVenta',
  async ({ fechaDesde, fechaHasta }, { getState }) => {
    const authStr = 'Bearer '.concat(storageToken());
    const requestOptions = {
      headers: {
        Authorization: authStr,
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const state = getState();
    let tipoVenta = state.ventasDesagregadasPorProducto.tipoVentasDesagregadasSelected;
    let semanaDesde = fechaDesde ? fechaDesde.getMonth() === 0 && fechaDesde.getDate() === 1 ? 1 : moment(fechaDesde).week() : 0;
    let semanaHasta = fechaHasta ? moment(fechaHasta).week() : 0;
    let anioDesde = fechaDesde ? fechaDesde.getFullYear() : new Date().getFullYear();
    let anioHasta = fechaHasta ? fechaHasta.getFullYear() : new Date().getFullYear();
    let idMoneda = storageMonedaId();
    let idEmpresa = storageEmpresaId();

    const url = `${baseUrlDashboard()}farmacias/ventasDesagregadasDelMesPorTipo?tipoVentas=${tipoVenta}` +
      `&fechaDesde=${fechaDesde.toDateString()}&fechaHasta=${fechaHasta.toDateString()}&idMoneda=${idMoneda}` +
      `&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDesagregadasPorTipoPorProducto}`;
    const response = await axios.get(url, requestOptions);

    let filtros = {
      semanaDesde,
      semanaHasta,
      anioDesde,
      anioHasta,
      desde: fechaDesde ? fechaDesde.toDateString() : null,
      hasta: fechaHasta ? fechaHasta.toDateString() : null,
      aplicado: true,
    };

    const result = {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      filtros,
    };

    return result;
  }
);

export const loadVentasDesagregadasDelMesPorTipoPorId = createAsyncThunk(
  'ventasDesagregadasPorProducto/loadVentasDesagregadasDelMesPorTipoPorId',
  async () => {
    const authStr = 'Bearer '.concat(storageToken());

  }
);

export const loadVentasDesagregadasPorComprobantesPorTipoVenta = createAsyncThunk(
  'ventasDesagregadasPorProducto/loadVentasDesagregadasPorComprobantesPorTipoVenta',
  async () => {
    const authStr = 'Bearer '.concat(storageToken());

  }
);

export const loadVentasDesagregadasDelMesPorTipoDeVentaYSubNivel = (id, tipoParent, tipoChild,
  fechaDesde, fechaHasta, filtrosParent, success, errorFail
) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());
    const requestOptions = {
      headers: {
        Authorization: authStr,
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };

    let idMoneda = storageMonedaId();
    let idEmpresa = storageEmpresaId();

    const url = `${baseUrlDashboard()}farmacias/VentasDesagregadasDelMesPorTipoPorId?tipoParent=${tipoParent}&tipoChild=${tipoChild}&idParent=${id}` +
      `&fechaDesde=${fechaDesde.toDateString()}&fechaHasta=${fechaHasta.toDateString()}&idMoneda=${idMoneda}` +
      `&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDesagregadasPorTipoPorProducto}`;

    axios
      .post(url, filtrosParent, requestOptions)
      .then((response) => {
        const ventas = response.data.ventas;
        const totalVentas = response.data.totalMonto;
        const totalUnidades = response.data.totalUnidades;
        const ventasNegativo = ventas.filter((rd) => rd.monto < 0);
        const fecha = ventas.length > 0 ? new Date(ventas[0].anio, ventas[0].mes - 1, 1) : new Date();
        return success(ventas, ventasNegativo, totalVentas, fecha, totalUnidades);
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  };
};

export const exportVentasDesagregadasPorTipoDeVentas = createAsyncThunk(
  'ventasDesagregadasPorProducto/exportVentasDesagregadasPorTipoDeVentas',
  async ({ tipoParent, tipoChild, fechaDesde, fechaHasta }) => {

    const authStr = 'Bearer '.concat(storageToken());
    const requestOptions = {
      headers: {
        Authorization: authStr,
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };

    let idMoneda = storageMonedaId();
    let idEmpresa = storageEmpresaId();

    const url = `${baseUrlDashboard()}farmacias/ExportVentasDesagregadasPorTipo?tipoParent=${tipoParent}&tipoChild=${tipoChild}` +
      `&fechaDesde=${fechaDesde.toDateString()}&fechaHasta=${fechaHasta.toDateString()}&idMoneda=${idMoneda}` +
      `&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.ventasDesagregadasPorTipoPorProducto}`;
    const response = await axios.get(url, requestOptions);

    return response && response.data;
  }
);

export const ventasDesagregadasPorProductoSlice = createSlice({
  name: 'ventasDesagregadasPorProducto',
  initialState,
  reducers: {
    seleccionTipoDeVentasDesagregadas: (state, action) => {
      state.tipoVentasDesagregadasSelected = action.payload.selectionId;
    },
    updateVentasPorProductoFiltros: {
      reducer: (state, action) => {
        state.filtros = action.payload;
      },
      prepare: (fechaDesde, fechaHasta) => {
        return {
          payload: {
            semanaDesde: fechaDesde ? moment(fechaDesde).week() : 0,
            semanaHasta: fechaHasta ? moment(fechaHasta).week() : 0,
            anioDesde: fechaDesde ? fechaDesde.getFullYear() : new Date().getFullYear(),
            anioHasta: fechaHasta ? fechaHasta.getFullYear() : new Date().getFullYear(),
            desde: fechaDesde ? fechaDesde.toDateString() : null,
            hasta: fechaHasta ? fechaHasta.toDateString() : null,
            aplicado: true,
          }
        }
      }
    },
    resetExportVentasDesagregadas: (state) => {
      state.exportVentasDesagregadas = {
        loading: false,
        estaCargado: false,
        error: null,
        ventas: null
      }
    },
    clearVentasDesagregadasPorProducto: (state) => {
      state.tipoVentasDesagregadasSelected = 'laboratorio';
      state.filtros = {
        semanaDesde: null,
        semanaHasta: null,
        anioDesde: null,
        anioHasta: null,
        desde: null,
        hasta: null,
        aplicado: false,
      };
      state.ventasDesagregadas = {
        loading: false,
        estaCargado: false,
        error: null,
        ventas: null,
        totalMonto: 0,
        totalUnidades: 0
      };
      state.exportVentasDesagregadas = {
        loading: false,
        estaCargado: false,
        error: null,
        ventas: null
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVentasDesagregadasDelMesPorTipoDeVenta.pending, (state) => {
        state.ventasDesagregadas = {
          ventas: [],
          loading: true,
          error: null,
          estaCargado: false
        };
      })
      .addCase(loadVentasDesagregadasDelMesPorTipoDeVenta.fulfilled, (state, response) => {
        if (response.payload) {
          state.ventasDesagregadas = {
            ventas: response.payload.data ? response.payload.data.ventas : [],
            totalMonto: response.payload.data ? response.payload.data.totalMonto : 0,
            totalUnidades: response.payload.data ? response.payload.data.totalUnidades : 0,
            loading: false,
            error: null,
            estaCargado: true
          };
          state.filtros = response.payload.filtros
        }
      })
      .addCase(loadVentasDesagregadasDelMesPorTipoDeVenta.rejected, (state, error) => {
        state.ventasDesagregadas = {
          ventas: [],
          loading: false,
          error: error.response
            ? error.response.data
            : "Se produjo un error conectando con el servidor",
          estaCargado: true
        };
      })
      .addCase(exportVentasDesagregadasPorTipoDeVentas.pending, (state) => {
        state.exportVentasDesagregadas = {
          ventas: [],
          loading: true,
          error: null,
          estaCargado: false
        };
      })
      .addCase(exportVentasDesagregadasPorTipoDeVentas.fulfilled, (state, response) => {
        state.exportVentasDesagregadas = {
          ventas: response.payload ? response.payload : [],
          loading: false,
          error: null,
          estaCargado: true
        };
      })
      .addCase(exportVentasDesagregadasPorTipoDeVentas.rejected, (state, error) => {
        state.exportVentasDesagregadas = {
          ventas: [],
          loading: false,
          error: error.response
            ? error.response.data
            : "Se produjo un error conectando con el servidor",
          estaCargado: true
        };
      })
  }
});

export const { updateVentasPorProductoFiltros, clearVentasDesagregadasPorProducto, seleccionTipoDeVentasDesagregadas, resetExportVentasDesagregadas } = ventasDesagregadasPorProductoSlice.actions;
export default ventasDesagregadasPorProductoSlice.reducer;