import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Select, Input, MenuItem, Typography } from "@mui/material";
import moment from 'moment';
import { formatNumber, formatNumberWithoutDecimals } from '../../../../assets/shared/formatNumber';
import { storageFechaFija, storageMonedaSigno } from '../../../../assets/shared/sessionData';
import classes from './PieTableVentasMontoUnidadPorSeleccion.module.css';
import PieVentasMontoUnidadPorSeleccion from './PieVentasMontoUnidadPorSeleccion';
import TablaVentasMontoUnidadPorSeleccion from './TablaVentasMontoUnidadPorSeleccion';
import {
  loadVentasDesagregadasDelMesPorTipoDeVenta, loadVentasDesagregadasDelMesPorTipoDeVentaYSubNivel,
  updateVentasPorProductoFiltros, seleccionTipoDeVentasDesagregadas, resetExportVentasDesagregadas
} from '../../../../store/slices/ventas/ventasDesagregadasPorProductoSlice';
import PieTableVentasFiltro from './PieTableVentasFiltro';
import { loadTipoDeVentasDesagregadas } from '../../../../store/slices/ventas/ventasDesagregadasSlice';

const PieTableVentasMontoUnidadPorSeleccion = (props) => {
  const { ejecucionDePagina, fechaVentasDelMes, vendedorId } = props;
  const [relatedChartVentas, setRelatedChartVentas] = useState([]);
  const [filtroParent, setFiltroParent] = useState([]);
  const lgScreen = window.screen.width >= 1900;

  const dataOptions = {
    marca: {
      title: 'Marca',
      stateKey: 'ventasPorMarca',
      onLoadKey: 'onLoadVentasPorMarca',
      nextTitle: 'Rubro',
    },
    rubro: {
      title: 'Rubro',
      stateKey: 'ventasPorRubro',
      onLoadKey: 'onLoadVentasPorRubro',
      nextTitle: 'Artículo',
    },
    subrubro: {
      title: 'Subrubro',
      stateKey: 'ventasPorSubrubro',
      onLoadKey: 'onLoadVentasPorSubrubro',
      nextTitle: 'Artículo',
    },
    provincia: {
      title: 'Provincia',
      stateKey: 'ventasPorProvincia',
      onLoadKey: 'onLoadVentasPorProvincia',
      nextTitle: 'Cliente',
    },
    zona: {
      title: 'Zona',
      stateKey: 'ventasPorZona',
      onLoadKey: 'onLoadVentasPorZona',
      nextTitle: 'Cliente',
    },
    unidaddenegocio: {
      title: 'Unidad de negocio',
      stateKey: 'ventasPorUnidadDeNegocio',
      onLoadKey: 'onLoadVentasPorUnidadDeNegocio',
      nextTitle: 'Canal',
    },
    canal: {
      title: 'Canal',
      stateKey: 'ventasPorCanal',
      onLoadKey: 'onLoadVentasPorCanal',
      nextTitle: 'Cliente',
    },
    grupodecompra: {
      title: 'Grupo de compra',
      stateKey: 'ventasPorGrupoDeCompra',
      onLoadKey: 'onLoadVentasPorGrupoDeCompra',
      nextTitle: 'Cliente',
    },
    responsable: {
      title: 'Responsable',
      stateKey: 'ventasPorResponsable',
      onLoadKey: 'onLoadVentasPorResponsable',
      nextTitle: 'Vendedor',
    },
    cliente: {
      title: 'Cliente',
      stateKey: 'ventasPorCliente',
      onLoadKey: 'onLoadVentasPorCliente',
      nextTitle: 'Rubro',
    },
    articulo: {
      title: 'Artículo',
      stateKey: 'ventasPorArticulo',
      onLoadKey: 'onLoadVentasPorArticulo',
      nextTitle: 'Cliente',
    },
    vendedor: {
      title: 'Vendedor',
      stateKey: 'ventasPorVendedor',
      onLoadKey: 'onLoadVentasPorVendedor',
      nextTitle: 'Cliente',
    },
    categoriacliente: {
      title: 'Categoría de Cliente',
      stateKey: 'ventasPorCategoriaCliente',
      onLoadKey: 'onLoadVentasPorCategoriaDeCliente',
      nextTitle: 'Cliente',
    }
  };

  //#region Dispatches
  const dispatch = useDispatch();
  const onLoadTipoDeVentas = useCallback(() => {
    dispatch(loadTipoDeVentasDesagregadas());
  }, [dispatch]);
  const onChangeOptionSelected = useCallback((selectionId) => {
    dispatch(seleccionTipoDeVentasDesagregadas({ selectionId }));
    dispatch(resetExportVentasDesagregadas());
  }, [dispatch]);
  const onLoadData = useCallback((fechaDesde, fechaHasta) => {
    dispatch(loadVentasDesagregadasDelMesPorTipoDeVenta({ fechaDesde, fechaHasta }));
  }, [dispatch]);
  const onLoadDataSubNivel = useCallback((id, tipoParent, tipoChild, fechaDesde, fechaHasta, filtrosParent, success, errorFail) => {
    dispatch(loadVentasDesagregadasDelMesPorTipoDeVentaYSubNivel(id, tipoParent, tipoChild, fechaDesde, fechaHasta, filtrosParent, success, errorFail));
  }, [dispatch]);
  const onUpdateFiltros = useCallback((fechaDesde, fechaHasta) => {
    dispatch(updateVentasPorProductoFiltros(fechaDesde, fechaHasta));
    dispatch(resetExportVentasDesagregadas());
  }, [dispatch]);

  const loadingTipoVentas = useSelector(state => state.ventasDesagregadas.tipoVentasDesagregadas.loading);
  const estaCargadoTipoVentas = useSelector(state => state.ventasDesagregadas.tipoVentasDesagregadas.estaCargado);
  const dataSelectedId = useSelector(state => state.ventasDesagregadasPorProducto.tipoVentasDesagregadasSelected);
  const dataSelectOptions = useSelector(state => state.ventasDesagregadas.tipoVentasDesagregadas.tipos);
  const loading = useSelector(state => state.ventasDesagregadasPorProducto.ventasDesagregadas.loading);
  const ventas = useSelector(state => state.ventasDesagregadasPorProducto.ventasDesagregadas.ventas);
  const totalMonto = useSelector(state => state.ventasDesagregadasPorProducto.ventasDesagregadas.totalMonto);
  const totalUnidades = useSelector(state => state.ventasDesagregadasPorProducto.ventasDesagregadas.totalUnidades);
  const filtroSeleccionado = useSelector(state => state.ventasDesagregadasPorProducto.filtros);
  //#endregion

  const getFechaVentas = useCallback(() => {
    const fechaDeDatos = ventas && ventas.length > 0
      ? new Date(ventas[0].anio, ventas[0].mes - 1, 1)
      : new Date(storageFechaFija());
    return fechaDeDatos ? fechaDeDatos : fechaVentasDelMes;
  }, [ventas, fechaVentasDelMes]);

  const getFechasForLoad = useCallback(() => {
    let result = new Date();

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      result = {
        desde: new Date(filtroSeleccionado.desde),
        hasta: new Date(filtroSeleccionado.hasta)
      }
    } else {
      const fecha = getFechaVentas();
      result = {
        desde: fecha.mes && fecha.anio ? new Date(fecha.anio, fecha.mes, 1) : new Date(fecha),
        hasta: fecha.mes && fecha.anio ? new Date(fecha.anio, fecha.mes + 1, 1) : new Date(fecha),
      }
    }

    return result;
  }, [filtroSeleccionado, getFechaVentas]);

  const getFechaVentasTitle = () => {
    let title = "";

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      title = `${moment(filtroSeleccionado.desde).format('MM/YYYY')} al ${moment(filtroSeleccionado.hasta).format('MM/YYYY')}`
    } else {
      const fecha = getFechaVentas();
      title = moment(fecha ? fecha : storageFechaFija()).format('MM/YYYY');
    }

    return title;
  }

  useEffect(() => {
    if (ejecucionDePagina && !estaCargadoTipoVentas && !loadingTipoVentas)
      onLoadTipoDeVentas();
  }, [ejecucionDePagina, estaCargadoTipoVentas, loadingTipoVentas]);

  useEffect(() => {
    if (ejecucionDePagina && ventas && ventas.length <= 0 && relatedChartVentas.length > 0) {
      clearRelatedChart();
    }
  }, [ejecucionDePagina, ventas, relatedChartVentas]);

  const handleChangeOptionSelected = (e) => {
    e.preventDefault();
    clearRelatedChart();
    onChangeOptionSelected(e.target.value);

    const fecha = getFechasForLoad();
    let fechaDesde = new Date(fecha.desde);
    let fechaHasta = new Date(fecha.hasta);

    onLoadData(fechaDesde, fechaHasta);
  };

  const onAplicarFiltroClick = (desde, hasta) => {
    clearRelatedChart();
    onLoadData(desde, hasta);
  }

  const optionSelector = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', fontWeight: 400, margin: '10px 0px 0px 0px' }}>
        <div className={classes.LabelVerPor}>
          Ver ventas del{' '}
          {loading ? '--/----' : getFechaVentasTitle()} por:{' '}
        </div>
        <Select
          input={<Input classes={{ input: classes.SelectRoot }} />}
          style={{ marginLeft: '5px' }}
          value={dataSelectedId}
          onChange={(e) => handleChangeOptionSelected(e)}
        >
          {dataSelectOptions.map((option, index) => {
            return (
              <MenuItem dense key={index} value={option.id}>
                {option.nombre}
              </MenuItem>
            );
          })}
        </Select>
      </div>
    );
  };

  const getHeaderTitle = useCallback(() => {
    let option = dataSelectOptions.find((opt) => opt.id === dataSelectedId);
    return option ? option.nombre : '';
  }, [dataSelectOptions, dataSelectedId]);

  //#region Click Chart
  const getFiltrosParent = useCallback((id, currentLevel, filtros) => {
    let tempFiltros = [];

    if (filtros && filtros.length > 0) {
      tempFiltros = tempFiltros.concat(filtros);
    }

    const existItem = tempFiltros.find((filtro) => filtro.entidad === currentLevel);

    if (existItem) {
      tempFiltros.forEach((filtro) => {
        if (filtro.entidad === currentLevel)
          filtro.id = id;
      });
    } else {
      tempFiltros.push({ id: id, entidad: currentLevel });
    }

    return tempFiltros;
  }, []);

  const [relatedChartTitle, setRelatedChartTitle] = useState('Ventas por');
  const [relatedParentTitle, setRelatedParentTitle] = useState('');
  const [relatedChartFilename, setRelatedChartFilename] = useState('');
  const [relatedCharLoading, setRelatedChartLoading] = useState(false);
  const [relatedChartTotal, setRelatedChartTotal] = useState('Total:');
  const [relatedChartType, setRelatedChartType] = useState('');
  const [relatedChartKey, setRelatedChartKey] = useState('');

  const getCurrentLevel = useCallback(() => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    return item;
  }, [dataSelectOptions, dataSelectedId]);

  const getNextLevel = useCallback(() => {
    const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);
    return item ? item.subNivel : undefined;
  }, [dataSelectOptions, dataSelectedId]);

  const clearRelatedChart = () => {
    setRelatedChartTitle('Ventas por');
    setRelatedParentTitle('');
    setRelatedChartVentas([]);
    setRelatedChartTotal('Total:');
    setRelatedChartType('monto');
    setRelatedChartLoading(false);
    setFiltroParent([]);
    setRelatedChartKey('');
  }

  const onPorcionClickFail = () => {
    setRelatedChartLoading(false);
  }

  const onPorcionMontoClickSuccess = useCallback((response) => {
    setRelatedChartVentas(response);
    let total = 0;
    response.forEach((venta) => {
      total += venta.monto;
    });

    setRelatedChartTotal(`Total: ${storageMonedaSigno()} ${formatNumber(total)}`);
    setTimeout(() => setRelatedChartLoading(false), 500);
  }, []);

  const onPorcionUnidadesClickSuccess = useCallback((response) => {
    setRelatedChartVentas(response);

    let totalUnidades = 0;
    response.forEach((venta) => {
      totalUnidades += venta.unidadesVendidas;
    });

    setRelatedChartTotal(`Total: ${formatNumberWithoutDecimals(totalUnidades)}`);
    setTimeout(() => setRelatedChartLoading(false), 500);
  }, []);

  const getTitle = useCallback((isMonto, item, currentKey, nextKey, addLineBreak = false) => {
    let option = dataSelectOptions.find((opt) => opt.id === currentKey);
    let nextTitle = option && option.subNivel ? option.subNivel.nombre : '';
    return (
      <Fragment>
        {isMonto
          ? <span>Ventas por importe neto{addLineBreak && (<Fragment><br /></Fragment>)} por {nextTitle}</span>
          : <span>Ventas por unidades{addLineBreak && (<Fragment><br /></Fragment>)} por {nextTitle}</span>
        }
      </Fragment>
    )
  }, [dataSelectOptions]);

  const getParentTitle = useCallback((item, currentKey) => {
    let option = dataSelectOptions.find((opt) => opt.id === currentKey);
    let title = option ? option.nombre : '';
    return (
      <span>{`${title}: ${item}`}</span>
    )
  }, [dataSelectOptions]);

  const getChartTitle = useCallback((isMonto, key, defaultTitle = "Producto") => {
    let option = dataSelectOptions.find((opt) => opt.id === key);
    let title = option ? option.nombre : defaultTitle ? defaultTitle : '';
    let tipo = isMonto ? 'importe neto' : 'unidades';
    let chartTitle = `Ventas por ${tipo} del ${getFechaVentasTitle()} por ${title}`;
    return chartTitle;
  }, [dataSelectOptions, getFechaVentasTitle]);

  const onPorcionMontoClick = useCallback((config) => {
    const currentLevel = getCurrentLevel(dataSelectOptions, dataSelectedId);
    const nextLevel = getNextLevel();

    if (!currentLevel || !nextLevel)
      return;

    let item = config.w.config.labels[config.dataPointIndex];
    let value = config.w.config.series[config.dataPointIndex];
    const idField = 'id';
    const field = 'nombre';

    if (item !== 'OTROS') {
      clearRelatedChart();
      setRelatedChartLoading(true);
      setRelatedChartTitle(getTitle(true, item, currentLevel.key.toLowerCase(), nextLevel.key.toLowerCase()));
      setRelatedParentTitle(getParentTitle(item, currentLevel.key.toLowerCase()));
      setRelatedChartType('monto');
      setRelatedChartKey(nextLevel.key.toLowerCase());

      let filename = `Ventas por importe neto de ${currentLevel.nombre}: ${item}`;
      setRelatedChartFilename(filename);

      let venta = ventas && ventas.find((v) => {
        let vField = v[field];
        let vValueField = v['monto'];

        if (vField !== null && vField !== undefined)
          return vField.toUpperCase().trim() === item.toUpperCase().trim() && vValueField === value;

        return false;
      });

      if (venta !== null && venta !== undefined) {
        const fecha = getFechasForLoad();
        onLoadDataSubNivel(venta[idField], currentLevel.key, nextLevel.key, fecha.desde, fecha.hasta, filtroParent,
          onPorcionMontoClickSuccess, onPorcionClickFail);

        const parent = getFiltrosParent(venta[idField], currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [ventas, dataSelectOptions, dataSelectedId, filtroParent]);

  const onPorcionUnidadesClick = useCallback((config) => {
    const currentLevel = getCurrentLevel();
    const nextLevel = getNextLevel();

    if (!currentLevel || !nextLevel)
      return;

    let item = config.w.config.labels[config.dataPointIndex];
    let value = config.w.config.series[config.dataPointIndex];
    const idField = 'id';

    if (item !== 'OTROS') {
      clearRelatedChart();
      setRelatedChartLoading(true);
      setRelatedChartTitle(getTitle(false, item, currentLevel.key.toLowerCase(), nextLevel.key.toLowerCase()));
      setRelatedParentTitle(getParentTitle(item, currentLevel.key.toLowerCase()));
      setRelatedChartType('unidadesVendidas');
      setRelatedChartKey(nextLevel.key.toLowerCase());

      let filename = `Ventas por unidades de ${currentLevel.nombre}: ${item}`;
      setRelatedChartFilename(filename);

      let venta = ventas && ventas.find((v) => {
        let vField = v['nombre'];
        let vValueField = v['unidadesVendidas'];

        if (vField && vValueField)
          return vField.toUpperCase().trim() === item.toUpperCase().trim() && vValueField === value;

        return false;
      });

      if (venta) {
        const fecha = getFechasForLoad();
        onLoadDataSubNivel(venta[idField], currentLevel.key, nextLevel.key, fecha.desde, fecha.hasta, filtroParent,
          onPorcionUnidadesClickSuccess, onPorcionClickFail);

        const parent = getFiltrosParent(venta[idField], currentLevel.key, filtroParent);
        setFiltroParent(parent);
      }
    }
  }, [ventas, dataSelectOptions, dataSelectedId, filtroParent, clearRelatedChart, getCurrentLevel, getFechasForLoad, getFiltrosParent,
    getNextLevel, getTitle, onLoadDataSubNivel, onPorcionUnidadesClickSuccess]);

  //#endregion

  return (
    <Fragment>
      <Grid item xs={12} container spacing={1}
        style={{
          border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "white", borderRadius: "3px"
        }}
      >
        <Grid item xs={12}
          container spacing={1}
          direction='row' justifyContent='space-between' alignContent='flex-start'
          style={{ margin: 'auto', marginLeft: '20px', paddingTop: '15px' }}
        >
          <Grid item xs={12} container spacing={1}
            direction='row' justifyContent='flex-start' alignContent='flex-start'
          >
            <Grid item xs={9} md={10}>
              <Typography variant="h5">
                GRÁFICOS
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <PieTableVentasFiltro
              filtros={filtroSeleccionado}
              onUpdateFiltros={onUpdateFiltros}
              aplicarFiltros={onAplicarFiltroClick}
            />
          </Grid>
          <Grid item xs={12}>
            {optionSelector()}
          </Grid>
          <Grid item xs={12} container spacing={1} >
            <Grid item xs={12} lg={4}>
              <PieVentasMontoUnidadPorSeleccion
                legendHeight={50}
                legendPosition={'bottom'}
                height={436}
                width={'100%'}
                chartType={'pie'}
                dataSelectedId={dataSelectedId}
                loading={loading}
                ventas={ventas}
                ventaType={'monto'}
                isCurrency
                title={"Ventas por importe neto"}
                filename="Ventas por importe neto"
                subTitle={`Total: ${storageMonedaSigno()} ${formatNumber(totalMonto)}`}
                onPorcionClick={onPorcionMontoClick}
                chartTitle={getChartTitle(true, dataSelectedId)}
                headerTitle={getHeaderTitle()}
                initialLimit={20}
                spaceBetweenChartTitle={lgScreen ? '19px' : '15px'}
                withoutDecimals={props.withoutDecimals}
                key={"PieVentasPorMontoPorSeleccion"}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PieVentasMontoUnidadPorSeleccion
                legendHeight={50}
                legendPosition={'bottom'}
                height={436}
                width={'100%'}
                chartType={'pie'}
                dataSelectedId={dataSelectedId}
                loading={loading}
                ventas={ventas}
                ventaType={'unidadesVendidas'}
                title={"Ventas por unidades"}
                filename="Ventas por unidades"
                subTitle={`Total: ${formatNumberWithoutDecimals(totalUnidades)}`}
                onPorcionClick={onPorcionUnidadesClick}
                chartTitle={getChartTitle(false, dataSelectedId)}
                headerTitle={getHeaderTitle()}
                initialLimit={20}
                spaceBetweenChartTitle={lgScreen ? '19px' : '15px'}
                withoutDecimals={props.withoutDecimals}
                key={"PieVentasPorUnidadPorSeleccion"}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <PieVentasMontoUnidadPorSeleccion
                legendHeight={50}
                legendPosition={'bottom'}
                height={436}
                width={'100%'}
                chartType={'pie'}
                loading={relatedCharLoading}
                ventas={relatedChartVentas}
                ventaType={relatedChartType}
                title={relatedChartTitle}
                parentTitle={relatedParentTitle}
                filename={relatedChartFilename}
                subTitle={relatedChartTotal}
                isCurrency={relatedChartType === 'monto'}
                headerTitle={getHeaderTitle()}
                chartTitle={getChartTitle(relatedChartType === 'monto', relatedChartKey)}
                initialLimit={20}
                withoutDecimals={props.withoutDecimals}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TablaVentasMontoUnidadPorSeleccion
                dataSelectedId={dataSelectedId}
                dataSelectOptions={dataSelectOptions}
                vendedorId={vendedorId}
                fechaVentasDelMes={getFechaVentas()}
                loading={loading}
                ventas={ventas}
                onShowFiltersModal={props.onShowFiltersModal}
                title={`Ventas del ${loading
                  ? '--/----' : getFechaVentasTitle()} por: ${getHeaderTitle()}`}
                withoutDecimals={props.withoutDecimals}
                key={"TablaVentasMontoUnidadPorSeleccion"}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default PieTableVentasMontoUnidadPorSeleccion;