import React from 'react';
import { Grid } from "@mui/material";
import PieTableVentasMontoUnidadPorSeleccion from '../../components/Indicadores/Ventas/PieTableVentasMontoUnidadPorSeleccion/PieTableVentasMontoUnidadPorSeleccion';

const PanelVentas = (props) => {
  const { ejecucionDePagina } = props;
  const inPhone = window.screen.width <= 750;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ maxWidth: inPhone ? '120vw' : '95vw' }}>
        <PieTableVentasMontoUnidadPorSeleccion
          ejecucionDePagina={ejecucionDePagina}
          withoutDecimals={false}
        />
      </Grid>
    </Grid>
  )
}

export default PanelVentas;