import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Select, MenuItem, Tooltip, FormControl, Typography } from '@mui/material';
import { makeStyles } from "@mui/styles";
import Spinner from '../Spinner/Spinner';
import { loadSucursales, cambiarSucursal } from '../../../store/slices/sucursalesSlice';
import { capitalizeName } from '../../../assets/shared/utility';
import { storageEmpresaId, storageSucursalId } from '../../../assets/shared/sessionData';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: '0px 3px 6px #00000029',
    borderRadius: '3px',
  },
  tooltipTitle: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    color: '8D8C8C'
  }
}));

const SucursalSelector = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sucursales = useSelector(state => state.sucursales.loadSucursales.sucursales);
  const loadingSucursales = useSelector(state => state.sucursales.loadSucursales.loading);
  const estaCargado = useSelector(state => state.sucursales.loadSucursales.estaCargado);
  const [sucursalId, setSucursalId] = useState('');
  const onLoadSucursales = useCallback(() => {
    const payload = {
      empresaId: storageEmpresaId(),
      incluirInactivas: false
    };
    dispatch(loadSucursales(payload));
  }, [dispatch]);

  const [tooltipOpen, setTooltipOpen] = useState(false)
  const handleTooltip = bool => {
    setTooltipOpen(bool)
  }

  const onSucursalChange = (event) => {
    const sucursal = event.target.value;
    setSucursalId(sucursal);
    dispatch(cambiarSucursal(sucursal));
  }

  useEffect(() => {
    if (!estaCargado) {
      onLoadSucursales();
    }
  }, [estaCargado, onLoadSucursales]);

  useEffect(() => {
    if (estaCargado && sucursalId === '' && storageSucursalId())
      setSucursalId(storageSucursalId());
  }, [estaCargado, sucursalId, sucursales]);

  useEffect(() => {
    if (estaCargado && sucursalId === '' && !storageSucursalId()) {
      if (sucursales && sucursales[0])
        setSucursalId(sucursales[0].id);
    }

  }, [estaCargado, sucursalId, sucursales]);

  return loadingSucursales ? (
    <Spinner size="small" />
  ) : (sucursales.length === 0 ?
    (
      <Fragment></Fragment>
    ) : (
      <FormControl style={{ margin: '0px 10px 0px 10px' }}>
        <Tooltip
          open={tooltipOpen}
          arrow
          title={
            <Typography className={classes.tooltipTitle}>
              Seleccione una sucursal
            </Typography>
          }
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <Select
            style={{ minWidth: 120, color: props.letraColor }}
            labelId="sucursalLabel"
            id="sucursalSelect"
            value={sucursalId}
            onChange={(event) => onSucursalChange(event)}
            onMouseEnter={() => { handleTooltip(true) }}
            onMouseLeave={() => { handleTooltip(false) }}
            onOpen={() => { handleTooltip(false) }}
            variant="standard"
            disabled={props.disabled ? props.disabled : false}
          >
            {sucursales
              .filter((suc) => !suc.inactiva)
              .map((sucursal) => (
                <MenuItem key={sucursal.id} value={sucursal.id}>
                  {sucursal.nombre && capitalizeName(sucursal.nombre)}
                </MenuItem>
              ))}
          </Select>
        </Tooltip>
      </FormControl>
    )
  );
};

export default SucursalSelector;