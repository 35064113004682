import React from "react";
import { useSelector } from 'react-redux';
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";
import FullWidthTabs from "../../components/UI/Tabs/Tabs";
import CuboVentas from "./CuboVentas";
import PanelVentas from "./PanelVentas";

const VentasPorProducto = () => {
  sessionStorage.setItem('seccionNombre', 'Ventas Por Producto');
  const ejecucionDePagina = useSelector(state => state.ejecucionPaginas.ejecucionVentasPorProducto);

  return (
    <ConteinerWithEjecutarPagina
      showMultiempresa
      isExecuted={!ejecucionDePagina}
    >
      
      <FullWidthTabs
        tabs={['Ventas', 'Gráficos']}
        views={[
          <CuboVentas ejecucionDePagina={ejecucionDePagina} />,
          <PanelVentas ejecucionDePagina={ejecucionDePagina} />
        ]}
      />
    </ConteinerWithEjecutarPagina>
  );
};

export default VentasPorProducto;