import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatNumber, formatNumberWithoutDecimals, isEven } from '../../../../assets/shared/formatNumber';
import Table from '../../../UI/Table/Table';
import { storageMonedaSigno, storageFechaFija } from '../../../../assets/shared/sessionData';

import classes from './PieTableVentasMontoUnidadPorSeleccion.module.css';
import { loadVentasDesagregadasDelMesPorTipoDeVentaYSubNivel } from '../../../../store/slices/ventas/ventasDesagregadasPorProductoSlice';
import { TableHead, TableCell, TableRow } from '@mui/material';
import { compareByDesc, compareByAsc } from '../../../../assets/shared/sorting';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const TablaVentasDesagregadasMontoUnidad = (props) => {
  const { itemExpanded, fechaVentasDelMes, currentLevel, nextLevel, filtrosParent } = props;
  const [ventasLocal, setVentasLocal] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalMonto, setTotalMonto] = useState(0);
  const [totalUnidades, setTotalUnidades] = useState(0);
  const [titleTotal] = useState(`TOTAL ${itemExpanded ? itemExpanded.nombre : ''}`);
  const tableRef = useRef();

  //#region Dispatches
  const dispatch = useDispatch();
  const onLoadVentasDesagregadasDelMes = useCallback((id, tipoParent, tipoChild, fechaDesde, fechaHasta, filtrosParent, success, errorFail) => {
    dispatch(loadVentasDesagregadasDelMesPorTipoDeVentaYSubNivel(id, tipoParent, tipoChild, fechaDesde, fechaHasta, filtrosParent, success, errorFail));
  }, [dispatch]);

  const filtroSeleccionado = useSelector(state => state.ventasDesagregadasPorProducto.filtros);
  //#endregion

  const getFechaVentas = useCallback(() => {
    const fechaDeDatos = ventasLocal && ventasLocal.length > 0
      ? new Date(ventasLocal[0].anio, ventasLocal[0].mes - 1, 1)
      : new Date(storageFechaFija());
    return fechaDeDatos ? fechaDeDatos : fechaVentasDelMes;
  }, [ventasLocal, fechaVentasDelMes]);

  const getFechasForLoad = useCallback(() => {
    let result = new Date();

    if (filtroSeleccionado && filtroSeleccionado.aplicado) {
      result = {
        desde: new Date(filtroSeleccionado.desde),
        hasta: new Date(filtroSeleccionado.hasta)
      }
    } else {
      const fecha = getFechaVentas();
      result = {
        desde: fecha.mes && fecha.anio ? new Date(fecha.anio, fecha.mes, 1) : new Date(fecha),
        hasta: fecha.mes && fecha.anio ? new Date(fecha.anio, fecha.mes + 1, 1) : new Date(fecha),
      }
    }

    return result;
  }, [filtroSeleccionado, getFechaVentas]);

  const onLoadSuccess = (ventas, ventasNegativo, totalVentas, fecha, totUnidades) => {
    setVentasLocal(ventas);
    setTotalMonto(totalVentas);
    setTotalUnidades(totUnidades);
    //props.endRequest && props.endRequest(ventas.length);
    setLoading(false);
  }

  const onLoadFail = () => {
    setVentasLocal([]);
    setTotalMonto(0);
    setTotalUnidades(0);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    const fecha = getFechasForLoad();
    onLoadVentasDesagregadasDelMes(itemExpanded.id, currentLevel.key, nextLevel.key, fecha.desde, fecha.hasta, filtrosParent, onLoadSuccess, onLoadFail);
  }, []);

  const getCurrentLevel = () => {
    return nextLevel;
  }

  const getCurrentLevelKey = () => {
    return nextLevel ? nextLevel.key : "";
  }

  const getCurrentLevelTitle = () => {
    let title = nextLevel ? nextLevel.nombre : '';
    return title;
  }

  const getNextLevel = () => {
    return nextLevel ? nextLevel.subNivel : undefined;
  }

  const getDataSelectedId = () => {
    return nextLevel ? nextLevel.key ? nextLevel.key.toLowerCase() : "" : "";
  }

  const mostrarComprobantes = () => {
    const disabled = getNextLevel() === undefined || getNextLevel() === null;
    return disabled;
  }

  const getFiltrosParent = () => {
    let tempFiltros = [];
    let id = itemExpanded.id;

    if (filtrosParent && filtrosParent.length > 0) {
      tempFiltros = tempFiltros.concat(filtrosParent);
    }

    const existItem = tempFiltros.find((filtro) => filtro.entidad === currentLevel.key);

    if (existItem) {
      tempFiltros.forEach((filtro) => {
        if (filtro.entidad === currentLevel.key)
          filtro.id = id;
      });
    } else {
      tempFiltros.push({ id: id, entidad: currentLevel.key });
    }

    return tempFiltros;
  }

  //#region Sort Data

  const [sortConfig, setSortConfig] = useState({
    key: 'porcentajeDeParticipacion',
    direction: 'desc'
  });

  const onSort = (sortKey) => {
    if (sortConfig.key === sortKey) {
      setSortConfig({ ...sortConfig, direction: sortConfig.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setSortConfig({ ...sortConfig, key: sortKey, direction: 'desc' });
    }
  };

  const renderSort = (sortKey) => {
    return sortConfig.key === sortKey
      ? sortConfig.direction === 'asc'
        ? (
          <span className="MuiButtonBase-root MuiTableSortLabel-root Mui-active css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root" tabIndex="0" role="button">
            <ArrowUpwardIcon />
          </span>
        ) : (
          <span className="MuiButtonBase-root MuiTableSortLabel-root Mui-active css-1qgma8u-MuiButtonBase-root-MuiTableSortLabel-root" tabIndex="0" role="button">
            <ArrowDownwardIcon />
          </span>
        ) : (
        <Fragment></Fragment>
      )
  }

  //#endregion

  const columns = [
    {
      title: '',
      field: 'id',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '10px',
        paddingTop: '10px',
        width: '5%'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '5%'
      }
    },
    {
      title: '',
      field: 'nombre',
      align: 'left',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '10px',
        paddingTop: '10px',
        width: '20%'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '20%'
      }
    },
    {
      title: ``,
      field: 'porcentajeDeParticipacion',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red',
          width: '15%'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '15%'
      },
      render: (rowData) => `${formatNumber(rowData.porcentajeDeParticipacion)}%`
    },
    {
      title: ``,
      field: 'monto',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red',
          width: '15%'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '15%'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.monto)}`
    },
    {
      title: ``,
      field: 'porcParticipacionUnidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red',
          width: '15%'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '15%'
      },
      render: (rowData) => `${formatNumber(rowData.porcParticipacionUnidades)}%`
    },
    {
      title: ``,
      field: 'unidades',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red',
          width: '15%'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '15%'
      },
      render: (rowData) => `${formatNumberWithoutDecimals(rowData.unidades)}`
    },
    {
      title: `Precio promedio`,
      field: 'precioProm',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '10px',
          paddingTop: '10px',
          color: cellData < 0 && 'red',
          width: '15%'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(235 235 235)',
        width: '15%'
      },
      render: (rowData) => `${storageMonedaSigno()} ${formatNumber(rowData.precioProm)}`
    },
  ];

  const data = () => {
    const tableData = [];
    const fecha = getFechasForLoad();

    ventasLocal && ventasLocal.map((venta) => {
      return tableData.push({
        id: venta.id,
        nombre: venta.nombre,
        monto: venta.monto,
        porcentajeDeParticipacion: venta.porcentajeDeParticipacion,
        unidades: venta.unidadesVendidas,
        porcParticipacionUnidades: venta.porcentajeDeParticipacionUnidades,
        mesDesde: fecha.mesDesde,
        anioDesde: fecha.anioDesde,
        mesHasta: fecha.mesHasta,
        anioHasta: fecha.anioHasta,
        precioProm: venta.monto/venta.unidadesVendidas
      });
    });

    tableData.sort((a, b) =>
      sortConfig.direction === 'desc' ? compareByDesc(a, b, sortConfig.key) : compareByAsc(a, b, sortConfig.key)
    );

    if (ventasLocal && ventasLocal.length > 0) {
      tableData.push({
        monto: totalMonto,
        porcentajeDeParticipacion: 100,
        unidades: totalUnidades,
        porcParticipacionUnidades: 100,
        id: null,
        nombre: titleTotal,
        mesDesde: fecha.mesDesde,
        anioDesde: fecha.anioDesde,
        mesHasta: fecha.mesHasta,
        anioHasta: fecha.anioHasta,
        precioProm: totalMonto/totalUnidades
      });
    }

    return tableData;
  };

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);
      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  const getMaxBodyHeight = () => {
    return ventasLocal && ventasLocal.length < 5 ? 250 : ventasLocal.length < 10 ? 350 : 450;
  }

  return (
    <div className={classes.TablaVentasDesagregadasMontoUnidad}>
      <Table
        tableRef={tableRef}
        title=''
        style={{ width: '98%', marginTop: 0, marginBottom: '10px' }}
        columns={columns}
        loading={loading}
        data={data()}
        components={{
          Header: (props) => {
            return (
              <TableHead style={{ maxHeight: '50px' }}>
                <TableRow style={{ height: '30px' }}>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('id')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="left"
                  >
                    {getCurrentLevelTitle()}{renderSort('id')}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('nombre')}
                    style={{
                      paddingTop: '5px',
                      paddingBottom: '5px',
                      width: window.screen.width < 1367 ? '265px' : '450px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="left"
                  >
                    {renderSort('nombre')}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcentajeDeParticipacion')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  >
                    {renderSort('porcentajeDeParticipacion')}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('monto')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  >
                    {renderSort('monto')}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('porcParticipacionUnidades')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  >
                    {renderSort('porcParticipacionUnidades')}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('unidades')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  >
                    {renderSort('unidades')}
                  </TableCell>
                  <TableCell
                    className={classes.SorteableHeaderTitle}
                    onClick={() => onSort('precioProm')}
                    style={{
                      fontSize: '14px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                      backgroundColor: 'rgb(235 235 235)'
                    }}
                    align="right"
                  >
                    {renderSort('precioProm')}
                  </TableCell>
                </TableRow>
              </TableHead>
            );
          }
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          search: true,
          showTitle: true,
          minBodyHeight: 250,
          maxBodyHeight: getMaxBodyHeight(),
          rowStyle: (rowData, index) => ({
            cursor: 'auto',
            color: rowData.tableData.monto < 0 && 'red',
            backgroundColor: (rowData.nombre === titleTotal && '#A3CCE8') || (!isEven(index) && '#f2f2f2')
          }),
          cellStyle: (cellData) => ({
            fontWeight: cellData === titleTotal && 'bolder'
          })
        }}
        tableKey={"tabla-ventas-desagregadas-monto-unidad"}
      />
    </div>
  );
};

export default TablaVentasDesagregadasMontoUnidad;