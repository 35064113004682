import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavigationItem.module.css";
import { makeStyles } from "@mui/styles";

import { ListItemText, ListItemButton, ListItemIcon } from "@mui/material";
import LightTooltip from "../../UI/LightTooltip/LightTooltip";
import { useDispatch } from "react-redux";
import { useCallback } from "react";
import { cambioPaginaRender } from "../../../store/slices/ejecucionPaginasSlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  listItem: {
    "&.Mui-selected": {
      background: 'linear-gradient(90deg, #fbb040, #f15a29)',
    },
    "&:focus": {
      background: 'linear-gradient(90deg, #fbb040, #f15a29)',
    },
  }
}));

const NavigationItem = (props) => {
  const dispatch = useDispatch();
  const { title, style, text, link, exact, children, } = props;
  const cambiarPage = useCallback((page) => dispatch(cambioPaginaRender(page)), [dispatch]);
  const pageRender = useSelector(state => state.ejecucionPaginas.paginaRender);
  const classes2 = useStyles();

  const onItemClick = () => {
    sessionStorage.setItem('seccionNombre', props.title);
    props.onClickItem();
    cambiarPage(link);
  }

  return (
    <Fragment>
      <LightTooltip arrow title={title} placement="right">
        <ListItemButton
          className={classes2.listItem}
          style={style}
          button="true"
          key={text}
          component={NavLink}
          to={link}
          exact={exact}
          onClick={() => onItemClick()}
          selected={pageRender === link}
        >
          <ListItemIcon className={classes.NavigationItemIcon}>
            {children}
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItemButton>
      </LightTooltip>
    </Fragment>
  );
};

export default NavigationItem;