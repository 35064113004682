import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomPivotGrid from '../../../UI/CustomPivotGrid/CustomPivotGrid';
import { storageEmpresaId, storageMonedaSigno, storageUsuarioId } from '../../../../assets/shared/sessionData';
import { Typography, Grid } from '@mui/material';
import { formatNumber } from '../../../../assets/shared/formatNumber';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { pivotGridFormat } from '../../../../assets/shared/pivotGridDefaults/ventasPorProductoFormat';
import PivotGridFiltroVentas from '../PivotGridFiltroVentas/PivotGridFiltroVentas';
import {
  loadVentasPorProducto, updateVentasPorProductoFiltros, updateConfiguracionFields,
  updateSelectedTotalRows, updateVentasPorProductoData, ventasPorProductoConfiguracion,
  updateVentasPorProductoFiltrosChart, updateNombreFormato, updateNombreCubo,
  updateVentasPorProductoFormato
} from '../../../../store/slices/ventas/ventasPorProductoSlice';
import moment from "moment";
import "moment/locale/es";
import { showListFiltersModal } from '../../../../store/slices/modalsSlice';
import Spinner from '../../../UI/Spinner/Spinner';

moment.locale("es");

const PivotGridVentasPorProducto = (props) => {
  const [signoMoneda] = useState(storageMonedaSigno());
  const [storageKey] = useState("ventas-por-producto-pivot-grid");
  const [pivotGridConfig] = useState(pivotGridFormat(signoMoneda));
  const [customLoading, setCustomLoading] = useState(false);
  const [configFields, setConfigFields] = useState();
  const [rowsColumnsFixed, setRowsColumnsFixed] = useState(true);
  const [gridContainerOverflowX, setGridContainerOverflowX] = useState("hidden");
  const [selectedTotalRows, setSelectedTotalRows] = useState([]);
  const rowFieldsFromDataSource = useRef();
  const [scrollingMode, setScrollingMode] = useState("virtual");
  const [reloadFilters, setReloadFilters] = useState(false);
  const nombreFormatoDefault = "Último formato utilizado";

  //#region Dispatches
  const dispatch = useDispatch();
  const onLoadData = useCallback((request, config) => {
    dispatch(loadVentasPorProducto({ request, config }));
  }, [dispatch]);
  const onLoadDataImport = useCallback((request, onSuccess, onFail) => {
    dispatch(loadVentasPorProducto({ request, onSuccess, onFail }));
  }, [dispatch]);
  const onUpdateData = useCallback((data, config, filtros) => {
    dispatch(updateVentasPorProductoData(data, config, filtros));
  }, [dispatch]);
  const onUpdateConfigFields = useCallback((config) => {
    dispatch(updateConfiguracionFields(config));
  }, [dispatch]);
  const onUpdateFiltros = useCallback((fechaDesde, fechaHasta, anioComparacion) => {
    dispatch(updateVentasPorProductoFiltros(fechaDesde, fechaHasta, anioComparacion));
  }, [dispatch]);
  const onUpdateFiltrosChart = useCallback((filtros) => {
    dispatch(updateVentasPorProductoFiltrosChart(filtros));
  }, [dispatch]);
  const onShowFiltersModal = useCallback((title) => {
    dispatch(showListFiltersModal(true, title, ['venta']));
  }, [dispatch]);

  const loading = useSelector(state => state.ventasPorProducto.data.loading);
  const datos = useSelector(state => state.ventasPorProducto.data.datos);
  const fechaActualizacion = useSelector(state => state.ventasPorProducto.data.actualizado);
  const config = useSelector(state => ventasPorProductoConfiguracion(state));
  const filtros = useSelector(state => state.ventasPorProducto.filtros);
  const pivotGridConfigFields = useSelector(state => state.ventasPorProducto.configuracionFields);
  const selectedTotalRowsItems = useSelector(state => state.ventasPorProducto.selectedTotalRows);
  const filtrosChart = useSelector(state => state.ventasPorProducto.filtrosChart);
  const nombreFormato = useSelector(state => state.ventasPorProducto.nombreFormato);
  const nombreCubo = useSelector(state => state.ventasPorProducto.nombreCubo);
  //#endregion

  const title = () => {
    return (
      <Typography variant="h5">
        {`VENTAS: ${nombreCubo} Formato: ${nombreFormato}`}
      </Typography>
    )
  }

  const subTitle = () => {
    return reloadFilters ?
      (
        <Spinner></Spinner>
      ) : (
        <Grid item xs={12} container spacing={1} justifyContent="flex-start" style={{ marginBottom: '20px' }}>
          <PivotGridFiltroVentas
            filtros={filtros}
            aplicarFiltros={onAplicarFiltroClick}
            onUpdateFiltros={onUpdateFiltros}
          />
        </Grid>
      )
  }

  const getFileName = (nombreFormato, filtros) => {
    const format = "DD-MM-YYYY";
    const filename = nombreFormato && nombreFormato !== ''
      ? `VENTAS: ${nombreFormato}`
      : filtros && filtros.desde && filtros.hasta
        ? `Ventas del ${new moment(new Date(filtros.desde)).format(format)} al ${new moment(new Date(filtros.hasta)).format(format)}`
        : 'Ventas';

    return filename;
  };

  const onCellClick = (e) => { }

  const data = () => {
    return datos && datos.map((dato) => {
      let item = { ...dato };
      return item;
    })
  }

  const fields = () => {
    var tempConfig = config ? JSON.parse(JSON.stringify(config)) : undefined;
    return tempConfig && tempConfig.map((field) => {
      let esDifComparacion = field.name && field.name.indexOf('DiferenciaComparacion') > -1;
      let item = { ...field };
      let fieldFormat = pivotGridConfig.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (fieldFormat) {
        if (fieldFormat.caption)
          item["caption"] = fieldFormat.caption;

        if (fieldFormat.isMeasure !== undefined)
          item["isMeasure"] = fieldFormat.isMeasure;

        if (fieldFormat.format !== undefined)
          item["format"] = fieldFormat.format;

        if (fieldFormat.allowFiltering !== undefined)
          item["allowFiltering"] = fieldFormat.allowFiltering;

        if (fieldFormat.allowSorting !== undefined)
          item["allowSorting"] = fieldFormat.allowSorting;

        if (fieldFormat.allowSortingBySummary !== undefined)
          item["allowSortingBySummary"] = fieldFormat.allowSortingBySummary;
        if (fieldFormat.customizeText !== undefined)
          item["customizeText"] = fieldFormat.customizeText;
      }

      if (esDifComparacion) {
        item["summaryType"] = 'custom';

        if (item["dataField"])
          delete item["dataField"];

        if (item["customizeText"])
          delete item["customizeText"];

        let esDifCompUnidades = field && field.name === 'unidadesDiferenciaComparacion';
        let esDifCompNeto = field && field.name === 'netoDiferenciaComparacion';
        let esPorcDifCompUnidades = field && field.name === 'unidadesPorcDiferenciaComparacion';
        let esPorcDifCompNeto = field && field.name === 'netoPorcDiferenciaComparacion';

        if (esDifCompUnidades) {
          item["format"] = ',##0';
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.unidadesTotales;
                options.totalValue.totalComparacion += options.value.unidadesTotalesComparacion;
                break;
              case "finalize":
                options.totalValue = (options.totalValue.totalActual - options.totalValue.totalComparacion);
            }
          }
        } else if (esDifCompNeto) {
          item["format"] = `${signoMoneda} ,##0.00`;
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.netoTotal;
                options.totalValue.totalComparacion += options.value.netoTotalComparacion;
                break;
              case "finalize":
                options.totalValue = (options.totalValue.totalActual - options.totalValue.totalComparacion);
            }
          }
        } else if (esPorcDifCompUnidades) {
          item["format"] = ',##0.00 %';
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.unidadesTotales;
                options.totalValue.totalComparacion += options.value.unidadesTotalesComparacion;
                break;
              case "finalize":
                let diff = (options.totalValue.totalActual - options.totalValue.totalComparacion);
                options.totalValue = options.totalValue.totalActual === 0 ? 0 : diff / options.totalValue.totalActual;
            }
          }
        } else if (esPorcDifCompNeto) {
          item["format"] = ',##0.00 %';
          item["calculateCustomSummary"] = function (options) {
            switch (options.summaryProcess) {
              case "start":
                options.totalValue = { totalActual: 0, totalComparacion: 0, count: 0 };
                break;
              case "calculate":
                options.totalValue.count += 1;
                options.totalValue.totalActual += options.value.netoTotal;
                options.totalValue.totalComparacion += options.value.netoTotalComparacion;
                break;
              case "finalize":
                let diff = (options.totalValue.totalActual - options.totalValue.totalComparacion);
                options.totalValue = options.totalValue.totalActual === 0 ? 0 : diff / options.totalValue.totalActual;
            }
          }
        }
      }

      item["hide"] = selectedTotalRows.find((row) => row.id === field.dataField) === undefined;
      return item;
    });
  };

  const onAplicarFiltroClick = (desde, hasta, anioComparacion) => {
    let localConfig = [];
    if (dataSource) {
      localConfig = dataSource.fields();
    } else {
      let item = localStorage.getItem(storageKey);
      localConfig = item ? JSON.parse(item).fields : config;
    }

    localStorage.removeItem(storageKey);

    let formato = procesarFormato(localConfig);

    const request = {
      empresaId: storageEmpresaId(),
      idUsuario: storageUsuarioId(),
      importacion: false,
      semanaDesde: desde ? desde.getMonth() === 0 && desde.getDate() === 1 ? 1 : moment(desde).week() : 0,
      semanaHasta: hasta ? moment(hasta).week() : 0,
      anioDesde: desde ? desde.getFullYear() : new Date().getFullYear(),
      anioHasta: hasta ? hasta.getFullYear() : new Date().getFullYear(),
      desde: desde ? desde.toDateString() : null,
      hasta: hasta ? hasta.toDateString() : null,
      anioComparacion,
    }

    onLoadData(request, formato);
    dispatch(updateNombreFormato(nombreFormatoDefault));
  }

  const customizeTooltip = (args) => {
    const valueText = args.seriesName.indexOf('%') !== -1
      ? `${formatNumber(args.originalValue)}%`
      : args.seriesName.indexOf('unidades') !== -1
        ? args.originalValue
        : `${signoMoneda} ${formatNumber(args.originalValue)}`;

    return {
      html: `${args.seriesName}<div style='text-align: center;'>${valueText}</div>`,
    };
  }

  const [isLoadingDataSource] = useState([false]);
  const dataSource = new PivotGridDataSource({
    fields: fields(),
    store: {
      type: "array",
      data: data()
    },
    onLoadingChanged: (isLoading) => {
      if (!isLoading) {
        const fields = dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'row');
        rowFieldsFromDataSource.current = fields;
      }
    },
    retrieveFields: false,
  });

  const onUpdateDataSource = () => { }

  const procesarFormato = useCallback((formato) => {
    let formatoResult = [];
    formato && formato.forEach((field) => {
      let item = pivotGridConfig.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (item) {
        let tempField = {
          ...field,
          caption: item.caption,
          dataType: item.dataType,
          name: item.name,
          summaryType: ''
        };

        if (item.summaryType)
          tempField.summaryType = item.summaryType;

        if (!field.area || field.area === '')
          tempField.area = "filter";

        formatoResult.push(tempField);
      } else {
        formatoResult.push(field);
      }
    });

    pivotGridConfig.forEach((field) => {
      if (field.area !== "filter") {
        let item = formato && formato.find((format) => format.name === field.name
          || (format.dataField && format.dataField === field.dataField));

        if (!item) {
          field.area = "filter";
          formatoResult.push(field);
        }
      }
    })

    return formatoResult;
  }, [pivotGridConfig]);

  const onCargarFormato = useCallback((rowData) => {
    const selectedRows = rowData.filasSubtotales ? JSON.parse(rowData.filasSubtotales) : [];
    dispatch(updateSelectedTotalRows(selectedRows));
    dispatch(updateNombreFormato(rowData.nombre));
    const formato = procesarFormato(JSON.parse(rowData.configuracion));
    setEstaCargado(false);
    dispatch(updateVentasPorProductoFormato(formato));
  }, [dispatch, procesarFormato]);

  const onCargarFormatoCubo = useCallback((rowData) => {
    setEstaCargado(false);
    setReloadFilters(true);
    setTimeout(() => setReloadFilters(false), 500);
  }, [dispatch, updateNombreCubo]);

  //#region Overflow X

  const setOverflowX = (modoConfiguracion, fijarFilasColumnas) => {
    const overflowX = modoConfiguracion ? "auto" : fijarFilasColumnas ? "hidden" : "auto";
    setGridContainerOverflowX(overflowX);
  }

  //#endregion

  //#region Cambio Vista
  const orderedFields = useMemo(() => {
    return dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'filter')
      .map((field) => {
        return {
          caption: field.caption && field.caption.replace('|', '') && field.caption.replace('-', ''),
          name: field.name,
        }
      })
      .sort((a, b) => { return a.caption < b.caption ? -1 : 1; });
  }, [dataSource]);

  const onViewChange = useCallback((config) => {
    setCustomLoading(true);
    setConfigFields(config);
    onUpdateConfigFields(config);
    setOverflowX(config.showFields, rowsColumnsFixed);
    setTimeout(() => setCustomLoading(false), 500);
  }, [rowsColumnsFixed, onUpdateConfigFields]);
  //#endregion

  //#region Fijar Filas y Columnas
  const onFixRowsColumns = useCallback(() => {
    let value = !rowsColumnsFixed;

    if (value && configFields && configFields.showFields) {
      setConfigFields({
        showFields: false,
        showColums: false,
        showData: false,
        showFilter: false,
        showRows: false,
        visible: false,
      });
    }

    setCustomLoading(true);
    setRowsColumnsFixed(value);
    setOverflowX(configFields.showFields, value);
    let scroll = value ? "virtual" : "standard";
    setScrollingMode(scroll);
    setTimeout(() => setCustomLoading(false), 500);
  }, [rowsColumnsFixed, configFields]);
  //#endregion

  //#region Customization

  //#region On Cell Prepared
  const isTotalDataRow = (cell) => (cell.area === 'data' && cell.rowType === 'T'
    && (cell.columnType === 'T' || cell.columnType === 'GT'));
  const lessThanCeroCell = (cell) => cell.area === 'data' && cell.rowType === 'D'
    && cell.columnType === 'GT' && cell.dataType === 'number' && cell.value && cell.value < 0;
  const isHeader = (cell) => cell.area === 'column' && cell.type === 'GT';
  const isTotalRow = (cell) => cell.area === 'row' && (cell.type === 'T' || cell.type === 'GT');
  const isGranTotal = (cell) => (cell.area === 'data' && cell.rowType === 'GT' && cell.columnType === 'GT')
    || (cell.area === 'column' && cell.type === 'GT' && cell.text === 'Gran Total');

  const hideStyle = useMemo(() => { return { fill: 'FFF', font: 'FFF', bold: true, border: null, display: 'none' } }, []);
  const style = useMemo(() => { return { 'font-size': 'small', 'text-transform': 'uppercase' } }, []);

  const specialChars = useMemo(() => { return ['| ', '- '] }, []);

  const containsSpecialChars = useCallback((cellText) => {
    let contains = false;

    if (cellText) {
      specialChars.forEach((item) => {
        if (cellText.includes(item))
          contains = true;
      })
    }
    return contains;
  }, [specialChars]);

  const removeSpecialChars = (cellText) => {
    if (cellText) {
      cellText = cellText.replace('| ', '');
      cellText = cellText.replace('- ', '');
    }

    return cellText;
  }

  const getCssStyles = ({ fill, font, bold, border }) => {
    let style = {
      'background-color': `#${fill}`,
      color: `#${font}`,
      'font-weight': bold ? 'bold' : undefined,
      'font-size': 'smaller',
      'text-transform': 'uppercase',
    };

    if (border)
      style['border-color'] = `#${border}`;

    return style;
  }

  const getConditionalAppearance = useCallback((cell, columnIndex, rows) => {
    if (isGranTotal(cell) || isTotalRow(cell) || isTotalDataRow(cell))
      return { fill: 'ffbf66', font: '3F3F3F', bold: true, border: null };

    if (isHeader(cell))
      return { fill: 'dbf9ff', font: '3F3F3F', bold: true, border: null };

    if (lessThanCeroCell(cell))
      return { fill: 'ffafaf', font: '3F3F3F', bold: true, border: null };

    return { fill: 'FFFFFF', font: '3F3F3F', bold: false, border: null };
  }, []);

  const onCellPrepared = useCallback((e) => {
    const { area, cellElement, cell, columnIndex } = e;

    cell.area = area;

    if (cell.area === 'column' && cell.type === 'GT' && containsSpecialChars(cell.text)) {
      cell.text = removeSpecialChars(cell.text);
      cellElement.innerHTML = removeSpecialChars(cellElement.innerHTML);
      cellElement.innerText = removeSpecialChars(cellElement.innerText);
    }

    let defaultStyle = style;
    const appearance = getConditionalAppearance(cell, columnIndex, selectedTotalRows);

    if (appearance)
      defaultStyle = getCssStyles(appearance);

    if ((cell.path && isTotalRow(cell)) || (cell.rowPath && isTotalDataRow(cell))) {
      let rowFields = rowFieldsFromDataSource.current;
      let pathIndex = cell.path ? cell.path.length - 1 : cell.rowPath.length - 1;
      let rowField = rowFields.find((row) => row.areaIndex === pathIndex);

      if (rowField && rowField.hide)
        defaultStyle = hideStyle;
    }

    Object.assign(cellElement.style, defaultStyle);
  }, [containsSpecialChars, getConditionalAppearance, hideStyle, selectedTotalRows, style]);

  //#endregion

  //#region Data Chart 
  const chartFields = useMemo(() => {
    return pivotGridConfig && pivotGridConfig
      .filter((f) => f.caption !== null && f.caption !== undefined)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [pivotGridConfig]);
  const chartOptions = chartFields && chartFields.filter((f) => f.area === "row");
  const chartSeries = chartFields && chartFields.filter((f) => f.area === "data");
  const unidadesItems = []
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name === 'unidadesTotales'))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name === 'unidadesTotalesComparacion'))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name === 'unidadesDiferenciaComparacion'));
  const netosItems = []
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name === 'netoTotal'))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name === 'netoTotalComparacion'))
    .concat(chartSeries && chartSeries.filter((f) => f.name && f.name === 'netoDiferenciaComparacion'));
  const chartFilters = [
    {
      id: 'unidades',
      showMonto: false,
      nombre: 'Unidades',
      seriesItems: unidadesItems,
      chartItems: unidadesItems,
      orderItems: chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('unidades') > -1)
    },
    {
      id: 'neto',
      showMonto: true,
      nombre: 'Importes Netos',
      seriesItems: netosItems,
      chartItems: netosItems,
      orderItems: chartSeries && chartSeries.filter((f) => f.name && f.name.indexOf('neto') > -1)
    },
  ];

  const chartConfig = {
    fields: chartFields,
    options: chartOptions,
    series: chartSeries,
    filters: chartFilters,
    data: datos,
    extraFilters: [],
    relatedFilter: true,
  };

  //#endregion

  //#region Mostrart Total por row
  const [estaCargado, setEstaCargado] = useState(false);

  const onSelectedTotalRowsChange = useCallback((items) => {
    setCustomLoading(true);
    setSelectedTotalRows(items);
    dispatch(updateSelectedTotalRows(items));
    setTimeout(() => setCustomLoading(false), 500);
  }, [dispatch]);

  //#endregion

  //#region On Content Ready 
  const dataConfigFields = useMemo(() => { return pivotGridConfig.filter((item) => item.area === "data"); }, [pivotGridConfig]);

  const onContentReady = useCallback((e) => {
    let ds = e.component.getDataSource();
    let rowFields = ds.fields() && ds.fields().filter((item) => item.area === "row");
    rowFieldsFromDataSource.current = rowFields;

    if (rowFields && rowFields.length > 0 && dataConfigFields && dataConfigFields.length > 0) {
      let reload = false;

      rowFields.forEach((field) => {
        const dataField = dataConfigFields.find((item) => item.name === field.name);

        if (dataField) {
          ds.field(field.name, { area: "filter" });

          if (!reload)
            reload = true;
        }
      });

      if (reload)
        ds.load();
    }

    isLoadingDataSource[0] = false;
  }, [dataConfigFields, isLoadingDataSource]);

  //#endregion

  //#endregion

  //#region UseEffect Methods  

  useEffect(() => {
    if ((!config || config.length === 0) && !loading) {
      let fields = pivotGridConfig;
      let dataStorage = localStorage.getItem(storageKey);

      if (dataStorage) {
        let configStorage = JSON.parse(dataStorage);

        if (configStorage && configStorage.fields && configStorage.fields.length > 0)
          fields = configStorage.fields;
      }

      let formato = procesarFormato(fields);
      setEstaCargado(false);
      onUpdateData(datos, formato, filtros);
    }
  }, [config, loading, datos, filtros, pivotGridConfig, storageKey, procesarFormato, onUpdateData]);

  useEffect(() => {
    if (selectedTotalRowsItems && selectedTotalRowsItems.length > 0 && !estaCargado) {
      const item = selectedTotalRowsItems.find((i) => i.key === storageKey);

      if (item) {
        onSelectedTotalRowsChange(item.rows);
        setEstaCargado(true);
      }
    }
  }, [selectedTotalRowsItems, estaCargado, onSelectedTotalRowsChange, storageKey]);

  useEffect(() => {
    let rowFields = rowFieldsFromDataSource.current;

    if (rowFields && rowFields.length !== 0) {
      let expandedFields = rowFields.filter((field) => field.expanded);

      if (!expandedFields || expandedFields.length === 0) {
        let fieldName = rowFields[0].name;
        dataSource.field(fieldName, { expanded: true });
      }
    }
  }, [rowFieldsFromDataSource, dataSource]);

  useEffect(() => {
    if (!configFields) {
      const cf = pivotGridConfigFields
        ? pivotGridConfigFields
        : {
          showFields: false,
          visible: false,
          showRows: false,
          showFilter: false,
          showData: false,
          showColums: false
        };
      setConfigFields(cf);
    }
  }, [configFields, pivotGridConfigFields]);

  useEffect(() => {
    if (configFields && configFields.showFields && orderedFields && orderedFields.length > 0) {
      orderedFields.forEach((field, index) => { dataSource.field(field.name, { areaIndex: index }); });
      dataSource.load();
    }
  }, [configFields, orderedFields, dataSource]);

  //#endregion

  return (
    <CustomPivotGrid
      title={() => title()}
      subTitle={() => subTitle()}
      fechaActualizacion={fechaActualizacion}
      dataSource={dataSource}
      loading={loading || customLoading}
      rawDatos={datos}
      rawFiltros={filtros}
      resetButton={false}
      exportToExcel={true}
      exportToPdf={true}
      fileName={getFileName(nombreFormato, filtros)}
      sheetName={"Cubo Ventas"}
      storageKey={storageKey}
      onCellClick={onCellClick}
      onCellPrepared={onCellPrepared}
      showConfig={true}
      customizeTooltip={customizeTooltip}
      onUpdateDataSource={onUpdateDataSource}
      onCargarFormato={onCargarFormato}
      onCargarFormatoCubo={onCargarFormatoCubo}
      configFields={configFields}
      onContentReady={onContentReady}
      chartConfig={chartConfig}
      onViewChange={onViewChange}
      showChart={false}
      showChartIcon={false}
      rowsColumnsFixed={rowsColumnsFixed}
      onFixRowsColumns={onFixRowsColumns}
      height={rowsColumnsFixed ? "750px" : "100%"}
      containerWidth={rowsColumnsFixed ? window.innerWidth < 1350 ? "92.5vw" : window.innerWidth < 1900 ? "93.5vw" : "94.5vw" : "100%"}
      gridWidth={"100%"}
      minHeight={450}
      gridContainerOverflowX={gridContainerOverflowX}
      onLoadDataImport={onLoadDataImport}
      selectedTotalRows={selectedTotalRows}
      onSelectedTotalRowsChange={onSelectedTotalRowsChange}
      totalRowsOptions={rowFieldsFromDataSource && rowFieldsFromDataSource.current}
      isLoadingDataSource={isLoadingDataSource && isLoadingDataSource[0]}
      scrollingMode={scrollingMode}
      filtrosChart={filtrosChart}
      onUpdateFiltrosChart={onUpdateFiltrosChart}
      onShowFiltersModal={onShowFiltersModal}
    />
  )
}

export default PivotGridVentasPorProducto;