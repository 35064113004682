import React from "react";
import { storageMultiempresa } from "../../../assets/shared/sessionData";
import EmpresaSelector from "../SelectorEmpresa/EmpresaSelector";
import BoxEjecutarPaginaInfo from "../BoxEjecutarPaginaInfo/BoxEjecutarPaginaInfo";
import { Fragment } from "react";

const ConteinerWithEjecutarPagina = (props) => {
  const { isExecuted, showMultiempresa, children } = props;
  const isMultiemp = storageMultiempresa() === "true";

  return (
    <Fragment>
      {showMultiempresa && isMultiemp && <EmpresaSelector setEmpresasessionStorage />}
      {isExecuted ? <BoxEjecutarPaginaInfo /> : children}
    </Fragment>
  );
};
export default ConteinerWithEjecutarPagina;