import React, { useCallback } from "react";
import { Grid, IconButton, Typography } from '@mui/material';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import IconConvertToPdf from '../../../assets/images/IconConvertToPdf.png';
import Spinner from "../Spinner/Spinner";
import { useDispatch } from "react-redux";
import { showGlobalModal } from "../../../store/slices/modalsSlice";
import { enqueueSnackbar } from "../../../store/slices/snackbarSlice";

const ExportToPdfIcon = (props) => {
  const dispatch = useDispatch();
  const { element, fileName, disabled } = props;

  const onShowModal = useCallback((body) => {
    dispatch(showGlobalModal(true, '', body, undefined, false, true, 'sm'));
  }, [dispatch]);
  const onCloseModal = useCallback(() => {
    dispatch(showGlobalModal(false, '', undefined, undefined, true, true, 'sm'));
  }, [dispatch]);
  const sendNotification = useCallback((notification) => {
    dispatch(enqueueSnackbar(notification));
  }, [dispatch]);

  const body = () => {
    return (
      <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Spinner />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">
            Generando PDF...
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const onExporting = (e) => {
    if (!element)
      return;

    onShowModal(body());

    window.scrollTo(0, 0);

    setTimeout(() => {
      let scale = 0.85;

      html2canvas(element, { logging: false, scale: scale })
        .then(function (canvas) {
          let landscape = true;
          var margin = 15;
          let docWidth = landscape ? 842 : 595;
          let docHeight = landscape ? 595 : 842;
          var htmlWidth = canvas.width;
          var htmlHeight = canvas.height;
          let ratio = landscape ? docHeight / docWidth : docWidth / docHeight;
          var pdfWidth = htmlWidth + (margin * 2);
          var pdfHeight = Math.ceil((pdfWidth * ratio)) + (margin * 2);
          var canvas_image_width = htmlWidth;
          var canvas_image_height = htmlHeight;
          var totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

          canvas.getContext('2d');
          const imgData = canvas.toDataURL('image/png', 1);
          let pdf = new jsPDF("landscape", 'pt', [pdfWidth, pdfHeight], true);
          pdf.addImage(imgData, 'PNG', margin, margin, canvas_image_width, canvas_image_height, undefined, 'FAST');

          if (totalPDFPages > 0) {
            for (var i = 1; i <= totalPDFPages; i++) {
              const position = -(pdfHeight * i) + (margin * 4);
              pdf.addPage([pdfWidth, pdfHeight], "landscape");
              pdf.addImage(imgData, 'PNG', margin, position, canvas_image_width, canvas_image_height, undefined, 'FAST');
            }

            pdf.save(`${fileName}.pdf`);
          } else {
            pdf.save(`${fileName}.pdf`);
          }
        })
        .catch((error) => {
          console.log(error);
          sendNotification({
            message: `Error al generar el PDF, intente nuevamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          });
        })
        .finally(() => onCloseModal());

    }, 1000);

    e.cancel = true;
  }

  return (
    <span title={disabled ? "Descargar en Pdf (no hay datos)" : "Descargar en Pdf"}>
      <IconButton disabled={true} onClick={(e) => onExporting(e)} style={{ marginRight: '10px' }} size="small">
        <img alt="" src={IconConvertToPdf} height={'35px'} />
      </IconButton>
    </span>
  )
}

export default ExportToPdfIcon;