import React, { Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from "@mui/styles";
import MultipleEmails from "../MultipleEmails/MultipleEmails";
import { Avatar, Chip, Grid, TextField } from "@mui/material";
import Buton from "../Button/Button";
import SendIcon from '@mui/icons-material/Send';
import Spinner from "../Spinner/Spinner";
import IconConvertToExcel from '../../../assets/images/IconConvertToExcel.png';
import { enviarArchivosPorMail } from "../../../store/slices/enviarGraficosSlice";
import { showSendAttachmentModal } from "../../../store/slices/modalsSlice";
import { Workbook } from 'exceljs';
import { exportPivotGrid } from 'devextreme/excel_exporter';

const useStyles = makeStyles({
  subject: {
    width: '100%',
    fontSize: 'small',
  },
  message: {
    width: '100%',
    fontSize: 'small',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
  },
  spinnerItems: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  graficos: {
    minWidth: 'fit-content'
  }
});

const EnviarExcelEmailModal = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const pivotGridRef = useSelector(state => state.modals.sendChartModal.current);
  const elementTitle = useSelector(state => state.modals.sendChartModal.titulo);
  const elementType = useSelector(state => state.modals.sendChartModal.type);
  const elementFilename = useSelector(state => state.modals.sendChartModal.filename);

  const closeChartModal = () => dispatch(showSendAttachmentModal(false, null));

  const userEmail = sessionStorage.getItem("userEmail");
  const [requiredMessage] = useState("Campo requerido");
  const [emails, setEmails] = useState([userEmail]);
  const [subject, setSubject] = useState(`Jalisco Farmacias ${elementType === 'excel' ? ": Tabla " : "- "} ${elementTitle ? `${elementTitle}` : ""}`);
  const [message, setMessage] = useState("");
  const [errorEmails, setErrorEmails] = useState(false);
  const [errorSubject, setErrorSubject] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [sending, setSending] = useState(false);

  const isvalid = (item) => {
    if (item === null || item === undefined) {
      return false;
    } else {
      if (typeof item === 'string' || item instanceof String) {
        const itemTrim = item.trim();

        if (itemTrim === "") {
          return false;
        }
      }
    }

    return true;
  }

  const formDisabled = () => {
    return errorEmails || errorSubject || errorSubject || !emails || emails.length === 0 || !isvalid(subject) || !isvalid(message);
  }

  const onSendEmailSuccess = (response) => {
    closeChartModal();
    setTimeout(() => setSending(false), 500);
  }

  const onSendEmailError = (error) => {
    setTimeout(() => setSending(false), 500);
  }

  const onSendEmail = (event) => {
    setSending(true);
    setErrorSubject(!isvalid(subject));
    setErrorMessage(!isvalid(message));
    setErrorEmails(emails === null || emails.length === 0);

    onExporting(event);
  }

  //#region Email

  const onEmailsChange = useCallback((items) => {
    setEmails(items);
    setErrorEmails(items === null || items.length === 0);
  }, []);

  const onSubjectChange = useCallback((evt) => {
    const value = evt.target.value;
    setErrorSubject(!isvalid(value));
    setSubject(value);
  }, []);

  const onMessageChange = useCallback((evt) => {
    const value = evt.target.value;
    setErrorMessage(!isvalid(value));
    setMessage(value);
  }, []);

  //#endregion

  //#region Attachment

  const showExcel = () => {
    return (
      <Chip
        avatar={<Avatar alt="Excel" src={IconConvertToExcel} />}
        label={elementFilename}
        variant="outlined"
      />
    )
  }

  function getExcelCellFormat({ fill, font, bold }) {
    return {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } },
      font: { color: { argb: font }, bold },
      alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    };
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(elementTitle);

    if (!pivotGridRef || !pivotGridRef.instance)
      return;

    const dataSource = pivotGridRef.instance.getDataSource();
    let rowFields = [];
    let dataFields = [];

    if (dataSource) {
      const fields = dataSource._fields;

      if (fields && fields.length !== 0) {
        rowFields = fields.filter((field) => field.area === "row");
        dataFields = fields.filter((field) => field.area === "data");

        if ((rowFields && rowFields.length !== 0) || (dataFields && dataFields.length !== 0)) {
          const columns = [];

          rowFields.forEach(element => {
            columns.push({ width: element.width ? element.width : 25 });
          });

          dataFields.forEach(element => {
            columns.push({ width: element.width ? element.width : 25 });
          });

          worksheet.columns = columns;
        }
      }
    }

    exportPivotGrid({
      component: pivotGridRef.instance,
      worksheet: worksheet,
      topLeftCell: { row: 3, column: 1 },
      keepColumnWidths: false,
      exportDataFieldHeaders: false,
      exportRowFieldHeaders: true,
      exportColumnFieldHeaders: false,
      exportFilterFieldHeaders: false,
      customizeCell: ({ pivotCell, excelCell }) => {
        const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
        excelCell.border = {
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle,
          top: borderStyle,
        };
      },
    })
      .then((cellRange) => {
        const headerRow = worksheet.getRow(4);
        const firstHeader = rowFields[0].caption;
        const appearance = { fill: 'F2F2F2', font: '3F3F3F', bold: true };
        let totalFields = dataFields.length;

        rowFields.forEach(element => {
          const headerCell = headerRow.getCell(element.areaIndex + 1);

          if (headerCell && headerCell.value && typeof headerCell.value === 'string') {
            if (headerCell.value.indexOf(firstHeader) > -1) {
              headerCell.value = element.caption.replace('-', '');
              totalFields += 1;
            } else {
              headerCell.value = headerCell.value.replace('-', '');
            }
          }
        });

        for (let index = 1; index <= totalFields; index++) {
          const headerCell = headerRow.getCell(index);

          if (headerCell) {
            Object.assign(headerCell, getExcelCellFormat(appearance));
          }
        }
      })
      .then(function () {
        workbook.xlsx.writeBuffer()
          .then(function (buffer) {
            const content = new Blob([buffer], { type: 'application/octet-stream' });
            const formFile = new FormData();
            formFile.append('destinatarios', JSON.stringify(emails));
            formFile.append('asunto', subject);
            formFile.append('mensaje', message);
            formFile.append('file', content, `${elementFilename}.xlsx`);

            dispatch(enviarArchivosPorMail({ data: formFile, success: onSendEmailSuccess, onError: onSendEmailError }));
          })
      })
    e.cancel = true;
  }

  //#endregion

  return (
    <Grid container spacing={1} >
      <Grid item xs={12} sm={12} md={12}>
        <MultipleEmails
          items={emails}
          setItems={onEmailsChange}
          errorItems={errorEmails}
          errorItemsMessage={requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          label="Asunto"
          variant="outlined"
          size="small"
          className={classes.subject}
          value={subject}
          onChange={(e) => onSubjectChange(e)}
          required={true}
          error={errorSubject}
          helperText={errorSubject && requiredMessage}
          disabled={sending}
        />
      </Grid>
      {elementType === 'excel' && (
        <Grid item xs={12} sm={12} md={12}>
          {showExcel()}
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          label="Mensaje"
          variant="outlined"
          size="small"
          className={classes.subject}
          value={message}
          multiline
          rows={4}
          onChange={(e) => onMessageChange(e)}
          required={true}
          inputProps={{
            maxLength: 500,
          }}
          error={errorMessage}
          helperText={errorSubject && requiredMessage}
          disabled={sending}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} container className={classes.buttonContainer} >
        <Grid item xs={6} sm={4} md={3}>
          <Buton
            style={{ width: '95%', padding: "4px" }}
            color="primary"
            startIcon={<SendIcon />}
            onClick={onSendEmail}
            disabled={sending || formDisabled()}
          >
            Enviar
          </Buton>
        </Grid>
      </Grid>
      <Grid
        item xs={12} sm={12} md={12}
        className={classes.graficos}
      >
        {sending
          ? (
            <div className={classes.spinnerItems}>
              <Spinner personalSize="75px" />
            </div>
          ) : <Fragment></Fragment>
        }
      </Grid>
    </Grid>
  )
}

export default EnviarExcelEmailModal;