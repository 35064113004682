import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '../../UI/Dialog/Dialog';
import { hiddenGlobalModal } from '../../../store/slices/modalsSlice';
import classes from './ModalGlobal.module.css';

export const ModalGlobal = () => {
  const dispatch = useDispatch();
  const { show, title, body, disableCloseButton, fullWidth, maxWidth } = useSelector(state => state.modals.globalModal);
  const onHiddenModal = () => dispatch(hiddenGlobalModal());

  return (
    <Dialog
      title={
        title ? (
          <div classes={classes.TitleDialogContent} style={{ width: '100%' }}>
            <div className={classes.TitleDialogTexto}>
              <Typography variant="h5">{title}</Typography>
              {!disableCloseButton && (
                <div>
                  <IconButton aria-label="close" onClick={() => onHiddenModal()}>
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        ) : undefined
      }
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      show={show}
      body={body}
      onClose={() => onHiddenModal()}
    />
  );
};

export default ModalGlobal;