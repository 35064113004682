import React from "react"
import classes from "./PiePlaceholder.module.css"
import AdsClick from '../../../../assets/images/AdsClick.svg';

const PiePlaceholderPrimerNivel = (props) => {
  return (
    <div className={classes.Container}>
      <img alt="" src={AdsClick} className={classes.Imagen} />
      <div className={classes.TextContainer}>
        <p className={classes.Text}>
          Haga clic en una porción de "<span className={classes.HighlightText}>Ventas por importe neto</span>" o "<span className={classes.HighlightText}>Ventas por unidades</span>" para cargar este gráfico desglosado
        </p>
      </div>
    </div>
  )
}

export default PiePlaceholderPrimerNivel; 