import React, { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import MonedaSelector from "../UI/MonedaSelector/MonedaSelector";
import MenuUsuario from "../MenuUsuario/MenuUsuario";
import MenuApps from "../MenuApps/MenuApps";
import UltimaActualizacion from "../UltimaActualizacion/UltimaActualizacion";
import ButtonEjecucionPagina from "../EjecucionPagina/ButtonEjecucionPagina";
import ButtonTomarEnCuentaInflacion from "../Configuraciones/TomarEnCuentaInflacion/ButtonTomarEnCuentaInflacion";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FechaFijaSelector from "../UI/FechaFijaSelector/FechaFijaSelector";
import MenuConfiguraciones from "../Configuraciones/MenuConfiguraciones/MenuConfiguraciones";
import SucursalSelector from '../UI/SucursalSelector/SucursalSelector';
import { tieneConfiguradoFarmacias } from "../../assets/shared/sessionData";

const useStyles = makeStyles((theme) => ({
  toolbarUsuario: {
    display: 'none',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  mobileToolbarUsuario: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  UsuarioLog: {
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
  },
  MonedaSelector: {
    color: "white",
    marginLeft: "10px",
    marginRight: "10px",
  },
  menuItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: 'flex-start',
  },
}));

const ToolbarComponents = () => {
  const classes = useStyles();
  const onSeleccionarMoneda = () => {
    handleClose();
  }

  const monedaSelect = () => {
    return (
      <div className={classes.Selector} style={{ display: 'none' }}>
        <MonedaSelector
          letraColor="white"
          onSeleccionarMoneda={onSeleccionarMoneda}
          disabled={!tieneConfiguradoFarmacias()}
        />
      </div>
    )
  }

  const sucursalSelect = () => {
    return (
      <div>
        <SucursalSelector letraColor="white" />
      </div>
    );
  };

  //#region  Toolbar Usuario
  const toolbarUsuario = () => {
    return (
      <Fragment>
        {tieneConfiguradoFarmacias() && <ButtonEjecucionPagina />}
        {sucursalSelect()}
        <ButtonTomarEnCuentaInflacion
          puedeModificarInflacion
          disabled={!tieneConfiguradoFarmacias()}
        />
        {monedaSelect()}
        <FechaFijaSelector
          disabled={!tieneConfiguradoFarmacias()}
        />
        <UltimaActualizacion
          ultPath={global.window.location.href}
          iconSize="25px"
          disabled={!tieneConfiguradoFarmacias()}
        />
        <div className={classes.MenuConfiguraciones}
          style={{ display: "none", alignItems: "center" }}
        >
          <MenuConfiguraciones iconSize="25px" />
        </div>
        <MenuApps iconSize="25px" />
        <div className={classes.UsuarioLog}>
          <MenuUsuario />
        </div>
      </Fragment>
    )
  }

  //#endregion

  //#region Mobile Toolbar Usuario
  const ITEM_HEIGHT = 48;

  const [anchorEl, setAnchorEl] = useState(null);
  const openMore = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const mobileToolbarUsuario = () => {
    return (
      <Fragment>
        <ButtonEjecucionPagina />
        <div className={classes.MenuConfiguraciones} style={{ display: 'flex', alignItems: 'center' }}>
          <MenuConfiguraciones iconSize="25px" />
        </div>
        <MoreVertIcon
          onClick={handleClick}
          style={{ color: 'white', fontSize: '1.8rem', cursor: 'pointer' }}
        />
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openMore}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              backgroundColor: 'RGB(81,81,81)',
            },
          }}
        >
          <MenuItem className={classes.menuItem}>
            <ButtonTomarEnCuentaInflacion puedeModificarInflacion />
          </MenuItem>
          <MenuItem className={classes.menuItem} style={{ color: 'white' }}>
            <Typography style={{ marginInline: '10px' }}>Moneda: </Typography>
            {monedaSelect()}
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <FechaFijaSelector iconSize="25px" color={'white'} />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <UltimaActualizacion ultPath={global.window.location.href} iconSize="25px" color={'white'} />
          </MenuItem>
          <MenuItem className={classes.menuItem}>
            <MenuApps iconSize="25px" />
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  //#endregion

  return (
    <Fragment>
      <div className={classes.toolbarUsuario}>
        {toolbarUsuario()}
      </div>

      <div className={classes.mobileToolbarUsuario}>
        {mobileToolbarUsuario()}
      </div>
    </Fragment>
  );
};

export default ToolbarComponents;