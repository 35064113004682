import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';
import moment from 'moment';
import { baseUrlDashboard } from '../../assets/shared/urlApps';
import idIndicadores from '../../assets/shared/idIndicadores';
import { enqueueSnackbar } from './snackbarSlice';
import { useDispatch } from 'react-redux';
import { storageEmpresaId, storageToken } from '../../assets/shared/sessionData';

//#region FUNCTIONS

export const cambiarFechaFija = createAsyncThunk(
  'configuraciones/cambiarFechaFija',
  async (fechaFija) => {
    const response = await axios.put(`${baseUrlDashboard()}configuraciones/cambiarFechaFija?clienteId=${sessionStorage.getItem('clienteId')}`, fechaFija);
    return response.data;
  }
);

export const loadIndicadores = createAsyncThunk(
  'configuraciones/loadIndicadores',
  async () => {
    const response = await axios.get(`${baseUrlDashboard()}configuraciones/IndicadoresPorUsuario?indicadorId=${idIndicadores.indicadoresPorUsuario}`);
    return response.data;
  }
);

export const loadIndicadoresOcultos = createAsyncThunk(
  'configuraciones/loadIndicadoresOcultos',
  async (success) => {
    const response = await axios.get(`${baseUrlDashboard()}configuraciones/IndicadoresOcultosPorUsuario?indicadorId=${idIndicadores.indicadoresOcultosPorUsuario}`);
    const indicadoresOcultos = {
      ventasDelMes: response.data.includes(idIndicadores.ventasDelMes),
      ventasDelDia: response.data.includes(idIndicadores.ventasDelDia),
      ivaFiscal: response.data.includes(idIndicadores.ivaFiscalDelMes),
      cobranzasDelMes: response.data.includes(idIndicadores.cobranzasDelMes),
      gastosPorCategoria: response.data.includes(idIndicadores.gastosPorCategoria),
      saldoDeudores: response.data.includes(idIndicadores.saldoDeudores),
      saldoAcreedores: response.data.includes(idIndicadores.saldoAcreedores),
      plazoMedioVencACobrar: response.data.includes(idIndicadores.plazoMedioVencACobrar),
      plazoMedioVencAPagar: response.data.includes(idIndicadores.plazoMedioVencAPagar),
      promDiasDeCobranzas: response.data.includes(idIndicadores.promDiasDeCobranzas),
      promDiasDePagos: response.data.includes(idIndicadores.promDiasDePagos),
      chequesEnCartera: response.data.includes(idIndicadores.chequesEnCartera),
      chequesEmitidos: response.data.includes(idIndicadores.chequesEmitidos),
      gastosDelMes: response.data.includes(idIndicadores.gastosDelMes),
      pagosDelMes: response.data.includes(idIndicadores.pagosDelMes),
      ivaFiscalMultiempresa: response.data.includes(idIndicadores.ivaFiscalMultiempresa),
      chequesEnCarteraMultiempresa: response.data.includes(idIndicadores.chequesEnCarteraMultiempresa),
      chequesEmitidosPendientesMultiempresa: response.data.includes(idIndicadores.chequesEmitidosPendientesMultiempresa),
      cobranzasMensualesMultiempresa: response.data.includes(idIndicadores.cobranzasMensualesMultiempresa),
      gastosDelMesMultiempresa: response.data.includes(idIndicadores.gastosDelMesMultiempresa),
      pagosDelMesMultiempresa: response.data.includes(idIndicadores.pagosDelMesMultiempresa),
      saldoAcreedoresMultiempresa: response.data.includes(idIndicadores.saldoAcreedoresMultiempresa),
      saldoDeudoresMultiempresa: response.data.includes(idIndicadores.saldoDeudoresMultiempresa),
      ventasDiariasMultiempresa: response.data.includes(idIndicadores.ventasDiariasMultiempresa),
      ventasMensualesMultiempresa: response.data.includes(idIndicadores.ventasMensualesMultiempresa),
      rentabilidadMultiempresa: response.data.includes(idIndicadores.rentabilidadMultiempresa),
      deudaPorCategoriaDeCliente: response.data.includes(idIndicadores.deudaPorCategoriaDeCliente),
      deudaPorCategoriaDeProveedor: response.data.includes(idIndicadores.deudaPorCategoriaDeProveedor)
    };

    if (success)
      success();

    return indicadoresOcultos;
  }
);

export const postIndicadoresOcultos = createAsyncThunk(
  'configuraciones/postIndicadoresOcultos',
  async (indicadores) => {
    const response = await axios.post(`${baseUrlDashboard()}configuraciones/IndicadoresPorUsuario`, indicadores);
    return response.data;
  }
);

export const loadCategoriasItemsDeCompras = createAsyncThunk(
  'configuraciones/loadCategoriasItemsDeCompras',
  async (empresaId = storageEmpresaId()) => {
    const response = await axios.get(`${baseUrlDashboard()}configuraciones/CategoriasItemsDeCompras?empresaId=${empresaId}&indicadorId=${idIndicadores.categoriaDeItems}`);
    return response.data;
  }
);

export const loadEstadosCheques = createAsyncThunk(
  'configuraciones/loadEstadosCheques',
  async (idEmpresa = storageEmpresaId()) => {
    const response = await axios.get(`${baseUrlDashboard()}cheques/Estados?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.estadosCheques}`);
    return response.data;
  }
);

export const loadChequesValorEstado = createAsyncThunk(
  'configuraciones/loadChequesValorEstado',
  async ({ propios, success, errorFail, idEmpresa = storageEmpresaId() }) => {
    const response = axios.get(`${baseUrlDashboard()}cheques/ValoresYEstados?propios=${propios}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.chequesValorEstado}`);

    response
      .then((response) => {
        return success ? success(response.data) : response.data;
      })
      .catch((error) => {
        return errorFail && errorFail(error);
      });
  }
);

export const postChequesValorEstado = createAsyncThunk(
  'configuraciones/postChequesValorEstado',
  async (valoresYEstados, propios, idEmpresa = storageEmpresaId(), success, fail) => {
    axios.post(`${baseUrlDashboard()}cheques/ValoresYEstados?propios=${propios}&idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.postChequesValorEstado}`,
      valoresYEstados)
      .then((response) => {
        return success && success(response.data);
      })
      .catch((error) => {
        return fail && fail(error);
      });
  }
);

export const loadComprobantesVentas = createAsyncThunk(
  'configuraciones/loadComprobantesVentas',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}Ventas/ComprobantesVentas?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesVentas}`);
    return response.data;
  }
);

export const loadComprobantesGastos = createAsyncThunk(
  'configuraciones/loadComprobantesGastos',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}gastos/ComprobantesGastos?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesGastos}`);
    return response.data;
  }
);

export const loadComprobantesPagos = createAsyncThunk(
  'configuraciones/loadComprobantesPagos',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}compras/ComprobantesPagos?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesGastos}`);
    return response.data;
  }
);

export const loadComprobantesCompras = createAsyncThunk(
  'configuraciones/loadComprobantesCompras',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}compras/ComprobantesCompras?idEmpresa=${idEmpresa}&indicadorId=${idIndicadores.comprobantesGastos}`);
    return response.data;
  }
);

export const loadComprobantesPedidos = createAsyncThunk(
  'configuraciones/loadComprobantesPedidos',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}ventas/ComprobantesPedidos?empresaId=${idEmpresa}&indicadorId=${idIndicadores.comprobantesGastos}`);
    return response.data;
  }
);

export const loadComprobantesCobranzas = createAsyncThunk(
  'configuraciones/loadComprobantesCobranzas',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}ventas/ComprobantesCobranzas?empresaId=${idEmpresa}&indicadorId=${idIndicadores.comprobantesGastos}`);
    return response.data;
  }
);

export const loadCategoriasDeProveedor = createAsyncThunk(
  'configuraciones/loadCategoriasDeProveedor',
  async (empresaId) => {
    const response = await axios.get(`${baseUrlDashboard()}configuraciones/CategoriasDeProveedores?empresaId=${empresaId}&indicadorId=${idIndicadores.categoriasDeProveedor}`);
    return response.data;
  }
);

export const loadCategoriasDelCliente = createAsyncThunk(
  'configuraciones/loadCategoriasDelCliente',
  async (idEmpresa) => {
    const response = await axios.get(`${baseUrlDashboard()}ventas/CategoriasDeCliente?empresaId=${idEmpresa}&indicadorId=${idIndicadores.categoriasDelCliente}`);
    return response.data;
  }
);

export const cambiarAjustaPorInflacion = createAsyncThunk(
  'configuraciones/cambiarAjustaPorInflacion',
  async (ajustaInflacion) => {
    const response = await axios.put(`${baseUrlDashboard()}configuraciones/AjustaPorInflacion?calculoConInflacion=${ajustaInflacion}`, {});
    return response.data;
  }
);

export const loadInflacion = createAsyncThunk(
  'configuraciones/loadInflacion',
  async () => {
    const response = await axios.get(`${baseUrlDashboard()}configuraciones/Inflacion?listaSoloUltimoAnio=true`);
    return response.data;
  }
);

export const loadInflacionPorAnio = createAsyncThunk(
  'configuraciones/loadInflacionPorAnio',
  async () => {
    const response = await axios.get(`${baseUrlDashboard()}configuraciones/InflacionPorAnio`);
    return response.data;
  }
);

export const postComprobantesGastos = createAsyncThunk(
  'configuraciones/postComprobantesGastos',
  async (empresaId, comprobantes) => {
    const response = await axios.post(`${baseUrlDashboard()}gastos/ComprobantesGastos?idEmpresa=${empresaId}`, comprobantes);
    return response.data;
  }
);

export const postComprobantesCobranzas = createAsyncThunk(
  'configuraciones/postComprobantesCobranzas',
  async (empresaId, comprobantes) => {
    const response = await axios.post(`${baseUrlDashboard()}ventas/ComprobantesCobranzas?idEmpresa=${empresaId}`, comprobantes);
    return response.data;
  }
);

export const postComprobantesCompras = createAsyncThunk(
  'configuraciones/postComprobantesCompras',
  async (empresaId, comprobantes) => {
    const response = await axios.post(`${baseUrlDashboard()}compras/ComprobantesCompras?idEmpresa=${empresaId}`, comprobantes);
    return response.data;
  }
);

export const postComprobantesPagos = createAsyncThunk(
  'configuraciones/postComprobantesPagos',
  async (empresaId, comprobantes) => {
    const response = await axios.post(`${baseUrlDashboard()}compras/ComprobantesPagos?idEmpresa=${empresaId}`, comprobantes);
    return response.data;
  }
);

export const postComprobantesPedidos = createAsyncThunk(
  'configuraciones/postComprobantesPedidos',
  async (empresaId, comprobantes) => {
    const response = await axios.post(`${baseUrlDashboard()}ventas/ComprobantesPedidos?idEmpresa=${empresaId}`, comprobantes);
    return response.data;
  }
);

export const postComprobantesVentas = createAsyncThunk(
  'configuraciones/postComprobantesVentas',
  async (empresaId, comprobantes) => {
    const response = await axios.post(`${baseUrlDashboard()}ventas/ComprobantesVentas?idEmpresa=${empresaId}`, comprobantes);
    return response.data;
  }
);

export const postCategoriasItemsCompra = createAsyncThunk(
  'configuraciones/postCategoriasItemsCompra',
  async (empresaId, categItems) => {
    const response = await axios.post(`${baseUrlDashboard()}configuraciones/CategoriasItemsDeCompras?idEmpresa=${empresaId}`, categItems);
    return response.data;
  }
);

export const postCategoriasDelCliente = createAsyncThunk(
  'configuraciones/postCategoriasDelCliente',
  async (empresaId, categorias) => {
    const response = await axios.post(`${baseUrlDashboard()}ventas/CategoriasDeCliente?idEmpresa=${empresaId}`, categorias);
    return response.data;
  }
);

export const postCategoriasDeProveedor = createAsyncThunk(
  'configuraciones/postCategoriasDeProveedor',
  async (empresaId, categItems) => {
    const response = await axios.post(`${baseUrlDashboard()}configuraciones/CategoriasDeProveedores?idEmpresa=${empresaId}`, categItems);
    return response.data;
  }
);

export const checkTieneFarmaciasConfiguradas = createAsyncThunk(
  'configuraciones/TieneFarmaciasConfiguradas',
  async () => {
    const idEmpresa = storageEmpresaId();
    const authStr = 'Bearer '.concat(storageToken());
    const response = await axios.get(`${baseUrlDashboard()}farmacias/TieneFarmaciasConfiguradas?idEmpresa=${idEmpresa}`,
      { headers: { Authorization: authStr } });
    return response.data;
  }
);

const onLoadStart = (state, key) => {
  state[key].items = [];
  state[key].loading = true;
  state[key].error = null;
  state[key].estaCargado = false;
}

const onLoadSuccess = (state, key, response) => {
  state[key].items = response.payload;
  state[key].loading = false;
  state[key].error = null;
  state[key].estaCargado = true;
}

const onLoadError = (state, key, error) => {
  state[key].loading = false;
  state[key].estaCargado = true;
  state[key].error = error.response
    ? error.response.data
    : "Se produjo un error conectando con el servidor";
};

const onPostStart = (state) => {
  state.postComprobantes.loading = true;
}

const onPostSuccess = (state) => {
  state.postComprobantes.loading = true;
  window.location.reload();
}

const onPostError = (state, dispatch) => {
  state.postComprobantes.loading = true;
  dispatch(
    enqueueSnackbar({
      message: `Error al guardar los cambios, intente nuevamente`,
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error'
      }
    })
  );
}

const initialState = {
  postIndicadoresOcultos: {
    loading: false
  },
  postComprobantes: {
    loading: false
  },
  postCategoriasItemsDeCompras: {
    loading: false
  },
  loadTiemposRequest: {
    indicadores: [],
    tiempos: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  actualizacionIndicadores: {
    analisisDeudaCliente: false,
    gastos: false,
    multiempresa: false
  },
  executeCambiarAjustaPorInflacion: false,

  indicadoresPorUsuario: {
    indicadores: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  indicadoresOcultosPorUsuario: {
    items: {
      ventasDelMes: false,
      ventasDelDia: false,
      ivaFiscal: false,
      cobranzasDelMes: false,
      gastosPorCategoria: false,
      saldoDeudores: false,
      saldoAcreedores: false,
      plazoMedioVencACobrar: false,
      plazoMedioVencAPagar: false,
      promDiasDeCobranzas: false,
      promDiasDePagos: false,
      chequesEnCartera: false,
      chequesEmitidos: false,
      gastosDelMes: false,
      pagosDelMes: false,
      ivaFiscalMultiempresa: false,
      chequesEnCarteraMultiempresa: false,
      chequesEmitidosPendientesMultiempresa: false,
      cobranzasMensualesMultiempresa: false,
      gastosDelMesMultiempresa: false,
      pagosDelMesMultiempresa: false,
      saldoAcreedoresMultiempresa: false,
      saldoDeudoresMultiempresa: false,
      ventasDiariasMultiempresa: false,
      ventasMensualesMultiempresa: false,
      rentabilidadMultiempresa: false,
      deudaPorCategoriaDeCliente: false,
      deudaPorCategoriaDeProveedor: false
    },
    loading: true,
    error: null,
    estaCargado: false
  },
  loadCategoriasItemsDeCompras: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  categoriasDeProveedor: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  categoriasDelCliente: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false,
  },
  loadInflacion: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadInflacionPorAnio: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadNovedades: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadEstadosCheques: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  loadChequesValorEstado: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  comprobantesPedidos: {
    items: [],
    loading: false,
    error: null,
    estaCargado: false
  },
  comprobantesVentas: {
    loading: false,
    items: [],
    error: null,
    estaCargado: false,
  },
  comprobantesGastos: {
    loading: false,
    items: [],
    error: null,
    estaCargado: false,
  },
  comprobantesPagos: {
    loading: false,
    items: [],
    error: null,
    estaCargado: false,
  },
  comprobantesCompras: {
    loading: false,
    items: [],
    error: null,
    estaCargado: false,
  },
  comprobantesCobranzas: {
    loading: false,
    items: [],
    error: null,
    estaCargado: false,
  },
  tieneConfiguradoFarmacias: {
    loading: false,
    tieneConfigurada: false,
  },
};

export const configuracionesSlice = createSlice({
  name: 'configuraciones',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(cambiarFechaFija.fulfilled, (state, fecha) => {
        sessionStorage.setItem('fechaFija', moment(fecha).format('MM-DD-YYYY'));
        window.location.reload();
      })
      .addCase(loadIndicadores.pending, (state) => {
        onLoadStart(state, "indicadoresPorUsuario");
      })
      .addCase(loadIndicadores.fulfilled, (state, response) => {
        onLoadSuccess(state, "indicadoresPorUsuario", response);
      })
      .addCase(loadIndicadores.rejected, (state, error) => {
        onLoadError(state, "indicadoresPorUsuario", error);
      })
      .addCase(loadIndicadoresOcultos.pending, (state) => {
        onLoadStart(state, "indicadoresOcultosPorUsuario");
      })
      .addCase(loadIndicadoresOcultos.fulfilled, (state, response) => {
        onLoadSuccess(state, "indicadoresOcultosPorUsuario", response);
      })
      .addCase(loadIndicadoresOcultos.rejected, (state, error) => {
        onLoadError(state, "indicadoresOcultosPorUsuario", error);
      })
      .addCase(postIndicadoresOcultos.pending, (state) => {
        onPostStart(state, 'postIndicadoresOcultos');
      })
      .addCase(postIndicadoresOcultos.fulfilled, (state) => {
        onPostSuccess(state, 'postIndicadoresOcultos');
      })
      .addCase(postIndicadoresOcultos.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(cambiarAjustaPorInflacion.pending, (state) => {
        onPostStart(state, 'executeCambiarAjustaPorInflacion');
      })
      .addCase(cambiarAjustaPorInflacion.fulfilled, (state) => {
        onPostSuccess(state, 'executeCambiarAjustaPorInflacion');
      })
      .addCase(cambiarAjustaPorInflacion.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(loadInflacion.pending, (state) => {
        onLoadStart(state, "loadInflacion");
      })
      .addCase(loadInflacion.fulfilled, (state, response) => {
        onLoadSuccess(state, "loadInflacion", response);
      })
      .addCase(loadInflacion.rejected, (state, error) => {
        onLoadError(state, "loadInflacion", error);
      })
      .addCase(loadInflacionPorAnio.pending, (state) => {
        onLoadStart(state, "loadInflacionPorAnio");
      })
      .addCase(loadInflacionPorAnio.fulfilled, (state, response) => {
        onLoadSuccess(state, "loadInflacionPorAnio", response);
      })
      .addCase(loadInflacionPorAnio.rejected, (state, error) => {
        onLoadError(state, "loadInflacionPorAnio", error);
      })
      .addCase(loadEstadosCheques.pending, (state) => {
        onLoadStart(state, "loadEstadosCheques");
      })
      .addCase(loadEstadosCheques.fulfilled, (state, response) => {
        onLoadSuccess(state, "loadEstadosCheques", response);
      })
      .addCase(loadEstadosCheques.rejected, (state, error) => {
        onLoadError(state, "loadEstadosCheques", error);
      })
      .addCase(loadChequesValorEstado.pending, (state) => {
        onLoadStart(state, "loadChequesValorEstado");
      })
      .addCase(loadChequesValorEstado.fulfilled, (state, response) => {
        onLoadSuccess(state, "loadChequesValorEstado", response);
      })
      .addCase(loadChequesValorEstado.rejected, (state, error) => {
        onLoadError(state, "loadChequesValorEstado", error);
      })
      .addCase(loadComprobantesVentas.pending, (state) => {
        onLoadStart(state, "comprobantesVentas");
      })
      .addCase(loadComprobantesVentas.fulfilled, (state, response) => {
        onLoadSuccess(state, "comprobantesVentas", response);
      })
      .addCase(loadComprobantesVentas.rejected, (state, error) => {
        onLoadError(state, "comprobantesVentas", error);
      })
      .addCase(loadComprobantesGastos.pending, (state) => {
        onLoadStart(state, "comprobantesGastos");
      })
      .addCase(loadComprobantesGastos.fulfilled, (state, response) => {
        onLoadSuccess(state, "comprobantesGastos", response);
      })
      .addCase(loadComprobantesGastos.rejected, (state, error) => {
        onLoadError(state, "comprobantesGastos", error);
      })
      .addCase(loadComprobantesPagos.pending, (state) => {
        onLoadStart(state, "comprobantesPagos");
      })
      .addCase(loadComprobantesPagos.fulfilled, (state, response) => {
        onLoadSuccess(state, "comprobantesPagos", response);
      })
      .addCase(loadComprobantesPagos.rejected, (state, error) => {
        onLoadError(state, "comprobantesPagos", error);
      })
      .addCase(loadComprobantesCompras.pending, (state) => {
        onLoadStart(state, "comprobantesCompras");
      })
      .addCase(loadComprobantesCompras.fulfilled, (state, response) => {
        onLoadSuccess(state, "comprobantesCompras", response);
      })
      .addCase(loadComprobantesCompras.rejected, (state, error) => {
        onLoadError(state, "comprobantesCompras", error);
      })
      .addCase(loadComprobantesPedidos.pending, (state) => {
        onLoadStart(state, "comprobantesPedidos");
      })
      .addCase(loadComprobantesPedidos.fulfilled, (state, response) => {
        onLoadSuccess(state, "comprobantesPedidos", response);
      })
      .addCase(loadComprobantesPedidos.rejected, (state, error) => {
        onLoadError(state, "comprobantesPedidos", error);
      })
      .addCase(loadComprobantesCobranzas.pending, (state) => {
        onLoadStart(state, "comprobantesCobranzas");
      })
      .addCase(loadComprobantesCobranzas.fulfilled, (state, response) => {
        onLoadSuccess(state, "comprobantesCobranzas", response);
      })
      .addCase(loadComprobantesCobranzas.rejected, (state, error) => {
        onLoadError(state, "comprobantesCobranzas", error);
      })
      .addCase(loadCategoriasDeProveedor.pending, (state) => {
        onLoadStart(state, "categoriasDeProveedor");
      })
      .addCase(loadCategoriasDeProveedor.fulfilled, (state, response) => {
        onLoadSuccess(state, "categoriasDeProveedor", response);
      })
      .addCase(loadCategoriasDeProveedor.rejected, (state, error) => {
        onLoadError(state, "categoriasDeProveedor", error);
      })
      .addCase(loadCategoriasDelCliente.pending, (state) => {
        onLoadStart(state, "categoriasDelCliente");
      })
      .addCase(loadCategoriasDelCliente.fulfilled, (state, response) => {
        onLoadSuccess(state, "categoriasDelCliente", response);
      })
      .addCase(loadCategoriasDelCliente.rejected, (state, error) => {
        onLoadError(state, "categoriasDelCliente", error);
      })
      .addCase(postComprobantesGastos.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postComprobantesGastos.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postComprobantesGastos.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postComprobantesCobranzas.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postComprobantesCobranzas.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postComprobantesCobranzas.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postComprobantesCompras.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postComprobantesCompras.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postComprobantesCompras.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postComprobantesPagos.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postComprobantesPagos.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postComprobantesPagos.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postComprobantesPedidos.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postComprobantesPedidos.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postComprobantesPedidos.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postComprobantesVentas.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postComprobantesVentas.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postComprobantesVentas.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postCategoriasItemsCompra.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postCategoriasItemsCompra.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postCategoriasItemsCompra.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postCategoriasDelCliente.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postCategoriasDelCliente.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postCategoriasDelCliente.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(postCategoriasDeProveedor.pending, (state) => {
        onPostStart(state);
      })
      .addCase(postCategoriasDeProveedor.fulfilled, (state) => {
        onPostSuccess(state);
      })
      .addCase(postCategoriasDeProveedor.rejected, (state) => {
        const dispatch = useDispatch();
        onPostError(state, dispatch);
      })
      .addCase(checkTieneFarmaciasConfiguradas.pending, (state) => {
        state.tieneConfiguradoFarmacias = {
          loading: true,
          tieneConfigurada: false
        };
      })
      .addCase(checkTieneFarmaciasConfiguradas.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          state.tieneConfiguradoFarmacias = {
            loading: false,
            tieneConfigurada: action.payload.data
          };
          sessionStorage.setItem('tieneConfiguradoFarmacias', action.payload.data);
        } else {
          state.tieneConfiguradoFarmacias = {
            loading: false,
            tieneConfigurada: false
          };
          sessionStorage.setItem('tieneConfiguradoFarmacias', false);
        }
      })
      .addCase(checkTieneFarmaciasConfiguradas.rejected, (state) => {
        state.tieneConfiguradoFarmacias = {
          loading: false,
          tieneConfigurada: false
        };
        sessionStorage.setItem('tieneConfiguradoFarmacias', false);
      })

  }
});

export default configuracionesSlice.reducer;