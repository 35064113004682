import React, {} from "react";
import { Grid } from "@mui/material";
import PivotGridVentasPorProducto from "../../components/Indicadores/Ventas/PivotGridVentasPorProducto/PivotGridVentasPorProducto";

const CuboVentas = (props) => {
  const { ejecucionDePagina } = props;

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <PivotGridVentasPorProducto ejecucionDePagina={ejecucionDePagina} />
      </Grid>
    </Grid>
  )
}

export default CuboVentas;