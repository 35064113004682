import React, { Fragment, useState, useCallback } from 'react';
import { makeStyles } from "@mui/styles";
import { FormControlLabel, FormGroup, Grid, IconButton, Switch, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { cambiarAjustaPorInflacion } from '../../../store/slices/configuracionesSlice';

import Dialog from '../../UI/Dialog/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import CustomButton from '../../UI/Button/Button';

import InflacionDesactivado from '../../../assets/images/InflacionDesactivado.png';
import InflacionActivado from '../../../assets/images/InflacionActivado.png';
import TablaInflacion from './TablaInflacion';
import LightTooltip from '../../UI/LightTooltip/LightTooltip';
import { storageMonedaId, storageMonedaSigno, storageAjustaInflacion } from '../../../assets/shared/sessionData';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    color: "white",
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'inline',
      color: 'white',
    }
  },
}));

const ButtonTomarEnCuentaInflacion = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ajustaInflacion = useSelector(state => state.auth.ajustaInflacion);
  const onCambiarAjustaPorInflacionClick = (ajustaInflacion) => dispatch(cambiarAjustaPorInflacion(ajustaInflacion));
  const { puedeModificarInflacion } = props;
  const [showModal, setShowModal] = useState(false);
  const [consideraInflacion, setConsideraInflacion] = useState(ajustaInflacion);

  const getAjustaInflacion = useCallback(() => {
    return ajustaInflacion === undefined ? storageAjustaInflacion() : ajustaInflacion;
  }, [ajustaInflacion]);

  const inflacionActivado = () => {
    return getAjustaInflacion() && (storageMonedaId() === 'PES' || storageMonedaSigno() === '$');
  }

  const onChangeInflacion = () => {
    setConsideraInflacion(!consideraInflacion);
  }
  const onGuardarSeleccionDeInflacion = () => {
    onCambiarAjustaPorInflacionClick(consideraInflacion);
    setShowModal(false);
  }

  const onCloseClick = () => {
    setShowModal(false);
  };

  const buttonIcon = () => {
    return (
      <div className={classes.root}
        onClick={() => !props.disabled && setShowModal(true)}
        disabled={props.disabled ? props.disabled : false}
      >
        <LightTooltip arrow
          title={
            <Typography className={classes.tooltip}>
              Tomar en cuenta inflación
            </Typography>
          }
          placement="bottom"
        >
          <IconButton size="small" >
            <img alt="" src={inflacionActivado() && puedeModificarInflacion ? InflacionActivado : InflacionDesactivado} />
          </IconButton>
        </LightTooltip>
        <Typography className={classes.iconMobile}>Tomar en cuenta inflación</Typography>
      </div>
    )
  }

  return (
    <Fragment>
      {buttonIcon()}
      {showModal && (<Dialog
        show={showModal}
        maxWidth={"sm"}
        title={
          <div style={{ width: '98%', textAlign: 'right' }}>
            <IconButton aria-label="close"
              onClick={() => onCloseClick()} >
              <CloseIcon />
            </IconButton>
          </div>}
        onClose={() => onCloseClick()}
        body={
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {puedeModificarInflacion ?
                <FormControlLabel
                  label='Tomar en cuenta inflación'
                  control={
                    <Switch
                      defaultChecked={inflacionActivado()}
                      onChange={() => onChangeInflacion()}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                /> :
                null
              }
            </Grid>
            <Grid item xs={12}>
              <TablaInflacion >
              </TablaInflacion>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              {puedeModificarInflacion ?
                <CustomButton
                  onClick={() => onGuardarSeleccionDeInflacion()}
                  style={{
                    minWidth: '100px',
                    minHeight: '30px',
                    borderRadius: '4px',
                    backgroundColor: "#00bcd4",
                    color: 'white',
                  }}
                >
                  Aceptar
                </CustomButton> :
                null
              }
            </Grid>
          </Grid>
        }
      >
      </Dialog>)}
    </Fragment>
  );
};

export default ButtonTomarEnCuentaInflacion;