import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { actualizarIndicadoresInicio, actualizarIndicadoresVentasPorProducto, actualizarIndicadoresVentasPorTipoPago } from '../../store/slices/actualizarPaginasSlice';
import { updateUltimaActualizacionInicio, updateUltimaActualizacionVentasPorProducto, updateUltimaActualizacionVentasPorTipoPago } from '../../store/slices/ultimaEjecucionSlice';
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import moment from "moment";
import { pathNamePages } from "../../assets/shared/pathnames";
import LightTooltip from "../UI/LightTooltip/LightTooltip";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto 10px",
    display: "flex",
    alignItems: "center",
    justifyContent: 'flex-start',
  },
  tooltip: {
    textAlign: "left",
    font: "normal normal normal 14px/19px Roboto",
    color: "8D8C8C",
  },
  icon: {
    fontSize: "1.8rem",
    cursor: "pointer",
    marginRight: "10px",
    paddingTop: 0,
    paddingBottom: 0
  },
  iconMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      color: 'white',
    }
  },
}));

const UltimaActualizacion = (props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const ultimaActualizacionInicio = useSelector(state => state.ultimaEjecucion.inicio);
  const ultimaActualizacionVentasPorProducto = useSelector(state => state.ultimaEjecucion.ventasPorProducto);
  const ultimaActualizacionVentasPorTipoPago = useSelector(state => state.ultimaEjecucion.ventasPorTipoPago);
  const ejecucionActualizacionInicio = useSelector(state => state.ejecucionPaginas.ejecucionInicio);
  const ejecucionActualizacionVentasPorProducto = useSelector(state => state.ejecucionPaginas.ejecucionVentasPorProducto);
  const ejecucionActualizacionVentasPorTipoPago = useSelector(state => state.ejecucionPaginas.ejecucionVentasPorTipoPago);

  const urlRef = global.window.location.pathname;
  const dateActual = () => {
    return moment(Date.now()).format("DD/MM/YYYY HH:mm");
  }

  const actualizarFechaHora = useCallback((page) => {
    switch (page) {
      case pathNamePages.home:
        return (ultimaActualizacionInicio === "" && dispatch(updateUltimaActualizacionInicio(dateActual())));
      case pathNamePages.inicio:
        return (ultimaActualizacionInicio === "" && dispatch(updateUltimaActualizacionInicio(dateActual())));
      case pathNamePages.ventasProducto:
        return (ultimaActualizacionVentasPorProducto === "" && dispatch(updateUltimaActualizacionVentasPorProducto(dateActual())));
      case pathNamePages.ventasTipoPago:
        return (ultimaActualizacionVentasPorTipoPago === "" && dispatch(updateUltimaActualizacionVentasPorTipoPago(dateActual())));

      default:
        break;
    }
  }, [ultimaActualizacionInicio, ultimaActualizacionVentasPorProducto, ultimaActualizacionVentasPorTipoPago, dispatch]);

  const infoUltimaActualizacion = () => {
    switch (urlRef) {
      case pathNamePages.home:
        return ultimaActualizacionInicio;
      case pathNamePages.inicio:
        return ultimaActualizacionInicio;
      case pathNamePages.ventasProducto:
        return ultimaActualizacionVentasPorProducto;
      case pathNamePages.ventasTipoPago:
        return ultimaActualizacionVentasPorTipoPago;

      default:
        return ultimaActualizacionInicio;
    }
  };

  const onActualizarIndicadoresClick = (e) => {
    e.preventDefault();

    switch (urlRef) {
      case pathNamePages.home:
        return onActualizarIndicadoresInicioSwitch();
      case pathNamePages.inicio:
        return onActualizarIndicadoresInicioSwitch();
      case pathNamePages.ventasProducto:
        return onActualizarIndicadoresVentasPorProductoSwitch();
      case pathNamePages.ventasTipoPago:
        return onActualizarIndicadoresVentasPorTipoPagoSwitch();

      default:
        return onActualizarIndicadoresInicioSwitch();
    }
  };

  const onActualizarIndicadoresInicioSwitch = useCallback(() => {
    if (ejecucionActualizacionInicio) {
      dispatch(updateUltimaActualizacionInicio(dateActual()));
      dispatch(actualizarIndicadoresInicio());
    }
  }, [ejecucionActualizacionInicio, dispatch]);

  const onActualizarIndicadoresVentasPorProductoSwitch = useCallback(() => {
    if (ejecucionActualizacionVentasPorProducto) {
      dispatch(updateUltimaActualizacionVentasPorProducto(dateActual()));
      dispatch(actualizarIndicadoresVentasPorProducto());
    }
  }, [ejecucionActualizacionVentasPorProducto, dispatch]);

  const onActualizarIndicadoresVentasPorTipoPagoSwitch = useCallback(() => {
    if (ejecucionActualizacionVentasPorTipoPago) {
      dispatch(updateUltimaActualizacionVentasPorTipoPago(dateActual()));
      dispatch(actualizarIndicadoresVentasPorTipoPago());
    }
  }, [ejecucionActualizacionVentasPorTipoPago, dispatch]);

  useEffect(() => {
    actualizarFechaHora(urlRef);
  }, [urlRef, actualizarFechaHora]);

  return (
    <div className={classes.root}
      onClick={(e) => onActualizarIndicadoresClick(e)}
      disabled={props.disabled ? props.disabled : false}
    >
      <LightTooltip
        arrow
        title={
          <Typography className={classes.tooltip}>
            Última actualización {infoUltimaActualizacion()}
          </Typography>
        }
      >
        <AccessTimeIcon
          className={classes.icon}
          style={{
            color: props.color,
            fontSize: props.iconSize,
          }}
        />
      </LightTooltip>
      <div className={classes.iconMobile}>
        <Typography>
          Última actualización:
        </Typography>
        <Typography>
          {infoUltimaActualizacion()}
        </Typography>
      </div>
    </div>
  );
};

export default UltimaActualizacion;