export const pivotGridFormat = (monedaSigno) => {
  return [
    {
      caption: '- Empresa',
      dataField: 'nombreEmpresa',
      name: 'nombreEmpresa',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Año',
      dataField: 'anio',
      name: 'anio',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Mes',
      dataField: 'mes',
      name: 'mes',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Fecha',
      dataField: 'fecha',
      dataType: "date",
      format: "dd/MM/yyyy",
      name: 'fecha',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Producto',
      dataField: 'nombreProducto',
      name: 'nombreProducto',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Laboratorio',
      dataField: 'nombreLaboratorio',
      name: 'nombreLaboratorio',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Forma',
      dataField: 'nombreForma',
      name: 'nombreForma',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Familia',
      dataField: 'nombreFamilia',
      name: 'nombreFamilia',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Monodroga',
      dataField: 'nombreMonodroga',
      name: 'nombreMonodroga',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Depto',
      dataField: 'nombreDepto',
      name: 'nombreDepto',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '| Unidades Totales',
      dataField: 'unidadesTotales',
      name: 'unidadesTotales',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: ',##0'
    },
    {
      caption: '| Neto Total',
      dataField: 'netoTotal',
      name: 'netoTotal',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
  ];
}