import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken } from "../../../assets/shared/sessionData";
import { baseUrlDashboard } from '../../../assets/shared/urlApps';
import moment from "moment";
import "moment/locale/es";
import axios from '../../../axios-dashboard';

moment.locale("es");

const initialState = {
  selectedTotalRows: [],
  configuracionFields: {},
  data: {
    loading: false,
    estaCargado: false,
    error: null,
    datos: null,
    config: null,
    anioInforme: null,
    filtros: {},
    actualizado: null,
  },
  filtros: {
    semanaDesde: null,
    semanaHasta: null,
    anioDesde: null,
    anioHasta: null,
    desde: null,
    hasta: null,
    anioComparacion: null,
    filtroAplicado: true,
  },
  filtrosChart: {
    optionSelectedId: 'anio',
    fieldSelectedId: 'unidades',
    orderSelectedId: 'totalUnidades',
    extraFilters: undefined,
    relatedFilters: undefined,
  },
  nombreFormato: "Último formato utilizado",
  nombreCubo: '',
  importacionFormato: {
    loading: false,
    estaCargado: false,
    error: null
  },
  importacionCubo: {
    loading: false,
    estaCargado: false,
    error: null
  }
};

export const loadVentasPorProducto = createAsyncThunk(
  'ventasPorProducto/loadVentasPorProducto',
  async ({ request, onSuccess = undefined, onFail = undefined, config = undefined }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios
      .post(`${baseUrlDashboard()}farmacias/GenerarCuboVentasPorProducto`, request, requestOptions);

    let filtro = {
      semanaDesde: request.semanaDesde,
      semanaHasta: request.semanaHasta,
      anioDesde: request.anioDesde,
      anioHasta: request.anioHasta,
      anioComparacion: request.anioComparacion,
      desde: request.desde,
      hasta: request.hasta,
      filtroAplicado: true
    };

    const result = {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      config,
      requestFiltro: filtro,
      request,
      onSuccess,
      onFail,
      actualizado: new Date()
    };
    return result;
  }
);

export const ventasPorProductoSlice = createSlice({
  name: 'ventasPorProducto',
  initialState,
  reducers: {
    updateSelectedTotalRows: {
      reducer: (state, action) => {
        state.selectedTotalRows = action.payload.rows ? action.payload.rows : [];
      },
      prepare: (rows) => {
        return {
          payload: { rows }
        }
      }
    },
    updateVentasPorProductoData: {
      reducer: (state, action) => {
        state.data.datos = action.payload.datos;
        state.data.config = action.payload.configuracion;
        state.data.filtros = action.payload.filtros;
      },
      prepare: (datos, configuracion, filtros) => {
        return {
          payload: { datos, configuracion, filtros }
        }
      }
    },
    clearVentasPorProducto: (state) => {
      state.selectedTotalRows = initialState.selectedTotalRows;
      state.configuracionFields = initialState.configuracionFields;
      state.data = initialState.data;
      state.filtros = initialState.filtros;
      state.filtrosChart = initialState.filtrosChart;
      state.nombreFormato = initialState.nombreFormato;
      state.nombreCubo = initialState.nombreCubo;
      state.importacionFormato = initialState.importacionFormato;
      state.importacionCubo = initialState.importacionCubo;
    },
    updateVentasPorProductoFiltros: {
      reducer: (state, action) => {
        state.filtros = action.payload;
      },
      prepare: (fechaDesde, fechaHasta, anioComparacion, filtroAplicado = true) => {
        return {
          payload: {
            semanaDesde: fechaDesde ? moment(fechaDesde).week() : 0,
            semanaHasta: fechaHasta ? moment(fechaHasta).week() : 0,
            anioDesde: fechaDesde ? fechaDesde.getFullYear() : new Date().getFullYear(),
            anioHasta: fechaHasta ? fechaHasta.getFullYear() : new Date().getFullYear(),
            desde: fechaDesde ? fechaDesde.toDateString() : null,
            hasta: fechaHasta ? fechaHasta.toDateString() : null,
            anioComparacion,
            filtroAplicado,
          }
        }
      }
    },
    updateVentasPorProductoFiltrosChart: {
      reducer: (state, action) => {
        state.filtrosChart = action.payload.filtro;
      },
      prepare: (filtro) => {
        return {
          payload: { filtro }
        }
      }
    },
    updateVentasPorProductoFormato: {
      reducer: (state, action) => {
        state.data.config = action.payload.config;
      },
      prepare: (config) => {
        return {
          payload: { config }
        }
      }
    },
    updateConfiguracionFields: {
      reducer: (state, action) => {
        state.configuracionFields = action.payload.config;
      },
      prepare: (config) => {
        return {
          payload: { config }
        }
      }
    },
    updateNombreFormato: {
      reducer: (state, action) => {
        state.nombreFormato = action.payload.nombre;
      },
      prepare: (nombre) => {
        return {
          payload: { nombre }
        }
      }
    },
    updateNombreCubo: {
      reducer: (state, action) => {
        state.nombreCubo = action.payload.nombre;
      },
      prepare: (nombre) => {
        return {
          payload: { nombre }
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVentasPorProducto.pending, (state) => {
        state.data = {
          loading: true,
          error: null,
          datos: null,
          config: null,
          estaCargado: false,
          anioInforme: null,
          filtros: {},
          actualizado: null
        };
      })
      .addCase(loadVentasPorProducto.fulfilled, (state, action) => {
        if (action.payload && action.payload.data) {
          let datos = action.payload.data.datos ?? [];
          let configuracion = action.payload.data.config ? JSON.parse(action.payload.data.config) : action.payload.config;
          let responseFiltros = action.payload.data.filtros;
          let requestFiltros = action.payload.requestFiltro;

          let filtros = {
            semanaDesde: responseFiltros && responseFiltros.semanaDesde ? responseFiltros.semanaDesde : requestFiltros.semanaDesde,
            semanaHasta: responseFiltros && responseFiltros.semanaHasta ? responseFiltros.semanaHasta : requestFiltros.semanaHasta,
            anioDesde: responseFiltros && responseFiltros.anioDesde ? responseFiltros.anioDesde : requestFiltros.anioDesde,
            anioHasta: responseFiltros && responseFiltros.anioHasta ? responseFiltros.anioHasta : requestFiltros.anioHasta,
            anioComparacion: responseFiltros && responseFiltros.anioComparacion ? responseFiltros.anioComparacion : requestFiltros.anioComparacion,
            desde: responseFiltros && responseFiltros.desde ? moment(responseFiltros.desde) : moment(requestFiltros.desde),
            hasta: responseFiltros && responseFiltros.hasta ? moment(responseFiltros.hasta) : moment(requestFiltros.hasta),
            filtroAplicado: true,
          };

          let selectedRows = action.payload.data.filasSubtotales ?? [];

          state.selectedTotalRows = selectedRows;
          state.data.loading = false;
          state.data.error = null;
          state.data.datos = datos;
          state.data.config = configuracion;
          state.data.estaCargado = true;
          state.data.actualizado = action.payload.data && action.payload.data.actualizado
            ? action.payload.data.actualizado : action.payload.actualizado;
          state.data.anioInforme = filtros ? filtros.anioFiscal : null;
          state.data.filtros = filtros;
          state.filtros = filtros;

          if (action.payload.data.nombreFormato && action.payload.data.nombreFormato !== '')
            state.nombreFormato = action.payload.data.nombreFormato;
          else if (configuracion && configuracion.nombre && configuracion.nombre !== '')
            state.nombreFormato = configuracion.nombre;

          if (action.payload.data.nombreCubo && action.payload.data.nombreCubo !== '')
            state.nombreCubo = action.payload.data.nombreCubo;

          if (action.payload.onSuccess)
            action.payload.onSuccess();
        }
      })
      .addCase(loadVentasPorProducto.rejected, (state, error) => {
        state.data.loading = false;
        state.data.error = error;
        state.data.estaCargado = true;
        state.data.actualizado = null;
      })
  }
});

export const { updateSelectedTotalRows, updateVentasPorProductoData, clearVentasPorProducto, updateVentasPorProductoFiltros,
  updateVentasPorProductoFormato, updateVentasPorProductoFiltrosChart, updateConfiguracionFields,
  updateNombreFormato, updateNombreCubo } = ventasPorProductoSlice.actions;
export default ventasPorProductoSlice.reducer;
export const selectedTotalRowsState = state => state.selectedTotalRows ? state.selectedTotalRows.items : [];
export const ventasPorProductoConfiguracion = state => state.ventasPorProducto.data.config;