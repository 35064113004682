export const storageFechaFija = () => {
  return sessionStorage.getItem('fechaFija');
};

export const storageToken = () => {
  return sessionStorage.getItem('token');
};

export const storageExpirationDate = () => {
  return sessionStorage.getItem('expirationDate');
};

export const storageMultiempresa = () => {
  return sessionStorage.getItem('multiempresa');
};

export const storageEmpresaId = () => {
  return parseInt(sessionStorage.getItem('empresaId'), 10);
};

export const storageSucursalId = () => {
  return parseInt(sessionStorage.getItem('sucursalId'), 10);
}

export const storageUserEmail = () => {
  return sessionStorage.getItem('userEmail');
};

export const storageMonedaId = () => {
  return sessionStorage.getItem('monedaId');
};

export const storageMonedaSigno = () => {
  return sessionStorage.getItem('monedaSigno');
};

export const buildBearerToken = () => {
  return `Bearer ${storageToken()}`;
};

export const storageClienteId = () => {
  return sessionStorage.getItem('idCliente');
}

export const storageProveedorId = () => {
  return sessionStorage.getItem('idProveedor');
}

export const storageEmpresaNombre = () => {
  return sessionStorage.getItem('empresaNombre');  
}

export const storageSeccionNombre = () => {
  return sessionStorage.getItem('seccionNombre');
}

export const getUserInfo = () => {
  return JSON.parse(sessionStorage.getItem('userInfo'));
};

export const storageUsuarioId = () => {
  return sessionStorage.getItem('usuarioId');
};

export const userGauss = () => {
  return sessionStorage.getItem("gauss") === "true";
}

export const userDemo = () => {
  return sessionStorage.getItem("demo") === "true";
}

//Reemplazar por el nombre de la app
export const appName = () => {
  return 'farmacias';
}

export const storageAjustaInflacion = () => {
  return sessionStorage.getItem('ajustaInflacion') === 'true';
}

export const tieneConfiguradoFarmacias = () => {
  return sessionStorage.getItem("tieneConfiguradoFarmacias") === "true";
}