import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel2.png';
import { exportVentasDesagregadasPorTipoDeVentas } from '../../../../store/slices/ventas/ventasDesagregadasPorProductoSlice';
import Dialog from '../../../UI/Dialog/Dialog';
import { Grid, Typography, IconButton, Tooltip, Divider } from '@mui/material';
import Spinner from '../../../UI/Spinner/Spinner';
import { CloseOutlined } from '@mui/icons-material';
import { downloadExcel } from "react-export-table-to-excel";
import { formatNumber, formatNumberWithoutDecimals } from '../../../../assets/shared/formatNumber';
import { storageMonedaSigno } from '../../../../assets/shared/sessionData';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';

const TablaExcelVentasMontoUnidadPorSeleccion = (props) => {
  const { filename, title, name, disabled } = props;
  const [openModal, setOpenModal] = useState(false);

  //#region Dispatches
  const dispatch = useDispatch();

  const dataSelectedId = useSelector(state => state.ventasDesagregadasPorProducto.tipoVentasDesagregadasSelected);
  const dataSelectOptions = useSelector(state => state.ventasDesagregadas.tipoVentasDesagregadas.tipos);
  const filtroSeleccionado = useSelector(state => state.ventasDesagregadasPorProducto.filtros);

  const loading = useSelector(state => state.ventasDesagregadasPorProducto.exportVentasDesagregadas.loading);
  const estaCargado = useSelector(state => state.ventasDesagregadasPorProducto.exportVentasDesagregadas.estaCargado);
  const ventas = useSelector(state => state.ventasDesagregadasPorProducto.exportVentasDesagregadas.ventas);
  //#endregion

  //#region Export to Excel  

  const borderStyle = {
    top: { style: 'thin', color: { argb: 'FF7E7E7E' } },
    left: { style: 'thin', color: { argb: 'FF7E7E7E' } },
    bottom: { style: 'thin', color: { argb: 'FF7E7E7E' } },
    right: { style: 'thin', color: { argb: 'FF7E7E7E' } }
  }

  const headersStyle = {
    font: { sz: '12', bold: true },
    fill: { fgColor: { rgb: 'FFD3D3D3' } },
    alignment: { horizontal: 'center', vertical: 'center' },
    border: borderStyle
  };

  const cellsStyle = {
    font: { sz: '10', bold: false },
    alignment: { horizontal: 'left' },
    border: borderStyle
  };

  const cellsStyleVerifNegative = (value, isTotal) => {
    return {
      font: { sz: '10', bold: false, color: { rgb: value < 0 && 'FFFF0000' } },
      alignment: { horizontal: 'right' },
      fill: isTotal && { fgColor: { rgb: 'FFD3D3D3' } },
      border: borderStyle
    };
  };

  const excelColumns = () => {
    return [
      {
        header: 'Id',
        width: { wch: 60 },
        style: headersStyle,
        key: 'id'
      },
      {
        header: 'Producto',
        width: { wch: 60 },
        style: {
          font: { size: '12', bold: true },
          fill: { fgColor: { rgb: 'FFD3D3D3' } }
        },
        key: 'nombre'
      },
      {
        header: 'Laboratorio',
        width: { wpx: 150 },
        style: headersStyle,
        key: 'laboratorio'
      },
      {
        header: 'Forma',
        width: { wpx: 150 },
        style: headersStyle,
        key: 'forma'
      },
      {
        header: 'Familia',
        width: { wpx: 150 },
        style: headersStyle,
        key: 'familia'
      },
      {
        header: 'Monodroga',
        width: { wpx: 150 },
        style: headersStyle,
        key: 'monodroga'
      },
      {
        header: 'Departamento',
        width: { wpx: 150 },
        style: headersStyle,
        key: 'depto'
      },
      {
        header: `Porc. participación (Monto)`,
        width: { wpx: 150 },
        style: headersStyle,
        key: 'porcentajeDeParticipacion'
      },
      {
        header: `Monto`,
        width: { wpx: 150 },
        style: headersStyle,
        key: 'monto'
      },
      {
        header: `Porc. participación (Unid.)`,
        width: { wpx: 150 },
        style: headersStyle,
        key: 'porcentajeDeParticipacionUnidades'
      },
      {
        header: `Unidades`,
        width: { wpx: 150 },
        style: headersStyle,
        key: 'unidadesVendidas'
      },
      {
        header: `Precio promedio`,
        width: { wpx: 150 },
        style: headersStyle,
        key: 'precioPromedio'
      }
    ]
  }

  const formatData = (ventas) => {
    var formatData = [];

    ventas && ventas.map((venta) => {
      return formatData.push([
        {
          value: venta.id,
          style: { font: { sz: '10', bold: false }, }
        },
        {
          value: venta.nombre,
          style: { font: { sz: '10', bold: false }, }
        },
        {
          value: venta.laboratorio,
          style: cellsStyle
        },
        {
          value: venta.forma,
          style: cellsStyle
        },
        {
          value: venta.familia,
          style: cellsStyle
        },
        {
          value: venta.monodroga,
          style: cellsStyle
        },
        {
          value: venta.depto,
          style: cellsStyle
        },
        {
          value: `${formatNumber(venta.porcentajeDeParticipacion)} %`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacion, false)
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(venta.monto)}`,
          style: cellsStyleVerifNegative(venta.monto, false)
        },
        {
          value: `${formatNumber(venta.porcentajeDeParticipacionUnidades)} %`,
          style: cellsStyleVerifNegative(venta.porcentajeDeParticipacionUnidades, false)
        },
        {
          value: `${formatNumberWithoutDecimals(venta.unidadesVendidas)}`,
          style: cellsStyleVerifNegative(venta.unidadesVendidas, false)
        },
        {
          value: `${storageMonedaSigno()} ${formatNumber(venta.precioPromedio)}`,
          style: cellsStyleVerifNegative(venta.precioPromedio, false)
        }
      ]);
    });

    return formatData;
  };
  //#endregion

  const onClose = () => {
    setOpenModal(false);
  }

  const onExporting = (e) => {
    setOpenModal(true);

    if (!estaCargado) {
      if (filtroSeleccionado && filtroSeleccionado.aplicado) {
        const item = dataSelectOptions && dataSelectOptions.find((option) => option.id === dataSelectedId);

        if (item && item.subNivel) {
          dispatch(exportVentasDesagregadasPorTipoDeVentas({
            tipoParent: dataSelectedId,
            tipoChild: item.subNivel.id,
            fechaDesde: new Date(filtroSeleccionado.desde),
            fechaHasta: new Date(filtroSeleccionado.hasta)
          }));
        }
      }
    }
  }

  useEffect(() => {
    if (openModal && !loading && estaCargado) {
      const columns = excelColumns();

      const header = columns
        ? columns.map((column) => { return column.header.toUpperCase(); })
        : [];

      const body = formatData(ventas).map((row) => row.map((r) => r.value));

      downloadExcel({
        fileName: filename,
        sheet: name,
        tablePayload: {
          header,
          body,
        },
      });

      setTimeout(() => setOpenModal(false), 1000);
    }
  }, [openModal, loading, estaCargado, ventas, excelColumns, filename, formatData, name]);

  /*useEffect(() => {
    if (openModal && !loading && estaCargado && ventas && ventas.length > 0) {
      const workbook = new Workbook();
      workbook.created = new Date();
      workbook.title = title;
      const worksheet = workbook.addWorksheet("sheet");
      const columns = excelColumns();
      worksheet.columns = columns;

      ventas && ventas.forEach((venta) => {
        let row = worksheet.addRow(venta);

        row.eachCell({ includeEmpty: true }, function (cell, colNumber) {
          console.log('Cell ' + colNumber + ' = ' + cell.value);
          cell.style = cellsStyleVerifNegative(cell.value, false);
        });
      });

      console.log('excel');

      workbook.xlsx.writeBuffer()
        .then(function (buffer) {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${filename}.xlsx`);
        })
        .then(() => {
          setOpenModal(false);
        });
    }
  }, [openModal, loading, estaCargado, ventas]);
  */

  return (
    <Fragment>
      <Tooltip title={title ?? "Descargar en Excel"}>
        <IconButton disabled={disabled} onClick={(e) => onExporting(e)} style={{ marginRight: '10px' }} size="small">
          <img alt="" src={IconConvertToExcel} height={'35px'} />
        </IconButton>
      </Tooltip>
      {openModal && (
        <Dialog
          fullWidth
          maxWidth={"sm"}
          show={openModal}
          title={
            <Grid container direction={"column"}>
              <Grid item xs={12} container spacing={1} direction={"row"} justifyContent={"space-between"}>
                <Grid item xs={10} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography variant={"h5"}>
                    Exportar a Excel
                  </Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <IconButton aria-label="close" onClick={() => onClose()}>
                    <CloseOutlined />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          }
          body={
            <Grid container spacing={2} direction={"column"} justifyContent={"center"} alignContent={"center"}>
              <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center'}}>
                <Spinner></Spinner>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"h6"}>
                  Exportando. Por favor, espere...
                </Typography>
              </Grid>
            </Grid>

          }
          onClose={() => onClose()}
        />
      )}
    </Fragment>
  )
}

export default TablaExcelVentasMontoUnidadPorSeleccion;