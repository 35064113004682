import React, { Suspense, useEffect, useCallback, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { authCheckState, login } from "./store/slices/authSlice";
import './App.module.css';
import SimpleBackdrop from "./components/UI/Backdrop/Backdrop";
import IframePortal from "./containers/Auth/IframePortal/IframePortal";
import Spinner from "./components/UI/Spinner/Spinner";
import { storageToken, getUserInfo, appName, userGauss, userDemo, tieneConfiguradoFarmacias } from "./assets/shared/sessionData";
import Layout from './containers/Layout/Layout';
import PantallaCarga from './containers/PantallaCarga/PantallaCarga';
import Login from './containers/Auth/Login/Login';
import Logout from './containers/Auth/Logout/Logout';
import Inicio from './containers/Inicio/Inicio';
import VentasPorProducto from './containers/VentasPorProducto/VentasPorProducto';
import { cambioPaginaRender } from "./store/slices/ejecucionPaginasSlice";
import { checkTieneFarmaciasConfiguradas } from "./store/slices/configuracionesSlice";
import SinConfigurar from "./containers/SinConfigurar/SinConfigurar";

const App = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const token = useSelector(state => state.auth.token);
  const loadingAuth = useSelector(state => state.auth.loading);
  const loadingCambiarEmpresa = useSelector(state => state.multiempresa.loadingCambiarEmpresa);
  const loadingCambiarMoneda = useSelector(state => state.monedas.loadingCambiarMoneda);
  const loadingExecuteCambiarAjustaPorInflacion = useSelector(state => state.configuraciones.executeCambiarAjustaPorInflacion);
  const loadingCambiarSucursal = useSelector(state => state.sucursales.loadingCambiarSucursal);
  const userInfoState = useSelector(state => state.auth.userInfo);
  const [userInfo, setUserInfo] = useState();
  const [secciones, setSecciones] = useState([]);

  useEffect(() => {
    if (storageToken())
      dispatch(authCheckState());
  }, [dispatch]);

  useEffect(() => {
    if (storageToken())
      dispatch(checkTieneFarmaciasConfiguradas());
  }, [dispatch]);

  useEffect(() => {
    if (!storageToken()) {
      const token = search.slice(1);
      dispatch(login(token));
    }
  }, [dispatch, search]);

  const obtenerSecciones = useCallback((userInfo) => {
    const items = [];
    if (userInfo && userInfo.perfil && userInfo.perfil.perfilAcceso) {
      const perfilesAccesos = userInfo.perfil.perfilAcceso;
      const filtrados = perfilesAccesos.filter((pa) => pa.aplicacionKey === appName());

      if (filtrados)
        filtrados.forEach((fil) => items.push(fil));
    }
    setSecciones(items);
  }, []);

  useEffect(() => {
    if (userInfo === null || userInfo === undefined || userInfo.id === null || userInfo.id === undefined) {
      const item = userInfoState && userInfoState.id
        ? userInfoState
        : getUserInfo();
      setUserInfo(item);
      obtenerSecciones(item);
    }
  }, [userInfoState, userInfo, setUserInfo, obtenerSecciones]);

  const tieneAcceso = (key) => {
    const seccion = secciones.find((sec) => sec.key === key);
    return userGauss() || userDemo() || (seccion !== null && seccion !== undefined);
  };

  const getDefaultPath = useCallback(() => {
    const seccion = secciones[0];

    if (!seccion)
      return '/ventasProducto';

    switch (seccion.key) {
      case 'inicio':
        return '/inicio';

      case 'ventasProducto':
        return '/ventasProducto';

      case 'ventasTipoPago':
        return '/ventasTipoPago';

      default:
        return;
    }
  }, [secciones]);

  useEffect(() => {
    dispatch(cambioPaginaRender(getDefaultPath()))
  }, [dispatch, getDefaultPath]);


  let routes = (
    <Routes>
      <Route path="/" element={<PantallaCarga />} />
    </Routes>
  );

  if (token !== null && !loadingAuth) {
    routes = (
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/logout" element={<Logout />} />
        {tieneConfiguradoFarmacias()
          ? tieneAcceso('inicio') && <Route index path="/inicio" element={<Inicio />} />
          : <Route index path="/inicio" element={<SinConfigurar />} />
        }
        {tieneConfiguradoFarmacias()
          ? tieneAcceso('ventasProducto') && <Route index path="/ventasProducto" element={<VentasPorProducto />} />
          : <Route index path="/ventasProducto" element={<SinConfigurar />} />
        }
        <Route path="/" element={token ? <Navigate to={getDefaultPath()} /> : <Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <Layout>
          <SimpleBackdrop
            open={
              loadingCambiarEmpresa ||
              loadingCambiarMoneda ||
              loadingExecuteCambiarAjustaPorInflacion ||
              loadingCambiarSucursal
            }
          />
          {routes}
        </Layout>
        <IframePortal />
      </Suspense>
    </div>
  );

}

export default App;