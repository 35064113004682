import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadInflacionPorAnio } from '../../../store/slices/configuracionesSlice';

import { formatNumber } from '../../../assets/shared/formatNumber';
import Table from '../../UI/Table/Table';
import classes from './TablaInflacion.module.css';
import TablaInflacionMesAMes from './TablaInflacionMesAMes';

const TablaInflacion = (props) => {
  const dispatch = useDispatch();
  const inflacionLocal = useSelector(state => state.configuraciones.loadInflacionPorAnio.items);
  const loading = useSelector(state => state.configuraciones.loadInflacionPorAnio.loading);
  const estaCargado = useSelector(state => state.configuraciones.loadInflacionPorAnio.estaCargado);
  const onLoadInflacionPorAnio = () => dispatch(loadInflacionPorAnio());

  useEffect(() => {
    if (inflacionLocal.length === 0) {
      if (!estaCargado && !loading)
        onLoadInflacionPorAnio();
    }
  }, [inflacionLocal, estaCargado, loading, onLoadInflacionPorAnio]);

  const columns = [
    {
      title: 'Año',
      field: 'anio',
      align: 'right',
      cellStyle: {
        fontSize: '12px',
        paddingBottom: '5px',
        paddingTop: '5px'
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220 220 220)'
      },
      defaultSort: 'desc'
    },
    {
      title: `Porcentaje`,
      field: 'inflacionAcumulada',
      align: 'right',
      type: 'numeric',
      cellStyle: (cellData) => {
        return {
          fontSize: '12px',
          paddingBottom: '5px',
          paddingTop: '5px'
        };
      },
      headerStyle: {
        fontSize: '14px',
        paddingTop: '0px',
        paddingBottom: '0px',
        backgroundColor: 'rgb(220 220 220)'
      },
      render: (rowData) => `${formatNumber(rowData.inflacionAcumulada)}%`
    }
  ];

  const data = () => {
    return inflacionLocal.map((inflacion) => {
      return {
        anio: inflacion.anio,
        inflacionAcumulada: inflacion.inflacionAcumulada,
        inflacion: inflacion.inflacion
      };
    });
  };

  const autoScroll = (rowData) => {
    if (tableRef.current) {
      const indexRow = tableRef.current.state.data.indexOf(tableRef.current.state.originalData[rowData.tableData.id]);

      tableRef.current.tableContainerDiv.current.scrollTop = 49 * indexRow;
    }
  };

  const tableRef = useRef();
  return (
    <div className={classes.tablaInflacion}>
      <Table
        tableRef={tableRef}
        style={{ width: '95%', marginTop: 0, marginBottom: '15px' }}
        title=""
        noBoxShadow
        columns={columns}
        data={data()}
        detailPanel={(rowData) => {
          setTimeout(() => {
            autoScroll(rowData);
          }, 100);
          return <TablaInflacionMesAMes inflacion={rowData.inflacion} />;
        }}
        customOptions={{
          thirdSortClick: false,
          sorting: true,
          grouping: false,
          draggable: false,
          search: false,
          paging: false,
          toolbar: false,
          showTitle: false,
          maxBodyHeight: 450
        }}
        tableKey={"tabla-inflacion"}
      />
    </div>
  );
};

export default TablaInflacion;