import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from "@mui/styles";
import { Tabs, Tab } from '@mui/material';
import AdministrarFormato from './AdministrarFormato';
import AdministrarCubo from './AdministrarCubo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 600,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabPanel: {
    width: '750px',
    height: '200px',
    paddingInline: '25px'
  },
  vertical: {
    width: '240px',
    "& .MuiButtonBase-root": {
      fontSize: "11px",
      maxWidth: '140px',
      maxHeight: '30px',
      padding: '6px',
      alignItems: 'flex-start',
    },
    "& .MuiTab-wrapper": {
      alignItems: 'start'
    }
  },
  labelIcon: {
    textAlign: 'center',
  }
}));

function TabPanel(props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.tabPanel}
      {...other}
    >
      {value === index && (
        <Fragment>
          {children}
        </Fragment>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const AdminPivotGrid = (props) => {
  const classes = useStyles();
  const { title, pivotGridKey, pivotGridRef, onClose, onCargarFormato, onCargarFormatoCubo } = props;
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label={title}
        className={classes.tabs}
        classes={{
          vertical: classes.vertical
        }}
      >
        <Tab label="Admin. formatos" {...a11yProps(0)} />
        <Tab label="Admin. cubos" {...a11yProps(1)} classes={{
          labelIcon: classes.labelIcon
        }} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <AdministrarFormato
          pivotGridKey={pivotGridKey}
          onClose={onClose}
          pivotGridRef={pivotGridRef}
          onCargarFormato={onCargarFormato}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <AdministrarCubo
          pivotGridKey={pivotGridKey}
          onClose={onClose}
          pivotGridRef={pivotGridRef}
          datos={props.datos}
          filtros={props.filtros}
          onLoadData={props.onLoadDataImport}
          onUpdateDataSource={props.onUpdateDataSource}
          onCargarFormato={onCargarFormatoCubo}
        />
      </TabPanel>
    </div>
  );
}

export default AdminPivotGrid;