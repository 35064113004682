import React from 'react';
import PivotGrid, { FieldChooser, FieldPanel, StateStoring, Export, HeaderFilter, Scrolling }
  from 'devextreme-react/pivot-grid';
import esMessages from "devextreme/localization/messages/es.json";
import { locale, loadMessages } from "devextreme/localization";

const TablePivotGrid = (props) => {
  const { storageKey, height, width, onCellClick, pivotGridRef, dataSource, configFields, onCellPrepared } = props;
  const { showColumnTotals, showColumnGrandTotals, showRowTotals, showRowGrandTotals } = props;

  loadMessages(esMessages);
  locale('es');

  const setSummaryType = (args, sourceField) => {
    dataSource.field(sourceField.index, {
      summaryType: args.itemData.value
    });

    dataSource.load();
  };

  const onContextMenuPreparing = (e) => {
    const sourceField = e.field;

    if (sourceField) {
      if (!sourceField.groupName || sourceField.groupIndex === 0) {
        e.items.push({
          text: 'Ocultar campo',
          onItemClick() {
            let fieldIndex;
            if (sourceField.groupName) {
              fieldIndex = dataSource
                .getAreaFields(sourceField.area, true)[sourceField.areaIndex]
                .index;
            } else {
              fieldIndex = sourceField.index;
            }

            dataSource.field(fieldIndex, {
              area: null,
            });
            dataSource.load();
          },
        });
      }

      if (sourceField.dataType === 'number') {
        const menuItems = [];

        e.items.push({ text: 'Tipo de cálculo', items: menuItems });
        ['Sum', 'Avg', 'Min', 'Max'].forEach((summaryType) => {
          const summaryTypeValue = summaryType.toLowerCase();

          menuItems.push({
            text: summaryType,
            value: summaryType.toLowerCase(),
            onItemClick(args) {
              setSummaryType(args, sourceField);
            },
            selected: e.field.summaryType === summaryTypeValue,
          });
        });
      }
    }
  };

  return (
    <PivotGrid
      ref={pivotGridRef}
      height={height}
      width={width}
      dataSource={dataSource}
      retrieveFields={false}
      allowSortingBySummary={true}
      allowSorting={true}
      allowFiltering={true}
      allowExpandAll={true}
      showBorders={true}
      showColumnTotals={showColumnTotals}
      showColumnGrandTotals={showColumnGrandTotals}
      showRowTotals={showRowTotals}
      showRowGrandTotals={showRowGrandTotals}
      wordWrapEnabled={true}
      onContextMenuPreparing={(e) => onContextMenuPreparing(e)}
      onCellClick={(e) => {
        if (onCellClick) {
          onCellClick(e);
        }
      }}
      onCellPrepared={onCellPrepared}
      onContentReady={(e) => props.onContentReady(e)}
      hideEmptySummaryCells={true}
      loadPanel={{
        enabled: true,
        height: 150,
        width: 300,
        shading: true,
        text: "Cargando..."
      }}
    >
      <StateStoring enabled={true} type="localStorage" storageKey={storageKey} />
      <FieldPanel
        visible={configFields && configFields.visible}
        showRowFields={configFields && configFields.showRows}
        showFilterFields={configFields && configFields.showFilter}
        showDataFields={configFields && configFields.showData}
        showColumnFields={false}
      />
      <FieldChooser
        enabled={true}
        allowSearch={true}
        applyChangesMode='onDemand'
        layout={1}
      />
      <Export enabled={false} />
      <HeaderFilter
        allowSearch={true}
        showRelevantValues={true}
        width={300}
        height={400}
      />
      <Scrolling mode={props.scrollingMode ? props.scrollingMode : "standard"} />
    </PivotGrid>
  )
}

export default TablePivotGrid;