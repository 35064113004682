import React, { Fragment, useCallback, useState, useEffect } from "react";
import { makeStyles } from '@mui/styles';
import {
  Checkbox, FormControl, InputLabel, ListItemText,
  MenuItem, Select, TextField, Input, Tooltip, FormHelperText,
  ListItemIcon
} from "@mui/material";
import Spinner from "../Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  selector: {
    width: '100%',
    textAlign: 'start',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  options: {
    fontSize: 'small',
  },
  formControl: {
    width: '100%',
    marginTop: '4px',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
  formControlMedium: {
    width: '100%',
    marginTop: '4px',
    fontSize: 'small',
    "& .MuiInputBase-input": {
      fontSize: "small",
    },
    "& .MuiInputLabel-root": {
      fontSize: "medium",
    }
  },
  menuItem: {
    fontSize: 'small',
    maxHeight: '35px',
  },
  listItem: {
    '& .MuiTypography-root': {
      fontSize: 'small',
    }
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const CustomSelector = (props) => {
  const classes = useStyles();
  const { id, label, labelSize, value, items, onChange, seleccione, isLoading,
    disabled, required, error, helperText, multiple, upperCase,
    toolTip, selectAll } = props;

  const idSelect = `${id}Select`;
  const idLabel = `${id}Label`;
  const [selectedValues, setSelectedValues] = useState(value);
  const [cargarAllValues, setCargarAllValues] = useState(true);
  const isAllSelected = items && selectedValues && items.length > 0 && selectedValues.length === items.length;
  
  const onSelectMultipleChange = useCallback((event) => {
    const selectValue = event.target.value;
    if (selectAll && selectValue[selectValue.length - 1] === "all") {
      let val = selectedValues.length === items.length ? [] : items;
      setSelectedValues(val);

      setTimeout(() => onChange(val), 500);
    } else {
      let tempValue = [];
      const copyValue = [...selectValue];

      selectValue.forEach(element => {
        let occurrences = copyValue.filter((copy) => copy.id === element.id);

        if (!occurrences || occurrences.length === 1)
          tempValue.push(element);
      });

      setSelectedValues(tempValue);
      setTimeout(() => onChange(tempValue), 500);
    }
  }, [selectAll, selectedValues, items, onChange]);

  const renderMultipleSelect = () => {
    let selectLabel = required ? `${label} *` : label;
    return (
      <FormControl variant="standard" className={
        labelSize === 'medium'
          ? classes.formControlMedium
          : classes.formControl}>
        <InputLabel id={idLabel} className={error ? "Mui-error" : undefined}>{selectLabel}</InputLabel>
        <Select
          labelId={idLabel}
          id={idSelect}
          multiple
          value={value}
          onChange={onSelectMultipleChange}
          error={error}
          input={<Input className={classes.options} style={{ color: "black", width: "100%" }} />}
          renderValue={(selected) => {
            let nombres = selected.map((item) => item.nombre);
            return nombres && nombres.join(', ');
          }}
          disabled={disabled}
          required={required}
          className={classes.selector}
          defaultValue=""
        >
          <MenuItem
            value="all"
            classes={{ root: isAllSelected ? classes.selectedAll : "" }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={selectedValues.length > 0 && selectedValues.length < items.length}
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary="Seleccionar Todos"
            />
          </MenuItem>
          {items && items.map((item, index) => (
            <MenuItem key={index} value={item} className={classes.menuItem}>
              <Checkbox checked={selectedValues.findIndex(val => val.id === item.id) >= 0} />
              <ListItemText primary={item.nombre} className={classes.listItem} />
            </MenuItem>
          ))}
        </Select>
        {required && helperText && error && (
          <FormHelperText className="Mui-error">{helperText}</FormHelperText>
        )}
      </FormControl>
    )
  }  

  const renderMultipleSelectTooltip = () => {
    return (
      <Tooltip placement='top' title={toolTip}>
        {renderMultipleSelect()}
      </Tooltip>)
  }

  const renderCustomSelect = () => {
    return (
      <TextField
        id={idSelect}
        select
        label={label}
        value={value}
        onChange={onChange}
        error={error}
        helperText={helperText}
        placeholder={label}
        style={{ color: "black", width: "100%" }}
        disabled={disabled}
        required={required}
        className={classes.selector}
        defaultValue=""
        variant="standard"
      >
        {seleccione &&
          <MenuItem
            className={classes.options}
            key="select"
            value="select">
            {upperCase ? 'SELECCIONE...' : 'Seleccione...'}
          </MenuItem>
        }
        {items && items.map((item, index) => (
          <MenuItem className={classes.options} key={index} value={item}>
            {item.nombre && (upperCase ? item.nombre.trimEnd().toUpperCase() : item.nombre.trimEnd())}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  useEffect(() => {
    if(selectAll && cargarAllValues && selectedValues.length <= 0 && value && value.length > 0){
      setSelectedValues(value);
      setCargarAllValues(false);
    }
      
  }, [selectAll, selectedValues, value, cargarAllValues]);

  return isLoading ? (
    <Spinner size="small" />
  ) : (
    <Fragment>
      {multiple 
        ? toolTip ? renderMultipleSelectTooltip() : renderMultipleSelect() 
        : renderCustomSelect()}
    </Fragment>
  );
}

export default CustomSelector;