import React, { useCallback, useState } from "react";
import { makeStyles, ThemeProvider } from '@mui/styles';
import moment from "moment";
import "moment/locale/es";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme, TextField } from "@mui/material";
import { Clear, Event } from "@mui/icons-material";
import { lightBlue } from "@mui/material/colors";

const useStyles = makeStyles(() => ({
  formControl: {
    fontSize: 'small',
    "& input": {
      fontSize: "small",
      textTransform: 'uppercase'
    },
    "& .MuiInputLabel-root": {
      fontSize: "small",
    }
  },
}));

const materialTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: lightBlue["400"],
      },
      current: {
        color: lightBlue["900"],
      },
    },
  },
});

const FiltroFechaSelector = (props) => {
  const classes = useStyles();
  const { label, disabled, value, onDateChange, minDate, maxDate, format, views, view, required, error, helperText, displayWeekNumber } = props;
  const min = minDate ? new moment(minDate) : new moment(new Date(new Date().getFullYear(), 0, 1));
  const max = maxDate ? new moment(new Date(maxDate)) : new moment(new Date(new Date().getFullYear(), 11, 31));
  const [selectedValue, setSelectedValue] = useState(value);
  const [isOpen, setIsOpen] = useState(false);

  const onChange = useCallback((value) => {
    setSelectedValue(value);
    let newDate = null;

    if (value && value._d) {
      const date = new Date(value._d);
      newDate = new Date(date.setHours(0, 0, 0, 0));
    }

    onDateChange(newDate);
  }, [onDateChange]);

  const onClearClick = (e) => {
    e.stopPropagation();
    setSelectedValue(null);
    onDateChange(null);
  }

  return (
    <ThemeProvider theme={materialTheme}>
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={"es"}
      >
        <DatePicker
          autoOk
          displayWeekNumber={displayWeekNumber}
          showToolbar={false}
          minDate={min ? min : null}
          maxDate={max ? max : null}
          onChange={(date) => onChange(date)}
          openTo={view ? view : "day"}          
          view={view}
          views={views}
          inputFormat={format}
          value={selectedValue ? moment(selectedValue.toISOString()) : null}
          renderInput={(params) =>
            <TextField
              {...params}
              variant={'standard'}
              label={label}
              className={classes.formControl}
            />
          }
          orientation='landscape'
          toolbarTitle={selectedValue && moment(selectedValue.toISOString()).year()}
          componentsProps={{
            actionBar: { actions: [] },
          }}
          disabled={disabled}
          maxDateMessage="La fecha no debe ser posterior a la fecha máxima"
          minDateMessage="La fecha no debe ser anterior a la fecha mínima"
          InputProps={{
            style: { fontSize: 'small' },
            className: classes.formControl,
            endAdornment: (selectedValue
              ? (<div onClick={(e) => onClearClick(e)} style={{ marginRight: 20, cursor: "pointer" }}>
                <Clear />
              </div>) : (
                <div onClick={() => setIsOpen(true)} style={{ marginRight: 20, cursor: "pointer" }}>
                  <Event />
                </div>
              )
            )
          }}
          InputLabelProps={{ style: { fontSize: 'small' } }}
          style={{ marginTop: '4px' }}
          open={isOpen}
          onOpen={() => setIsOpen(true)}
          onClose={() => setIsOpen(false)}
          autoComplete={'false'}
          required={required}
          error={error}
          helperText={helperText}
        />
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default FiltroFechaSelector;