import React from "react";
import { useSelector } from 'react-redux';
import Tabs from '../../components/UI/Tabs/Tabs';
import ConteinerWithEjecutarPagina from "../../components/UI/ConteinerWithEjecutarPagina/ConteinerWithEjecutarPagina";
import PivotGridVentasPorProducto from "../../components/Indicadores/Ventas/PivotGridVentasPorProducto/PivotGridVentasPorProducto";
import PivotGridVentasPorTipoPago from "../../components/Indicadores/Ventas/PivotGridVentasPorTipoPago/PivotGridVentasPorTipoPago";

const Inicio = () => {
  sessionStorage.setItem('seccionNombre', 'Inicio');
  const ejecucionDePagina = useSelector(state => state.ejecucionPaginas.ejecucionInicio);

  return (
    <ConteinerWithEjecutarPagina
      showMultiempresa
      isExecuted={!ejecucionDePagina}
    >
      <div>
        <Tabs
          tabs={['Ventas Por Producto', 'Ventas Por Tipo de Pago']}
          views={[
            <PivotGridVentasPorProducto ejecucionDePagina={ejecucionDePagina} />,
            <PivotGridVentasPorTipoPago ejecucionDePagina={ejecucionDePagina} />
          ]}
        />
      </div>
    </ConteinerWithEjecutarPagina>
  );
};

export default Inicio;