import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageToken, storageEmpresaId } from "../../assets/shared/sessionData";
import { baseUrlDashboard } from '../../assets/shared/urlApps';
import axios from '../../axios-dashboard';

const initialState = {
  comprobantesVentas: {
    loading: false,
    comprobantes: [],
    error: null,
    estaCargado: false
  },
}

export const loadComprobantesVentas = createAsyncThunk(
  'comprobantes/loadComprobantesVentas',
  async ({ idEmpresa = storageEmpresaId() }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };

    const response = await axios.get(`${baseUrlDashboard()}Ventas/ComprobantesVentas?idEmpresa=${idEmpresa}`, requestOptions);

    return response;
  }
);

export const comprobantesSlice = createSlice({
  name: 'comprobantes',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadComprobantesVentas.pending, (state) => {
        state.comprobantesVentas = {
          loading: true,
          comprobantes: [],
          error: null,
          estaCargado: false
        }
      })
      .addCase(loadComprobantesVentas.fulfilled, (state, action) => {
        state.comprobantesVentas = {
          loading: false,
          comprobantes: action.payload.data,
          error: null,
          estaCargado: false
        }
      })
      .addCase(loadComprobantesVentas.rejected, (state, action) => {
        state.comprobantesVentas = {
          loading: false,
          comprobantes: [],
          error: action.error.error,
          estaCargado: true
        }
      })
  }
});

export default comprobantesSlice.reducer;