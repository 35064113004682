import React from 'react';
import { IconButton } from '@mui/material';
import IconSendExcelToEmail from '../../../../assets/images/IconSendExcelToEmail.jpg';
import { showSendAttachmentModal } from '../../../../store/slices/modalsSlice';
import { useDispatch } from 'react-redux';

const SendExcelToEmailIcon = (props) => {
  const { pivotGridRef, title, fileName, disabled } = props;
  const dispatch = useDispatch();
  
  const onSendFileClick = () => {
    dispatch(showSendAttachmentModal(true, pivotGridRef, title, fileName));
  }

  return (
    <span title={disabled ? "Enviar Excel por email (no hay datos)" : "Enviar Excel por email"}>
      <IconButton disabled={disabled} onClick={(e) => onSendFileClick()} style={{ marginRight: '10px' }} size="small">
        <img alt="" src={IconSendExcelToEmail} height={'30px'} />
      </IconButton>
    </span>
  )
}

export default SendExcelToEmailIcon;