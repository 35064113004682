import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEmpresas, seleccionarEmpresa } from '../../../store/slices/multiempresaSlice';
import { storageEmpresaId } from '../../../assets/shared/sessionData';

import Spinner from '../Spinner/Spinner';
import { Tooltip } from '@mui/material';

const EmpresaSelector = (props) => {
  const dispatch = useDispatch();
  const empresas = useSelector(state => state.multiempresa.empresas.empresas);
  const loading = useSelector(state => state.multiempresa.empresas.loading);
  const cargandoIndicadores = useSelector(state => state.pivotGridData.ventasPorProductoData.loading 
    || state.ventasPorProducto.data.loading);

  const [empresaId] = useState(storageEmpresaId());
  const { setEmpresasessionStorage, onChangeValue, } = props;

  useEffect(() => {
    if (empresas.length === 0) {
      dispatch(loadEmpresas());
    }
  }, [empresas.length, dispatch]);

  const onEmpresaClick = (empresa) => {
    if (setEmpresasessionStorage) {      
      dispatch(seleccionarEmpresa({ empresaId: empresa.id }));
    } else {
      onChangeValue(empresa);
    }
  };

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '10px', zIndex: 1200 }}>
      <div>
        {empresas && empresas.map((empresa, key) => (
          <Tooltip
            title="Cargando indicadores..."
            placement="right"
            arrow
            open={cargandoIndicadores && key === props.empresas.length - 1}
            key={key}
          >
            <button
              disabled={cargandoIndicadores}
              key={key}
              style={{
                margin: '0px',
                outline: 'none',
                color: empresa.id === empresaId ? '#FFFFFF' : '#CECECE',
                border: '0.5px solid #CECECE',
                textAlign: 'center',
                font: 'normal normal medium 12px/16px Roboto',
                backgroundColor: empresa.id === empresaId ? '#0CC5FF' : '#FFFFFF',
                borderRadius: '3px 0px 0px 3px',
                cursor: empresa.id !== empresaId && !cargandoIndicadores && 'pointer'
              }}
              onClick={() => empresa.id !== empresaId && onEmpresaClick(empresa)}
            >
              {empresa.nombre}
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  );
};

export default EmpresaSelector;