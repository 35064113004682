import React, { useCallback, useEffect, useState } from 'react';
import { pathNamePages } from '../../assets/shared/pathnames';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import LightTooltip from '../UI/LightTooltip/LightTooltip';
import { Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { ejecutarInicio, ejecutarVentasPorProducto, ejecutarVentasPorTipoPago } from '../../store/slices/ejecucionPaginasSlice';
import { useDispatch } from 'react-redux';

const ButtonEjecucionPagina = (props) => {
  const { home, inicio, ventasProducto, ventasTipoPago } = pathNamePages;
  const dispatch = useDispatch();
  const ejecucionInicio = useSelector(state => state.ejecucionPaginas.ejecucionInicio);
  const ejecucionVentasPorProducto = useSelector(state => state.ejecucionPaginas.ejecucionVentasPorProducto);
  const ejecucionVentasPorTipoPago = useSelector(state => state.ejecucionPaginas.ejecucionVentasPorTipoPago);
  const paginaRender = useSelector(state => state.ejecucionPaginas.paginaRender);
  const ejecutarPaginaInicio = useCallback(() => dispatch(ejecutarInicio()), [dispatch]);
  const ejecutarPaginaVentasPorProducto = useCallback(() => dispatch(ejecutarVentasPorProducto()), [dispatch]);
  const ejecutarPaginaVentasPorTipoPago = useCallback(() => dispatch(ejecutarVentasPorTipoPago()), [dispatch]);
  const [botonOculto, setBotonOculto] = useState(false);

  const ejecutarPagina = () => {
    const path = global.window.location.pathname;
    path === home && ejecutarYOcultarBotonLocal(ejecutarPaginaInicio, home);
    path === inicio && ejecutarYOcultarBotonLocal(ejecutarPaginaInicio, inicio);
    path === ventasProducto && ejecutarYOcultarBotonLocal(ejecutarPaginaVentasPorProducto, ventasProducto);
    path === ventasTipoPago && ejecutarYOcultarBotonLocal(ejecutarPaginaVentasPorTipoPago, ventasTipoPago);
  };

  const ejecutarYOcultarBotonLocal = (ejecucionMethod, paginaKey) => {
    const path = global.window.location.pathname;
    path === paginaKey && ejecucionMethod();
    setBotonOculto(true);
  };

  const ocultarBoton = useCallback((pathRender) => {
    (pathRender === home) && setBotonOculto(ejecucionInicio);
    (pathRender === inicio) && setBotonOculto(ejecucionInicio);
    (pathRender === ventasProducto) && setBotonOculto(ejecucionVentasPorProducto);
    (pathRender === ventasTipoPago) && setBotonOculto(ejecucionVentasPorTipoPago);
  }, [home, inicio, ejecucionInicio, ejecucionVentasPorProducto,
    ejecucionVentasPorTipoPago, ventasProducto, ventasTipoPago]);

  useEffect(() => {
    ocultarBoton(paginaRender);
  }, [paginaRender, ocultarBoton]);

  return (
    !botonOculto && (
      <LightTooltip
        arrow
        title={
          <Typography style={{ textAlign: 'left', font: 'normal normal normal 14px/19px Roboto', color: '8D8C8C' }}>
            Ejecutar página
          </Typography>
        }
        placement="bottom"
      >
        <PlayCircleFilledWhiteOutlinedIcon
          onClick={() => ejecutarPagina()}
          style={{ color: 'white', fontSize: '1.8rem', cursor: 'pointer', marginRight: '10px' }}
        />
      </LightTooltip>
    )
  );
};

export default ButtonEjecucionPagina;