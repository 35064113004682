import React from 'react';
import MaterialTable from 'material-table';

const Table = (props) => {
  const {
    title,
    columns,
    data,
    loading,
    actions,
    components,
    customOptions,
    actionsTitle,
    editable,
    noBoxShadow,
    onRowClick,
    detailPanel,
    style,
    tableRef
  } = props;

  return (
    <MaterialTable
      style={{ ...style, boxShadow: noBoxShadow && 'none' }}
      title={title}
      columns={columns}
      editable={editable}
      data={data}
      tableRef={tableRef}
      isLoading={loading}
      actions={actions}
      components={components}
      detailPanel={detailPanel}
      onRowClick={props.onRowClick ? (event, rowData) => onRowClick(event, rowData) : undefined}
      onColumnDragged={
        props.onColumnDragged
          ? (sourceIndex, destinationIndex) => props.onColumnDragged(sourceIndex, destinationIndex)
          : undefined
      }
      localization={{
        header: {
          actions: actionsTitle
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'Filas',
          previousTooltip: 'Página anterior',
          nextTooltip: 'Página siguiente',
          firstTooltip: 'Primer página',
          lastTooltip: 'Última página',
          labelRowsPerPage: 'Filas por página:',
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
          exportName: 'Descargar en CSV',
          exportAriaLabel: 'Exportar',
          exportTitle: 'Exportar',
          addRemoveColumns: 'add col'
        },
        body: {
          editTooltip: 'Editar',
          addTooltip: 'Nuevo',
          deleteTooltip: 'Eliminar',
          emptyDataSourceMessage: loading ? 'Cargando...' : 'No hay datos para mostrar',
          editRow: {
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Confirmar',
            deleteText: '¿Confirma que quiere eliminar?',
          }
        }
      }}
      options={{
        ...customOptions
      }}
    />
  );
};

export default Table;
