import React, { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from 'react-redux';
import Dialog from "../Dialog/Dialog";
import { Typography, IconButton, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EnviarExcelEmailModal from "./EnviarExcelEmailModal";
import EnviarChartEmailModal from "./EnviarChartEmailModal";
import { showSendChartModal } from "../../../store/slices/modalsSlice";

const useStyles = makeStyles({
  titleTexto: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }
});

const EnviarChart = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const sendChart = useSelector(state => state.modals.sendChartModal);  
  const [subject] = useState(`Enviar ${sendChart.type === 'chart' ? "gráfico" : "tabla"}`);

  const onClose = () => {
    dispatch(showSendChartModal(false, null));
  }

  const sendAttachmentDialog = () => {
    return sendChart.type === 'chart'
      ? (
        <EnviarChartEmailModal />
      ) : (
        <EnviarExcelEmailModal />
      )
  }

  return (
    <Fragment>
      <div id="enviarChart"></div>
      {sendChart.show && (
        <Dialog
          fullWidth
          maxWidth={"sm"}
          title={
            <div style={{ width: "100%" }}>
              <div className={classes.titleTexto}>
                <Typography style={{ fontSize: "large" }}>{subject}</Typography>
                <IconButton aria-label="close" onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              </div>
              <Divider />
            </div>
          }
          show={sendChart.show}
          body={sendAttachmentDialog()}
          onClose={() => onClose()}
        />
      )}
    </Fragment>
  );
}

export default EnviarChart;