import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadEmpresas, seleccionarEmpresa } from '../../../store/slices/multiempresaSlice';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { storageEmpresaId } from '../../../assets/shared/sessionData';
import Spinner from '../Spinner/Spinner';
import { useCallback } from 'react';

const EmpresaSelector = (props) => {
  const dispatch = useDispatch();
  const [empresaId, setEmpresaId] = useState(storageEmpresaId());

  const onLoadEmpresas = useCallback(() => dispatch(loadEmpresas()), [dispatch]);
  const onSeleccionarEmpresa = useCallback((empresaId) => dispatch(seleccionarEmpresa({ empresaId })), [dispatch]);
  const loading = useSelector(state => state.multiempresa.empresas.loading);
  const empresas = useSelector(state => state.multiempresa.empresas.empresas);

  useEffect(() => {
    if (empresas && empresas.length === 0) {
      onLoadEmpresas();
    }
  }, [empresas, empresas.length, onLoadEmpresas]);

  return loading ? (
    <Spinner size="small" />
  ) : ( 
    <FormControl variant="standard" style={{ margin: '0px 10px 0px 10px' }}>
      <InputLabel id="empresaInputLabel">Empresas</InputLabel>
      <Select
        labelId="empresaLabel"
        id="empresaSelect"
        value={props.empId ? props.empId : empresaId}
        onChange={(event) => {
          setEmpresaId(event.target.value);
          if (props.setEmpresasessionStorage) {
            onSeleccionarEmpresa(event.target.value);
          } else {
            props.onChangeValue(event);
          }
        }}
      >
        {empresas.map((empresa) => (
          <MenuItem key={empresa.id} value={empresa.id}>
            {empresa.nombre}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default EmpresaSelector;
