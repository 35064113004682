import React from "react";
import { urlPortalLogout } from "../../assets/shared/urlApps";
import AvatarUsuarioLog from "../UI/AvatarUsuarioLog/AvatarUsuarioLog";
import Menu from "../UI/Menu/Menu";

const listItems = [
  {
    id: "logout",
    title: "Cerrar sesión",
  },
];

const MenuUsuario = (props) => {
  const onLogoutClick = () => {
    sessionStorage.clear();
    return setTimeout(() => urlPortalLogout(), 100);
  };

  const onItemClick = (selectedItem) => {
    switch (selectedItem.id) {
      case "logout":
        return onLogoutClick();

      default:
        break;
    }
  };

  return (
    <div style={{ cursor: "pointer" }}>
      <Menu
        placement={"bottom-end"}
        email={sessionStorage.getItem("userEmail")}
        title={<AvatarUsuarioLog />}
        listItems={listItems}
        onItemClick={(selectedItem) => onItemClick(selectedItem)}
      />
    </div>
  );
};

export default MenuUsuario;