import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../../axios-dashboard';
import { baseUrlDashboard } from '../../assets/shared/urlApps';
import idIndicadores from "../../assets/shared/idIndicadores";
import { enqueueSnackbar } from './snackbarSlice';
import { useDispatch } from 'react-redux';
import { storageToken } from '../../assets/shared/sessionData';

const initialState = {
  empresas: {
    loading: false,
    empresas: [],
    error: null,
    estaCargado: false
  },
  loadingCambiarEmpresa: false
};

export const loadEmpresas = createAsyncThunk(
  'multiEmpresa/loadEmpresas',
  async () => {
    const response = await axios.get(`${baseUrlDashboard()}empresas/empresas`);
    return response.data;
  }
);

export const seleccionarEmpresa = createAsyncThunk(
  'multiEmpresa/seleccionarEmpresa',
  async ({ empresaId}) => {
    const response = await axios.put(`${baseUrlDashboard()}empresas/CambiarEmpresaPorDefecto?empresaId=${empresaId}&indicadorId=${idIndicadores.cambiarEmpresa}`);
    return response;
  }
);

export const multiEmpresaSlice = createSlice({
  name: 'multiEmpresa',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEmpresas.pending, (state) => {
        state.empresas.loading = true;
        state.empresas.empresas = [];
        state.empresas.estaCargado = false;
        state.empresas.error = null;
      })
      .addCase(loadEmpresas.fulfilled, (state, response) => {
        state.empresas.loading = false;
        state.empresas.empresas = response.payload;
        state.empresas.estaCargado = true;
        state.empresas.error = null;
      })
      .addCase(loadEmpresas.rejected, (state, error) => {
        state.empresas.loading = false;
        state.empresas.empresas = [];
        state.empresas.estaCargado = true;
        state.empresas.error = error;
      })

      .addCase(seleccionarEmpresa.pending, (state) => {
        state.loadingCambiarEmpresa = true;
      })
      .addCase(seleccionarEmpresa.fulfilled, (state, response) => {
        const data = response.payload.data;
        sessionStorage.setItem('empresaId', data.empresaId);
        sessionStorage.setItem('monedaId', data.monedaId);
        sessionStorage.setItem('monedaSigno', data.monedaSigno);
        sessionStorage.setItem('empresaNombre', data.empresa);

        //window.location.href = window.location.origin + '?' + storageToken();
        window.location.reload();
      })
      .addCase(seleccionarEmpresa.rejected, (state, response) => {
        const dispatch = useDispatch();
        dispatch(
          enqueueSnackbar({
            message: `No se pudo cambiar de empresa, intente nuevamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );

        state.loadingCambiarEmpresa = false;
      })
  }
});

export default multiEmpresaSlice.reducer;