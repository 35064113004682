import React, { Fragment } from 'react';
import IconConvertToExcel from '../../../assets/images/IconConvertToExcel.png';
import { IconButton, Tooltip } from '@mui/material';
import { downloadExcel } from "react-export-table-to-excel";

const ExportToExcelIcon = (props) => {
  const { filename, name, tooltipTitle, dataSet, disabled } = props;

  function handleDownloadExcel() {
    const header = dataSet && dataSet[0] && dataSet[0].columns
      ? dataSet[0].columns.map((column) => { return column.title; })
      : [];

    const body = dataSet && dataSet[0] && dataSet[0].data
      ? dataSet[0].data.map((row) => row.map((r) => r.value))
      : [];

    downloadExcel({
      fileName: filename,
      sheet: name,
      tablePayload: {
        header,
        body,
      },
    });
  }

  return (
    <Fragment>
      <Tooltip title={tooltipTitle ? tooltipTitle : "Descargar en excel"}>
        <IconButton disabled={disabled} onClick={handleDownloadExcel} style={{ marginRight: '10px' }} size="small">
          <img alt="" src={IconConvertToExcel} height={'33px'} />
        </IconButton>
      </Tooltip>
    </Fragment>
  );
}

export default ExportToExcelIcon;