import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  globalModal: {
    show: false,
    title: "",
    body: "",
    footer: "",
    disableCloseButton: false,
    fullWidth: false,
    maxWidth: "",
    fullScreen: false,
  },
  listFiltersModal: {
    show: false,
    title: "",
    filterTypes: [],
  },
  sendChartModal: {
    show: false,
    chartRef: null,
    current: null,
    titulo: "",
    type: 'chart',
    filename: "",
  },
  estadoModal: {
    show: false,
    title: "",
    body: "",
  },
  backButtonModal: {
    show: false,
  },
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showGlobalModal: {
      reducer: (state, action) => {
        state.globalModal = action.payload;
      },
      prepare: (show, title, body, footer = undefined, disableCloseButton = false, fullWidth = true, fullScreen = false, maxWidth = 'md') => {
        return {
          payload: {
            show,
            title,
            body,
            footer,
            disableCloseButton,
            fullWidth,
            fullScreen,
            maxWidth
          }
        }
      }
    },
    changeGlobalModalBody: {
      reducer: (state, action) => {
        state.globalModal = action.payload;
      },
      prepare: (body) => {
        return {
          payload: body
        };
      }
    },
    showListFiltersModal: {
      reducer: (state, action) => {
        state.listFiltersModal.show = action.payload.show;
        state.listFiltersModal.title = action.payload.title;
        state.listFiltersModal.filterTypes = action.payload.filterTypes;
      },
      prepare: (show, title, filterTypes) => {
        return { payload: { show, title, filterTypes, } };
      }
    },
    hiddenGlobalModal: (state, action) => {
      state.globalModal = initialState.globalModal;
    },
    showSendChartModal: {
      reducer: (state, action) => {
        state.sendChartModal.show = action.payload.show;
        state.sendChartModal.filename = action.payload.filename;
        state.sendChartModal.titulo = action.payload.titulo;
        state.sendChartModal.current = action.payload.current;
        state.sendChartModal.chartRef = action.payload.chartRef;
        state.sendChartModal.type = 'chart';
      },
      prepare: (show, chartRef, tituloChart) => {
        return {
          payload: {
            show,
            titulo: tituloChart,
            current: chartRef && chartRef.current,
            chartRef: chartRef,
            filename: null
          }
        };
      }
    },
    showSendAttachmentModal: {
      reducer: (state, action) => {
        state.sendChartModal.show = action.payload.show;
        state.sendChartModal.filename = action.payload.filename;
        state.sendChartModal.titulo = action.payload.titulo;
        state.sendChartModal.current = action.payload.current;
        state.sendChartModal.chartRef = action.payload.chartRef;
        state.sendChartModal.type = 'excel';
      },
      prepare: (show, contentRef, title, filename) => {
        return {
          payload: {
            show,
            titulo: title,
            current: contentRef && contentRef.current,
            chartRef: contentRef,
            filename
          }
        };
      }
    },
    showEstadoModal: {
      reducer: (state, action) => {
        state.estadoModal.show = action.payload.show;
        state.estadoModal.title = action.payload.title;
        state.estadoModal.body = action.payload.body;
      },
      prepare: (show, title, body) => {
        return {
          payload: {
            show,
            title,
            body,
          }
        };
      }
    },
    hiddenEstadoModal: (state) => {
      state.estadoModal = initialState.estadoModal;
    },
    showBackButtonModal: {
      reducer: (state, action) => {
        state.backButtonModal.show = action.payload.show;
      },
      prepare: (show) => {
        return {
          payload: {
            show,
          }
        };
      }
    },
  }
});

export const { showGlobalModal, changeGlobalModalBody, showListFiltersModal, hiddenGlobalModal,
  showSendChartModal, showSendAttachmentModal, showEstadoModal, hiddenEstadoModal, showBackButtonModal } = modalsSlice.actions;

export default modalsSlice.reducer;

export const listFiltersModal = state => state.listFiltersModal;