export const pivotGridFormat = (monedaSigno) => {
  return [
    {
      caption: '- Empresa',
      dataField: 'nombreEmpresa',
      name: 'nombreEmpresa',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Año',
      dataField: 'anio',
      name: 'anio',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Mes',
      dataField: 'mes',
      name: 'mes',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Semana',
      dataField: 'semana',
      name: 'semana',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Tipo de Pago',
      dataField: 'tipoPago',
      name: 'tipoPago',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    {
      caption: '- Descripcion de Pago',
      dataField: 'descripcionPago',
      name: 'descripcionPago',
      area: 'row',
      isMeasure: false,
      expanded: false,
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true
    },
    
    {
      caption: '| Obra Social',
      dataField: 'obraSocial',
      name: 'obraSocial',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
    {
      caption: '| Obra Social Comparación',
      dataField: 'obraSocialComparacion',
      name: 'obraSocialComparacion',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
    {
      caption: '| Importe Pago',
      dataField: 'importePago',
      name: 'importePago',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
    {
      caption: '| Importe Pago Comparación',
      dataField: 'importePagoComparacion',
      name: 'importePagoComparacion',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
    {
      caption: '| Neto Total',
      dataField: 'netoTotal',
      name: 'netoTotal',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
    {
      caption: '| Neto Total Comparación',
      dataField: 'netoTotalComparacion',
      name: 'netoTotalComparacion',
      dataType: 'number',
      area: 'data',
      allowFiltering: true,
      allowSorting: true,
      allowSortingBySummary: true,
      summaryType: 'sum',
      format: `${monedaSigno} ,##0.00`
    },
  ];
}