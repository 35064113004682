import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import Spinner from '../../UI/Spinner/Spinner';
import SinResultados from '../../../assets/images/SinResultados.png';
import IndicadorDeshabilitado from '../../../assets/images/IndicadorDeshabilitado.png';
import { Grid, Typography, IconButton, Divider } from '@mui/material';
import classes from './CustomPivotGrid.module.css';
import TablePivotGrid from './TablePivotGrid/TablePivotGrid';
import AdminFormatIcon from './Icons/AdminFormatIcon';
import AdminPivotGrid from './AdminPivotGrid/AdminPivotGrid';
import Dialog from '../Dialog/Dialog';
import { CloseOutlined, ExpandLess, ExpandMore } from '@mui/icons-material';
import ExportPivotGridToExcelIcon from './Icons/ExportPivotGridToExcelIcon';
import CambiarVistaIcon from './Icons/CambiarVistaIcon';
import CustomSwitch from './../CustomSwitch/CustomSwitch';
import { compareByAsc } from '../../../assets/shared/sorting';
import SubtotalSelector from './SubtotalSelector/SubtotalSelector';
import ExportPdfIcon from '../../UI/Icons/ExportToPdfIcon';
import SendExcelToEmailIcon from './Icons/SendExcelToEmailIcon';
import moment from "moment";
import "moment/locale/es";

const CustomPivotGrid = (props) => {
  const {
    storageKey, loading, dataSource, rawDatos, rawFiltros, height, containerWidth, gridWidth, title, subTitle,
    minHeight, fechaActualizacion, disabled, imgSinDatos, onCellClick, exportToExcel, sheetName, fileName,
    configFields, onCargarFormato, onCargarFormatoCubo, onCellPrepared, onContentReady, onViewChange,
    selectedTotalRows, totalRowsOptions, onSelectedTotalRowsChange, onLoadDataImport } = props;

  const [customLoading, setCustomLoading] = useState(false);
  const pivotGridRef2 = useRef(null);
  const customPivotGridRef = useRef(null);
  const pivotGridRef = useCallback(node => {
    if (node)
      pivotGridRef2.current = node;
  }, []);

  const onUpdateDataSource = useCallback(() => {
    if (pivotGridRef2 && pivotGridRef2.current && pivotGridRef2.current.props) {
      if (pivotGridRef2.current.props["getDataSource"])
        pivotGridRef2.current.props.getDataSource().reload();

      if (pivotGridRef2.current.props["dataSource"])
        pivotGridRef2.current.props.dataSource.reload();
    }

    props.onUpdateDataSource();
  }, [pivotGridRef2, props.onUpdateDataSource]);

  //#region Leyenda Filtro Utilizado
  const fechaUltimaActualizacion = (fechaActualizacion) => {
    const format = "DD-MM-YYYY HH:mm:ss";
    const fechatText = fechaActualizacion instanceof Date
      ? new moment(fechaActualizacion).format(format)
      : typeof fechaActualizacion === 'string' ? new moment(new Date(fechaActualizacion)).format(format) : fechaActualizacion;

    return fechatText ? (
      <Typography variant="h6" fontSize={'medium'} fontWeight={'500'}>
        Información actualizada al: <span style={{ fontWeight: '300' }}> {fechatText}</span>
      </Typography>
    ) : (<Fragment></Fragment>)
  }

  const filtrosUtilizados = useCallback(() => {
    let storageItem = localStorage.getItem(storageKey);
    let pivotGrid = JSON.parse(storageItem);
    let fields = dataSource && dataSource.fields();

    let filtros = pivotGrid && pivotGrid.fields && pivotGrid.fields
      .filter((field) => field.filterValues && field.filterValues.length > 0)
      .map((filtro) => {
        let item = fields && fields.find((field) => field.name === filtro.name);
        let caption = item && item.caption && item.caption.replace('|', '') && item.caption.replace('-', '');

        return { filter: caption };
      })
      .sort((a, b) => compareByAsc(a, b, 'filter'));

    let items = filtros && filtros.map((item, index) => {
      return (
        <span key={`filter-${index}`} style={{ fontSize: 'medium', fontWeight: '300' }}>
          {item.filter}{index < filtros.length - 1 ? " - " : ""}
        </span>
      )
    });

    return filtros && filtros.length > 0
      ? (
        <Fragment>
          <Typography variant="h6" fontSize={'medium'} fontWeight={'500'}>
            Filtros aplicados por: {items}
          </Typography>
        </Fragment>
      ) : (
        <Fragment></Fragment>
      )
  }, [storageKey, dataSource]);

  //#endregion



  //#region Mostrar Totals  
  const [showColumnTotals, setShowColumnTotals] = useState(true);
  const [showColumnGrandTotals, setShowColumnGrandTotals] = useState(true);
  const [showRowTotals, setShowRowTotals] = useState(false);
  const [showRowGrandTotals, setShowRowGrandTotals] = useState(true);

  const onShowColumnTotalsChange = useCallback(() => {
    let value = !showColumnTotals;

    setCustomLoading(true);
    setShowColumnTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showColumnTotals]);

  const onShowColumnGrandTotalsChange = useCallback(() => {
    let value = !showColumnGrandTotals;

    setCustomLoading(true);
    setShowColumnGrandTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showColumnGrandTotals]);

  const onShowTotalRowsChange = useCallback(() => {
    let value = !showRowTotals;

    setCustomLoading(true);
    setShowRowTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showRowTotals]);

  const onShowRowGrandTotalsChange = useCallback(() => {
    let value = !showRowGrandTotals;

    setCustomLoading(true);
    setShowRowGrandTotals(value);
    setTimeout(() => setCustomLoading(false), 500);
  }, [showRowGrandTotals]);

  const onCustomSelectedTotalRowsChange = useCallback((items) => {
    const show = items && items.length > 0;
    setShowRowTotals(show);
    onSelectedTotalRowsChange(items);
  }, [onSelectedTotalRowsChange]);

  useEffect(() => {
    if (selectedTotalRows && selectedTotalRows.length > 0 && !showRowTotals)
      setShowRowTotals(true);
  }, [selectedTotalRows, showRowTotals]);

  useEffect(() => {
    if (showRowTotals && (!selectedTotalRows || selectedTotalRows.length <= 0))
      setShowRowTotals(false);
  }, [selectedTotalRows, showRowTotals]);

  //#endregion

  //#region Administrar Formatos
  const [showAdminModal, setShowAdminModal] = useState(false);
  const onClose = () => {
    setShowAdminModal(false);
  }

  const getAdminPivotGridModal = () => {
    return (
      <AdminPivotGrid
        title={'Administrar Pivot Grid'}
        pivotGridKey={storageKey}
        pivotGridRef={pivotGridRef2}
        onClose={onClose}
        onUpdateDataSource={onUpdateDataSource}
        onCargarFormato={onCargarFormato}
        onCargarFormatoCubo={onCargarFormatoCubo}
        datos={rawDatos}
        filtros={rawFiltros}
        onLoadDataImport={onLoadDataImport}
        showColumnTotals={showColumnTotals}
        onShowColumnTotalsChange={onShowColumnTotalsChange}
        showColumnGrandTotals={showColumnGrandTotals}
        onShowColumnGrandTotalsChange={onShowColumnGrandTotalsChange}
        showRowTotals={showRowTotals}
        onShowTotalRowsChange={onShowTotalRowsChange}
        showRowGrandTotals={showRowGrandTotals}
        onShowRowGrandTotalsChange={onShowRowGrandTotalsChange}
      />
    );
  };

  //#endregion  

  //#region Mostrart Total por row
  const getSelectItems = useCallback(() => {
    if (totalRowsOptions && totalRowsOptions.length > 0) {
      const items = totalRowsOptions
        .sort((a, b) => compareByAsc(a, b, 'dataField'))
        .map((item) => {
          return {
            id: item.dataField,
            nombre: item.caption ? item.caption.replace('- ', '') : item.dataField,
            index: item.areaIndex
          }
        });
      return items;
    }

    return [];
  }, [totalRowsOptions]);
  //#endregion

  //#region Colapsar Filtros
  const [openFilters, setOpenFilters] = useState(false);
  const inPhone = window.screen.width <= 750;
  //#endregion

  return (
    <Fragment>
      <Grid container
        ref={customPivotGridRef}
        disabled={disabled}
        style={{
          height: "100%", width: containerWidth, minHeight: minHeight + 50,
          border: "0px solid #ccc", boxShadow: "0px 3px 6px #00000029",
          backgroundColor: "white", borderRadius: "3px", overflowX: props.gridContainerOverflowX, overflowY: 'auto'
        }}
      >
        <Grid item xs={12}
          container spacing={1}
          direction='row' justifyContent='space-between' alignContent='flex-start'
          style={{ maxWidth: '94.5vw', margin: 'auto', marginLeft: '20px' }}
        >
          <Grid item xs={12} container className={classes.TitleContainer}
            justifyContent="space-between" alignItems="center">
            <Grid item xs={openFilters ? 12 : 9} lg={10} container flexDirection={'column'} className={classes.Title}>
              <Grid item xs={12}>
                {title()}
              </Grid>
              {!openFilters && !inPhone && (
                <Grid item xs={12} container flexDirection={'row'} justifyContent={'flex-start'}>
                  {fechaActualizacion && (<Grid item xs={12} lg={3}>
                    {fechaUltimaActualizacion(fechaActualizacion)}
                  </Grid>)}
                  <Grid item xs={12} lg={9}>
                    {filtrosUtilizados()}
                  </Grid>
                </Grid>)}
            </Grid>
            <Grid item xs={openFilters ? 12 : 3} lg={2} container spacing={1} justifyContent={'flex-end'} className="iconsPivotGrid">
              {!openFilters && !inPhone && (
                <Fragment>
                  <Grid item xs={3} lg={2} style={{ padding: '0px' }}>
                    <ExportPdfIcon
                      element={customPivotGridRef && customPivotGridRef.current}
                      fileName={fileName}
                      disabled={!rawDatos || rawDatos.length <= 0}
                    />
                  </Grid>
                  <Grid item xs={3} lg={2} style={{ padding: '0px' }}>
                    <ExportPivotGridToExcelIcon
                      pivotGridRef={pivotGridRef2}
                      sheetName={sheetName}
                      fileName={fileName}
                      disabled={!rawDatos || rawDatos.length <= 0}
                    />
                  </Grid>
                  <Grid item xs={3} lg={2} style={{ padding: '0px' }}>
                    <SendExcelToEmailIcon
                      pivotGridRef={pivotGridRef2}
                      title={sheetName}
                      fileName={fileName}
                      disabled={!rawDatos || rawDatos.length <= 0}
                    />
                  </Grid>
                </Fragment>
              )}
              <Grid item xs={3} lg={2} style={{ padding: '0px' }}>
                <AdminFormatIcon
                  iconSize='medium'
                  filterSize='medium'
                  onClick={() => setShowAdminModal(true)}
                />
              </Grid>
              <Grid item xs={3} lg={2} style={{ padding: '0px' }}>
                <span title={openFilters ? "Ocultar filtros" : "Visualizar filtros"}>
                  <IconButton size='large' onClick={() => setOpenFilters(!openFilters)}>
                    {openFilters ? <ExpandLess /> : <ExpandMore />}
                  </IconButton>
                </span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container style={{ minHeight: minHeight, alignContent: 'flex-start' }}>
          <Grid item xs={12} container className={classes.SubTitleContainer} style={{ marginLeft: '30px', display: openFilters ? 'flex' : 'none' }}>
            {subTitle()}
          </Grid>
          <Grid className="iconsPivotGrid" item xs={12} container justifyContent="space-between" alignItems="center"
            style={{ maxWidth: '95vw', maxHeight: '100px', marginBottom: '10px', display: openFilters ? 'flex' : 'none' }} >
            <Grid item xs={12} md={9} lg={6} container spacing={1}>
              <Grid item xs={4}>
                <CambiarVistaIcon onChange={onViewChange} config={configFields} />
              </Grid>
              <Grid item xs={4}>
                <CustomSwitch
                  value={props.rowsColumnsFixed}
                  title='Fijar filas y columnas'
                  onChange={() => props.onFixRowsColumns()}
                  firstLabel='No'
                  secondLabel='Si'
                />
              </Grid>
              {onSelectedTotalRowsChange && (
                <Grid item xs={4}>
                  <SubtotalSelector
                    selectedTotalRows={selectedTotalRows}
                    selectItems={getSelectItems()}
                    onSelectedTotalRowsChange={onCustomSelectedTotalRowsChange}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} sm={4} md={2} lg={2} container justifyContent='flex-end' >
              <Grid item xs={2} md={4} lg={2} style={{ textAlign: 'end' }}>
                <ExportPdfIcon
                  element={customPivotGridRef && customPivotGridRef.current}
                  fileName={fileName}
                  disabled={!rawDatos || rawDatos.length <= 0}
                />
              </Grid>
              <Grid item xs={2} md={4} lg={2} style={{ textAlign: 'end' }}>
                <ExportPivotGridToExcelIcon
                  pivotGridRef={pivotGridRef2}
                  sheetName={sheetName}
                  fileName={fileName}
                  disabled={!rawDatos || rawDatos.length <= 0}
                />
              </Grid>

              <Grid item xs={2} md={4} lg={2} style={{ padding: '0px' }}>
                <SendExcelToEmailIcon
                  pivotGridRef={pivotGridRef2}
                  title={sheetName}
                  fileName={fileName}
                  disabled={!rawDatos || rawDatos.length <= 0}
                />
              </Grid>
            </Grid>
            {fechaUltimaActualizacion && (
              <Grid item xs={12} style={{ marginLeft: '30px' }}>
                {fechaUltimaActualizacion()}
              </Grid>
            )}
            <Grid item xs={12} style={{ marginLeft: '30px' }}>
              {filtrosUtilizados()}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            {disabled ? (
              <Grid item xs={12} style={{ textAlign: 'center', minHeight: '220px' }}>
                <img alt="Indicador inhablitado" src={IndicadorDeshabilitado} />
              </Grid>
            ) : (loading || customLoading) ? (
              <Grid item xs={12} style={{ textAlign: 'center', minHeight: '220px' }}>
                <Spinner />
              </Grid>
            ) : imgSinDatos ? (
              <Grid item xs={12} style={{ textAlign: 'center', minHeight: '220px' }}>
                <img alt="Sin resultados" src={SinResultados} />
              </Grid>
            ) : (
              <TablePivotGrid
                storageKey={storageKey}
                height={height}
                width={gridWidth}
                minHeight={minHeight}
                onCellClick={onCellClick}
                exportToExcel={exportToExcel}
                pivotGridRef={pivotGridRef}
                dataSource={dataSource}
                configFields={configFields}
                onCellPrepared={onCellPrepared}
                onContentReady={onContentReady}
                onViewChange={onViewChange}
                selectedTotalRows={selectedTotalRows}
                scrollingMode={props.scrollingMode}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {showAdminModal && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          title={
            <Grid container direction={"column"}>
              <Grid item xs={12} container spacing={1} direction={"row"} justifyContent={"space-between"}>
                <Grid item xs={10} md={6} style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                  <Typography style={{ fontSize: "large" }}>Administrar Pivot Grid</Typography>
                </Grid>
                <Grid item xs={2} md={1}>
                  <IconButton aria-label="close" onClick={() => onClose()}>
                    <CloseOutlined />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          }
          show={showAdminModal}
          body={getAdminPivotGridModal()}
          onClose={() => onClose()}
        />
      )}
    </Fragment>
  )
};

export default CustomPivotGrid;