import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageEmpresaId, storageToken } from "../../../assets/shared/sessionData";
import { baseUrlDashboard } from '../../../assets/shared/urlApps';
import axios from '../../../axios-dashboard';

const initialState = {
  pivotGridConfiguraciones: {
    items: []
  },
  pivotGridInformaciones: {
    items: []
  },
};

const setItemsConfiguraciones = (state, key, configuraciones, loading, estaCargado, error) => {
  let items = state.pivotGridConfiguraciones.items;
  items = items.filter((item) => item.key !== key);
  let selected = configuraciones && configuraciones.length !== 0 ? configuraciones[0] : undefined;

  let newItem = {
    key,
    configuraciones,
    selected,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

const setItemsInformaciones = (state, key, informaciones, loading, estaCargado, error) => {
  let items = state.pivotGridInformaciones.items;
  items = items.filter((item) => item.key !== key);
  let selected = informaciones && informaciones.length !== 0 ? informaciones[0] : undefined;

  let newItem = {
    key,
    informaciones,
    selected,
    loading,
    estaCargado,
    error
  };

  items.push(newItem);

  return items;
}

export const loadPivotGridConfiguraciones = createAsyncThunk(
  'pivotGridConfiguracion/loadPivotGridConfiguraciones',
  async ({ key, idUsuario, idEmpresa = storageEmpresaId() }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios
      .get(`${baseUrlDashboard()}budgetForecast/GetConfiguraciones?key=${key}&idUsuario=${idUsuario}&idEmpresa=${idEmpresa}`, requestOptions);

    return {
      data: response.data,
      status: response.status,
      statusText: response.statusText,
      key
    };
  }
);

export const loadPivotGridInformaciones = createAsyncThunk(
  'pivotGridConfiguracion/loadPivotGridInformaciones',
  async ({ key, idUsuario, idEmpresa = storageEmpresaId() }) => {
    const requestOptions = {
      headers: {
        Authorization: 'Bearer '.concat(storageToken()),
        Accept: 'application/json', 'Content-Type': 'application/json'
      }
    };
    const response = await axios
      .get(`${baseUrlDashboard()}budgetForecast/GetInformaciones?key=${key}&idUsuario=${idUsuario}&idEmpresa=${idEmpresa}`, requestOptions);

    return {
      ...response,
      key
    };
  }
);

export const guardarPivotGridConfiguracion = (configuracion, onSucess, onError) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .post(`${baseUrlDashboard()}budgetForecast/guardarConfiguracion`, configuracion, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        if (onSucess)
          onSucess(response);
      })
      .catch((error) => {
        if (onError)
          onError(error);
      });
  };
};

export const guardarPivotGridInformacion = (informacion, onSucess, onError) => {
  return () => {
    const authStr = 'Bearer '.concat(storageToken());

    axios
      .post(`${baseUrlDashboard()}budgetForecast/guardarInformacion`, informacion, {
        headers: { Authorization: authStr }
      })
      .then((response) => {
        if (onSucess)
          onSucess(response);
      })
      .catch((error) => {
        if (onError)
          onError(error);
      });
  };
};

export const pivotGridConfiguracionSlice = createSlice({
  name: 'pivotGridConfiguracion',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(loadPivotGridConfiguraciones.pending, (state, action) => {
        let key = action.payload ? action.payload : action.meta && action.meta.arg && action.meta.arg.key;
        let items = setItemsConfiguraciones(state, key, [], true, false, null);
        state.pivotGridConfiguraciones = {
          items
        };
      })
      .addCase(loadPivotGridConfiguraciones.fulfilled, (state, action) => {
        let key = action.payload.key;
        let configuraciones = action.payload.data;
        let items = setItemsConfiguraciones(state, key, configuraciones, false, true, null);
        state.pivotGridConfiguraciones = {
          items
        };
      })
      .addCase(loadPivotGridConfiguraciones.rejected, (state, action) => {
        let key = action.payload.key;
        let error = action.payload.error;
        let items = setItemsConfiguraciones(state, key, [], false, true, error);
        state.pivotGridConfiguraciones = {
          items
        };
      })
      .addCase(loadPivotGridInformaciones.pending, (state, action) => {
        let key = action.payload ? action.payload : action.meta && action.meta.arg && action.meta.arg.key;
        let informaciones = [];
        let items = state.pivotGridInformaciones.items;
        let selected = informaciones && informaciones.length !== 0 ? informaciones[0] : undefined;
        items = items.filter((item) => item.key !== key);

        let newItem = {
          key,
          informaciones,
          selected,
          loading: true,
          estaCargado: false,
          error: null
        };

        items.push(newItem);
        state.pivotGridInformaciones = {
          items
        };
      })
      .addCase(loadPivotGridInformaciones.fulfilled, (state, action) => {
        let key = action.payload.key;
        let informaciones = action.payload.data;
        let items = setItemsInformaciones(state, key, informaciones, false, true, null);
        state.pivotGridInformaciones = {
          items
        };
      })
      .addCase(loadPivotGridInformaciones.rejected, (state, action) => {
        let key = action.payload.key;
        let error = action.payload.error;
        let items = setItemsInformaciones(state, key, [], false, true, error);
        state.pivotGridInformaciones = {
          items
        };
      })
  }
});

export default pivotGridConfiguracionSlice.reducer;

export const pivotGridConfiguracionesItems = (state, key) => {
  const pivotGridConfiguraciones = state.pivotGridConfiguracion.pivotGridConfiguraciones.items;
  return pivotGridConfiguraciones && pivotGridConfiguraciones.find((config) => config.key === key);
};
export const pivotGridConfiguracionesLoading = (state, key) => {
  const pivotGridConfiguraciones = state.pivotGridConfiguracion.pivotGridConfiguraciones.items;
  const items = pivotGridConfiguraciones && pivotGridConfiguraciones.find((config) => config.key === key);
  return items && items.loading;
};
export const pivotGridInformacionesItems = (state, key) => {
  const pivotGridInformaciones = state.pivotGridConfiguracion.pivotGridInformaciones.items;
  return pivotGridInformaciones && pivotGridInformaciones.find((config) => config.key === key);
}
export const pivotGridInformacionesLoading = (state, key) => {
  const pivotGridInformaciones = state.pivotGridConfiguracion.pivotGridInformaciones.items;
  const items = pivotGridInformaciones && pivotGridInformaciones.find((config) => config.key === key);
  return items && items.loading;
}