import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { storageEmpresaId, storageToken } from "../../../assets/shared/sessionData";
import axios from '../../../axios-dashboard';

const initialState = {
  tipoVentasDesagregadas: {
    tipos: [],
    loading: false,
    error: null,
    estaCargado: false
  }
}

export const loadTipoDeVentasDesagregadas = createAsyncThunk(
  'ventasDesagregadas/loadTipoDeVentas',
  async () => {
    const authStr = 'Bearer '.concat(storageToken());
    const response = await axios
      .get(`/farmacias/TipoDeVentasDesagregadas?idEmpresa=${storageEmpresaId()}`,
        { headers: { Authorization: authStr } });
    return { data: response.data, status: response.status, statusText: response.statusText };
  }
);

export const ventasDesagregadasSlice = createSlice({
  name: 'ventasDesagregadas',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadTipoDeVentasDesagregadas.pending, (state) => {
        state.tipoVentasDesagregadas = {
          tipos: [],
          loading: true,
          error: null,
          estaCargado: false
        };
      })
      .addCase(loadTipoDeVentasDesagregadas.fulfilled, (state, response) => {
        state.tipoVentasDesagregadas = {
          tipos: response.payload.data,
          loading: false,
          error: null,
          estaCargado: true
        };
      })
      .addCase(loadTipoDeVentasDesagregadas.rejected, (state, error) => {
        state.tipoVentasDesagregadas = {
          tipos: [],
          loading: false,
          error: error.response
            ? error.response.data
            : "Se produjo un error conectando con el servidor",
          estaCargado: true
        };
      })
  }
});

export default ventasDesagregadasSlice.reducer;