import React, { useCallback, useEffect, useState } from "react";
import { storageEmpresaId, storageUsuarioId } from "../../../../assets/shared/sessionData";
import { Button, Grid, Typography } from "@mui/material";
import ButtonPivotGrid from "../ButtonPivotGrid/ButtonPivotGrid";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Table from '../../Table/Table';
import SaveIcon from '@mui/icons-material/Save';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import { useDispatch } from 'react-redux';
import { loadPivotGridConfiguraciones, guardarPivotGridConfiguracion, pivotGridConfiguracionesItems, pivotGridConfiguracionesLoading } from '../../../../store/slices/pivotGrid/pivotGridConfiguracionSlice';
import { selectedTotalRowsState, updateSelectedTotalRows } from "../../../../store/slices/pivotGrid/pivotGridDataSlice";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    textTransform: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    border: 'none !important',
    padding: '8px',
    minWidth: '10px',
    minHeight: '10px',
    borderRadius: '50%'
  }
}));

const AdministrarFormato = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pivotGridKey, pivotGridRef, onClose, onCargarFormato } = props;
  const pivotGridConfiguraciones = useSelector(state => pivotGridConfiguracionesItems(state, pivotGridKey));
  const pivotGridLoading = useSelector(state => pivotGridConfiguracionesLoading(state, pivotGridKey));
  const selectedTotalRows = useSelector(state => selectedTotalRowsState(state));
  const [idUsuario] = useState(storageUsuarioId());
  const [formatData, setFormatData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onLoadConfiguraciones = useCallback((key, idUsuario) => {
    dispatch(loadPivotGridConfiguraciones({ key, idUsuario }));
  }, [dispatch]);
  const onSaveConfiguracion = useCallback((configuracion, onSuccess, onError) => {
    dispatch(guardarPivotGridConfiguracion(configuracion, onSuccess, onError));
  }, [dispatch]);
  const onUpdateSelectedTotalRows = useCallback((key, rows) => {
    dispatch(updateSelectedTotalRows(key, rows));
  }, [dispatch]);

  const selectedRows = () => {
    let rows = selectedTotalRows
      ? selectedTotalRows.find((i) => i.key === pivotGridKey)
        ? selectedTotalRows.find((i) => i.key === pivotGridKey).rows
        : []
      : [];
    return JSON.stringify(rows);
  }

  useEffect(() => {
    if (!pivotGridConfiguraciones || (pivotGridConfiguraciones && !pivotGridConfiguraciones.estaCargado && !pivotGridLoading))
      onLoadConfiguraciones(pivotGridKey, idUsuario);
  }, [pivotGridConfiguraciones, pivotGridKey, idUsuario, onLoadConfiguraciones, pivotGridLoading]);

  //#region NEW
  const cellStyle = {
    fontSize: '11px',
    paddingBottom: '5px',
    padding: '5px'
  };
  const headerStyle = {
    fontSize: '13px',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'rgb(220 220 220)'
  };
  const columns = [
    { title: 'ID', field: 'id', align: 'left', width: "5%", cellStyle: cellStyle, headerStyle: headerStyle, hidden: true },
    { title: 'Nombre', field: 'nombre', align: 'left', width: "60%", cellStyle: cellStyle, headerStyle: headerStyle, defaultSort: 'asc', validate: rowData => Boolean(rowData.nombre && rowData.nombre.trim()), },
    { title: 'Público*', field: 'esPublica', align: 'center', width: "10%", cellStyle: cellStyle, headerStyle: headerStyle, type: 'boolean' },
    { title: 'Creador', field: 'usuarioNombre', align: 'center', width: "15%", cellStyle: cellStyle, headerStyle: headerStyle, editable: 'never', type: 'string' },
    { title: 'Actualizado', field: 'fechaImportacion', align: 'center', width: "2%", cellStyle: cellStyle, headerStyle: headerStyle, editable: 'never', type: 'datetime' },
  ];

  const onCancelClick = () => {
    onClose();
  }

  //#endregion

  //#region ACTUALIZAR FORMATO
  // Actualizar formato confirm dialog
  const [actualizarFormatoConfirmDialogOpen, setActualizarFormatoConfirmDialogOpen] = useState(false);

  const handleActualizarFormatoConfirmDialogOpen = () => {
    setActualizarFormatoConfirmDialogOpen(true);
  };

  const handleActualizarFormatoConfirmDialogClose = () => {
    setActualizarFormatoConfirmDialogOpen(false);
  };

  const actualizarFormatoConfirmDialog = () => (
    <ConfirmDialog
      show={actualizarFormatoConfirmDialogOpen}
      title="Actualizar Formato"
      body="Si confirma la acción, se pisará el formato actual con el nuevo."
      onConfirm={() => {
        handleActualizarFormatoConfirmDialogClose();
        onActualizarFormato(formatData);
      }}
      onClose={handleActualizarFormatoConfirmDialogClose}
    >
    </ConfirmDialog>
  );
  // - confirm dialog

  const onActualizarSuccess = () => {
    setIsLoading(false);
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    alert("La actualización de la estructura del cubo fue realizada correctamente.");
  }

  const onActualizarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onActualizarFormato = (rowData) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey).fields;
      }

      let config = {
        id: rowData.id,
        nombre: rowData.nombre,
        key: pivotGridKey,
        configuracion: JSON.stringify(fields),
        esPublica: rowData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onActualizarSuccess, onActualizarFail);
    }
  }
  //#endregion ACTUALIZAR FORMATO

  //#region USAR FORMATO
  const onUsarFormato = (rowData) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      setIsLoading(true);
      localStorage.removeItem(pivotGridKey);
      onCargarFormato(rowData);
      setTimeout(() => {
        setIsLoading(false);
        onClose();
      }, 1000);
    }
  }
  //#endregion USAR FORMATO

  //#region EDITAR FORMATO
  const onEditarSuccess = () => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onEditarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onEditarFormato = (newData, oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: newData.nombre,
        key: pivotGridKey,
        configuracion: oldData.configuracion,
        esPublica: newData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onEditarSuccess, onEditarFail);
    }
    resolve();
  }
  //#endregion EDITAR FORMATO

  //#region CREAR FORMATO
  const onCrearSuccess = () => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onCrearFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onCrearFormato = (newData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let fields = [];
      if (pivotGridRef.current && pivotGridRef.current.props
        && pivotGridRef.current.props.dataSource) {
        fields = pivotGridRef.current.props.dataSource.fields();
      } else {
        fields = localStorage.getItem(pivotGridKey).fields;
      }

      let config = {
        id: null,
        nombre: newData.nombre,
        key: pivotGridKey,
        configuracion: JSON.stringify(fields),
        esPublica: newData.esPublica ? newData.esPublica : false,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onCrearSuccess, onCrearFail);
    }
    resolve();
  }
  //#endregion CREAR FORMATO

  //#region BORRAR FORMATO
  const onBorrarSuccess = () => {
    onLoadConfiguraciones(pivotGridKey, idUsuario);
    setIsLoading(false);
  }

  const onBorrarFail = (error) => {
    console.log(error);
    setIsLoading(false);
  }

  const onBorrarFormato = (oldData, resolve) => {
    var form = document.getElementById("adminPivotGrid");
    form.classList.add('was-validated');

    if (form.checkValidity()) {
      let config = {
        id: oldData.id,
        nombre: oldData.nombre,
        key: pivotGridKey,
        configuracion: oldData.configuracion,
        esPublica: oldData.esPublica,
        incluyeDatos: false,
        datos: undefined,
        empresaId: storageEmpresaId(),
        usuarioId: idUsuario,
        borrado: true,
        filasSubtotales: selectedRows()
      };

      setIsLoading(true);
      onSaveConfiguracion(config, onBorrarSuccess, onBorrarFail);
    }
    resolve();
  }
  //#endregion BORRAR FORMATO
  const data = () => pivotGridConfiguraciones && pivotGridConfiguraciones.configuraciones
    && pivotGridConfiguraciones.configuraciones.filter(c => !c.borrado).map(o => ({ ...o }));

  return (
    <form id="adminPivotGrid" autoComplete="off">
      <Grid container spacing={1} display={"flex"} flexDirection={"column"} justifyContent='space-between'>
        <Grid item xs={12} container alignContent='flex-start'>
          <Grid item xs={12} >
            <Typography variant="h5" fontWeight={'600'}>
              Administrar Formatos
            </Typography>
            <Typography variant="h6" align="justify" fontSize={'small'} fontWeight={'400'}>
              El formato es la estructura que contiene los datos. Al grabar el formato, no guarda los datos,
              sino lo que guarda son todos los filtros configurados, las columnas visualizadas y el orden de las mismas.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Table
              style={{ width: '100%' }}
              title=""
              noBoxShadow
              columns={columns}
              data={data()}
              loading={isLoading || pivotGridLoading}
              editable={{
                isEditable: rowData => rowData.usuarioId === idUsuario,
                isDeletable: rowData => rowData.usuarioId === idUsuario,
                editTooltip: rowData => "edit",
                deleteTooltip: rowData => "delete",
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    onEditarFormato(newData, oldData, resolve);
                  }),
                onRowAdd: (newData) =>
                  new Promise((resolve) => {
                    onCrearFormato(newData, resolve);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    onBorrarFormato(oldData, resolve);
                  }),
              }}
              actions={[
                {
                  icon: 'save',
                  tooltip: "Actualizar formato",
                  onClick: (event, rowData) => {
                    setFormatData(rowData)
                    handleActualizarFormatoConfirmDialogOpen();
                  },
                },
                {
                  icon: 'apply',
                  tooltip: "Aplicar formato",
                  onClick: (event, rowData) => onUsarFormato(rowData),
                },
              ]}
              components={{
                Action: (props) => {
                  var buttonKey = props.action && props.action.icon ? props.action.icon : props.action(props.data).tooltip;
                  const active = props.data && props.data.usuarioId === idUsuario;

                  if (buttonKey === "save") {
                    return (
                      <Button
                        onClick={(event) => props.action.onClick(event, props.data)}
                        variant="outlined"
                        size="small"
                        color="primary"
                        title={props.action.tooltip}
                        disabled={!active}
                        className={classes.actionButton}
                      >
                        <SaveIcon style={{ color: (active ? '#f88d37' : '#bdbdbd') }} />
                      </Button>
                    );
                  } else if (buttonKey === "apply") {
                    return (
                      <Button
                        onClick={(event) => props.action.onClick(event, props.data)}
                        variant="outlined"
                        size="small"
                        color="primary"
                        title={props.action.tooltip}
                        className={classes.actionButton}
                      >
                        <GetAppIcon style={{ color: '#10c5ff' }} />
                      </Button>
                    );
                  } else if (buttonKey === "edit") {
                    return (
                      <Button
                        onClick={(event) => props.action(props.data).onClick(event, props.data)}
                        variant="outlined"
                        size="small"
                        color="primary"
                        title="Editar formato"
                        disabled={!active}
                        className={classes.actionButton}
                      >
                        <EditIcon style={{ color: '#626262' }} />
                      </Button>
                    );
                  } else if (buttonKey === "delete") {
                    return (
                      <Button
                        onClick={(event) => props.action(props.data).onClick(event, props.data)}
                        variant="outlined"
                        size="small"
                        color="primary"
                        title="Eliminar formato"
                        disabled={!active}
                        className={classes.actionButton}
                      >
                        <DeleteOutlineIcon style={{ color: '#626262' }} />
                      </Button>
                    );
                  } else if (typeof buttonKey !== "string") {
                    buttonKey = props.action.tooltip;
                    return (
                      <Button
                        onClick={(event) => props.action.onClick(event, props.data)}
                        variant="outlined"
                        size="small"
                        color="primary"
                        title={props.action.tooltip}
                        className={classes.actionButton}
                      >
                        {buttonKey === "Nuevo" ? (
                          <AddBoxIcon style={{ color: '#626262' }} />
                        ) : buttonKey === "Confirmar" ? (
                          <CheckIcon style={{ color: '#626262' }} />
                        ) : (
                          <CloseIcon style={{ color: '#626262' }} />
                        )
                        }
                      </Button>
                    );
                  }
                }
              }}
              customOptions={{
                thirdSortClick: false,
                sorting: true,
                grouping: false,
                draggable: false,
                search: false,
                paging: true,
                toolbar: true,
                showTitle: false,
                maxBodyHeight: 320,
                addRowPosition: 'first',
                pageSize: 10,
                pageSizeOptions: [10, 20, 50]
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography style={{ fontSize: 'x-small' }}>
              *Tildando esta opción, usted estará compartiendo el formato con toda la organización, para que puedan acceder a visualizarlo.
          </Typography>
        </Grid>
        <Grid item xs={12} container display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
          <Grid item xs={6} md={3}>
            <ButtonPivotGrid
              tooltipTitle="Cerrar ventana"
              buttonSize="small"
              text="Cerrar"
              disabled={false}
              onClick={onCancelClick}
              loading={false}
              icon={<CloseOutlinedIcon fontSize="small" />}
              backgroundImage="linear-gradient(45deg, #fbb040, #f15a29)"
            />
          </Grid>
        </Grid>
      </Grid>
      {actualizarFormatoConfirmDialog()}
    </form>
  )
}

export default AdministrarFormato;