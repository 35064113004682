import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomPivotGrid from '../../../UI/CustomPivotGrid/CustomPivotGrid';
import { storageEmpresaId, storageMonedaSigno, storageUsuarioId } from '../../../../assets/shared/sessionData';
import { Typography, Grid } from '@mui/material';
import { formatNumber } from '../../../../assets/shared/formatNumber';
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import { pivotGridFormat } from '../../../../assets/shared/pivotGridDefaults/ventasPorTipoPagoFormat';
import PivotGridFiltroVentas from '../PivotGridFiltroVentas/PivotGridFiltroVentas';
import {
  loadVentasPorTipoPago, updateVentasPorTipoPagoFiltros, updateConfiguracionFields,
  updateSelectedTotalRows, updateVentasPorTipoPagoData
} from '../../../../store/slices/pivotGrid/pivotGridDataSlice';
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const PivotGridVentasPorTipoPago = () => {
  const dispatch = useDispatch();
  const [signoMoneda] = useState(storageMonedaSigno());
  const [storageKey] = useState("ventas-por-tipo-pago-pivot-grid");
  const [pivotGridConfig] = useState(pivotGridFormat(signoMoneda));
  const [customLoading, setCustomLoading] = useState(false);
  const [nombreFormato, setNombreFormato] = useState("Último formato utilizado");
  const [configFields, setConfigFields] = useState();
  const [rowsColumnsFixed, setRowsColumnsFixed] = useState(true);
  const [gridContainerOverflowX, setGridContainerOverflowX] = useState("hidden");
  const [selectedTotalRows, setSelectedTotalRows] = useState([]);
  const rowFieldsFromDataSource = useRef();
  const [scrollingMode, setScrollingMode] = useState("standard");

  //#region Dispatches
  const onUpdateSelectedTotalRows = useCallback((key, rows) => {
    dispatch(updateSelectedTotalRows(key, rows))
  }, [dispatch]);
  const onLoadData = useCallback((request, config) => {
    dispatch(loadVentasPorTipoPago({ request, config }));
  }, [dispatch]);
  const onLoadDataImport = useCallback((request, onSuccess, onFail) => {
    dispatch(loadVentasPorTipoPago(request, onSuccess, onFail));
  }, [dispatch]);
  const onUpdateData = useCallback((data, config, filtros) => {
    dispatch(updateVentasPorTipoPagoData(data, config, filtros));
  }, [dispatch]);
  const onUpdateConfigFields = useCallback((key, config) => {
    dispatch(updateConfiguracionFields(key, config));
  }, [dispatch]);
  const onUpdateFiltros = useCallback((idArchivo, fechaDesde, fechaHasta, anioComparacion) => {
    dispatch(updateVentasPorTipoPagoFiltros(idArchivo, fechaDesde, fechaHasta, anioComparacion));
  }, [dispatch]);

  const { loading, datos, config } = useSelector(state => state.pivotGridData.ventasPorTipoPagoData);
  const filtros = useSelector(state => state.pivotGridData.ventasPorTipoPagoFiltros);
  const pivotGridConfigFields = useSelector(state => state.pivotGridData.pivotGridConfiguracionFields.items);
  const selectedTotalRowsItems = useSelector(state => state.pivotGridData.selectedTotalRows.items);
  //#endregion

  const title = () => {
    return (
      <Typography variant="h5">
        {`VENTAS: ${nombreFormato}`}
      </Typography>
    )
  }

  const subTitle = () => {
    return (
      <Grid item xs={12} container spacing={1} justifyContent="flex-start">
        <PivotGridFiltroVentas
          aplicarFiltros={onAplicarFiltroClick}
          onUpdateFiltros={onUpdateFiltros}
        />
      </Grid>
    )
  }

  const onCellClick = (e) => { }
  const rawData = useMemo(() => { return datos; }, [datos]);
  const data = useCallback(() => { return rawData; }, [rawData]);

  const rawFields = useMemo(() => {
    return config && config.map((field) => {
      let item = { ...field };
      let fieldFormat = pivotGridConfig.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (fieldFormat) {
        if (fieldFormat.caption)
          item["caption"] = fieldFormat.caption;

        if (fieldFormat.isMeasure !== undefined)
          item["isMeasure"] = fieldFormat.isMeasure;

        if (fieldFormat.format !== undefined)
          item["format"] = fieldFormat.format;

        if (fieldFormat.allowFiltering !== undefined)
          item["allowFiltering"] = fieldFormat.allowFiltering;

        if (fieldFormat.allowSorting !== undefined)
          item["allowSorting"] = fieldFormat.allowSorting;

        if (fieldFormat.allowSortingBySummary !== undefined)
          item["allowSortingBySummary"] = fieldFormat.allowSortingBySummary;
        if (fieldFormat.customizeText !== undefined)
          item["customizeText"] = fieldFormat.customizeText;
      }

      item["hide"] = selectedTotalRows.find((row) => row.id === field.dataField) === undefined;

      return item;
    });
  }, [config, selectedTotalRows, pivotGridConfig]);
  const fields = useCallback(() => { return rawFields; }, [rawFields]);

  const onAplicarFiltroClick = (desde, hasta, anioComparacion) => {
    let localConfig = [];
    if (dataSource) {
      localConfig = dataSource.fields();
    } else {
      let item = localStorage.getItem(storageKey);
      localConfig = item ? JSON.parse(item).fields : config;
    }

    localStorage.removeItem(storageKey);

    let formato = processFormato(localConfig);

    const request = {
      empresaId: storageEmpresaId(),
      idUsuario: storageUsuarioId(),
      importacion: false,
      semanaDesde: desde ? moment(desde).week() : 0,
      semanaHasta: hasta ? moment(hasta).week() : 0,
      anioDesde: desde ? desde.getFullYear() : new Date().getFullYear(),
      anioHasta: hasta ? hasta.getFullYear() : new Date().getFullYear(),
      desde: desde ? desde.toDateString() : null,
      hasta: hasta ? hasta.toDateString() : null,
      anioComparacion,
    }

    onLoadData(request, formato);
  }

  const customizeTooltip = (args) => {
    const valueText = args.seriesName.indexOf('%') !== -1
      ? `${formatNumber(args.originalValue)}%`
      : args.seriesName.indexOf('unidades') !== -1
        ? args.originalValue
        : `${signoMoneda} ${formatNumber(args.originalValue)}`;

    return {
      html: `${args.seriesName}<div style='text-align: center;'>${valueText}</div>`,
    };
  }

  const [isLoadingDataSource] = useState([false]);
  const dataSource = new PivotGridDataSource({
    fields: fields(),
    store: {
      type: "array",
      data: data()
    },
    onLoadingChanged: (isLoading) => {
      if (!isLoading) {
        const fields = dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'row');
        rowFieldsFromDataSource.current = fields;
      }
    },
    retrieveFields: false,
  });

  const onUpdateDataSource = () => { }

  const processFormato = useCallback((formato) => {
    let formatoResult = [];
    formato && formato.forEach((field) => {
      let item = pivotGridConfig.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (item) {
        let tempField = {
          ...field,
          caption: item.caption,
          dataType: item.dataType,
          name: item.name,
          summaryType: '',
          area: ''
        };

        if (item.summaryType)
          tempField.summaryType = item.summaryType;

        if (!field.area)
          tempField.area = "filter";

        formatoResult.push(tempField);
      } else {
        formatoResult.push(field);
      }
    });

    pivotGridConfig.forEach((field) => {
      let item = formato && formato.find((format) => format.name === field.name
        || (format.dataField && format.dataField === field.dataField));

      if (!item) {
        field.area = "filter";
        formatoResult.push(field);
      }
    })

    return formatoResult;
  }, [pivotGridConfig]);

  const onCargarFormato = useCallback((formato, nombreFormato, items = undefined) => {
    setNombreFormato(nombreFormato);
    formato = processFormato(formato);
    setEstaCargado(false);
    const datosCubo = items !== undefined ? items : datos;
    onUpdateData(datosCubo, formato, filtros);
  }, [datos, filtros, onUpdateData, processFormato]);

  //#region Overflow X

  const setOverflowX = (modoConfiguracion, fijarFilasColumnas) => {
    const overflowX = modoConfiguracion ? "auto" : fijarFilasColumnas ? "hidden" : "auto";
    setGridContainerOverflowX(overflowX);
  }

  //#endregion

  //#region Cambio Vista
  const orderedFields = useMemo(() => {
    return dataSource && dataSource.fields() && dataSource.fields().filter((field) => field.area === 'filter')
      .map((field) => {
        return {
          caption: field.caption && field.caption.replace('|', '') && field.caption.replace('-', ''),
          name: field.name,
        }
      })
      .sort((a, b) => { return a.caption < b.caption ? -1 : 1; });
  }, [dataSource]);

  const onViewChange = useCallback((config) => {
    setCustomLoading(true);
    setConfigFields(config);
    onUpdateConfigFields(storageKey, config);
    setOverflowX(config.showFields, rowsColumnsFixed);
    setTimeout(() => setCustomLoading(false), 500);
  }, [storageKey, rowsColumnsFixed, onUpdateConfigFields]);
  //#endregion

  //#region Fijar Filas y Columnas
  const onFixRowsColumns = useCallback(() => {
    let value = !rowsColumnsFixed;

    if (value && configFields && configFields.showFields) {
      setConfigFields({
        showFields: false,
        showColums: false,
        showData: false,
        showFilter: false,
        showRows: false,
        visible: false,
      });
    }

    setCustomLoading(true);
    setRowsColumnsFixed(value);
    setOverflowX(configFields.showFields, value);
    let scroll = value ? "virtual" : "standard";
    setScrollingMode(scroll);
    setTimeout(() => setCustomLoading(false), 500);
  }, [rowsColumnsFixed, configFields]);
  //#endregion

  //#region Customization

  //#region On Cell Prepared
  const isTotalDataRow = (cell) => (cell.area === 'data' && cell.rowType === 'T'
    && (cell.columnType === 'T' || cell.columnType === 'GT'));
  const lessThanCeroCell = (cell) => cell.area === 'data' && cell.rowType === 'D'
    && cell.columnType === 'GT' && cell.dataType === 'number' && cell.value && cell.value < 0;
  const isHeader = (cell) => cell.area === 'column' && cell.type === 'GT';
  const isTotalRow = (cell) => cell.area === 'row' && (cell.type === 'T' || cell.type === 'GT');
  const isGranTotal = (cell) => (cell.area === 'data' && cell.rowType === 'GT' && cell.columnType === 'GT')
    || (cell.area === 'column' && cell.type === 'GT' && cell.text === 'Gran Total');

  const hideStyle = useMemo(() => { return { fill: 'FFF', font: 'FFF', bold: true, border: null, display: 'none' } }, []);
  const style = useMemo(() => { return { 'font-size': 'small', 'text-transform': 'uppercase' } }, []);

  const specialChars = useMemo(() => { return ['| ', '- '] }, []);

  const containsSpecialChars = useCallback((cellText) => {
    let contains = false;

    if (cellText) {
      specialChars.forEach((item) => {
        if (cellText.includes(item))
          contains = true;
      })
    }
    return contains;
  }, [specialChars]);

  const removeSpecialChars = (cellText) => {
    if (cellText) {
      cellText = cellText.replace('| ', '');
      cellText = cellText.replace('- ', '');
    }

    return cellText;
  }

  const getCssStyles = ({ fill, font, bold, border }) => {
    let style = {
      'background-color': `#${fill}`,
      color: `#${font}`,
      'font-weight': bold ? 'bold' : undefined,
      'font-size': 'smaller',
      'text-transform': 'uppercase',
    };

    if (border)
      style['border-color'] = `#${border}`;

    return style;
  }

  const getConditionalAppearance = useCallback((cell, columnIndex, rows) => {
    if (isGranTotal(cell) || isTotalRow(cell) || isTotalDataRow(cell))
      return { fill: 'ffbf66', font: '3F3F3F', bold: true, border: null };

    if (isHeader(cell))
      return { fill: 'dbf9ff', font: '3F3F3F', bold: true, border: null };

    if (lessThanCeroCell(cell))
      return { fill: 'ffafaf', font: '3F3F3F', bold: true, border: null };

    return { fill: 'FFFFFF', font: '3F3F3F', bold: false, border: null };
  }, []);

  const onCellPrepared = useCallback((e) => {
    const { area, cellElement, cell, columnIndex } = e;

    cell.area = area;

    if (cell.area === 'column' && cell.type === 'GT' && containsSpecialChars(cell.text)) {
      cell.text = removeSpecialChars(cell.text);
      cellElement.innerHTML = removeSpecialChars(cellElement.innerHTML);
      cellElement.innerText = removeSpecialChars(cellElement.innerText);
    }

    let defaultStyle = style;
    const appearance = getConditionalAppearance(cell, columnIndex, selectedTotalRows);

    if (appearance)
      defaultStyle = getCssStyles(appearance);

    if ((cell.path && isTotalRow(cell)) || (cell.rowPath && isTotalDataRow(cell))) {
      let rowFields = rowFieldsFromDataSource.current;
      let pathIndex = cell.path ? cell.path.length - 1 : cell.rowPath.length - 1;
      let rowField = rowFields.find((row) => row.areaIndex === pathIndex);

      if (rowField && rowField.hide)
        defaultStyle = hideStyle;
    }

    Object.assign(cellElement.style, defaultStyle);
  }, [containsSpecialChars, getConditionalAppearance, hideStyle, selectedTotalRows, style]);

  //#endregion

  //#region Data Chart 
  const chartFields = useMemo(() => {
    return pivotGridConfig && pivotGridConfig
      .filter((f) => f.caption !== null && f.caption !== undefined)
      .sort((a, b) => a.caption.localeCompare(b.caption));
  }, [pivotGridConfig]);
  const chartOptions = chartFields && chartFields.filter((f) => f.area === "row");
  const chartSeries = chartFields && chartFields.filter((f) => f.area === "data");
  const chartFilters = [];

  const chartConfig = {
    fields: chartFields,
    options: chartOptions,
    series: chartSeries,
    filters: chartFilters,
    data: datos,
    extraFilters: [],
    relatedFilter: true,
  };

  //#endregion

  //#region Mostrart Total por row
  const [estaCargado, setEstaCargado] = useState(false);

  const onSelectedTotalRowsChange = useCallback((items) => {
    setCustomLoading(true);
    setSelectedTotalRows(items);
    onUpdateSelectedTotalRows(storageKey, items);
    setTimeout(() => setCustomLoading(false), 500);
  }, [storageKey, onUpdateSelectedTotalRows]);

  //#endregion

  //#region On Content Ready 
  const dataConfigFields = useMemo(() => { return pivotGridConfig.filter((item) => item.area === "data"); }, [pivotGridConfig]);

  const onContentReady = useCallback((e) => {
    let ds = e.component.getDataSource();
    let rowFields = ds.fields() && ds.fields().filter((item) => item.area === "row");
    rowFieldsFromDataSource.current = rowFields;

    if (rowFields && rowFields.length > 0 && dataConfigFields && dataConfigFields.length > 0) {
      let reload = false;

      rowFields.forEach((field) => {
        const dataField = dataConfigFields.find((item) => item.name === field.name);

        if (dataField) {
          ds.field(field.name, { area: "filter" });

          if (!reload)
            reload = true;
        }
      });

      if (reload)
        ds.load();
    }

    isLoadingDataSource[0] = false;
  }, [dataConfigFields, isLoadingDataSource]);

  //#endregion

  //#endregion

  //#endregion UseEffect Methods  

  useEffect(() => {
    if (!config && !loading) {
      let fields = pivotGridConfig;
      let dataStorage = localStorage.getItem(storageKey);

      if (dataStorage) {
        let configStorage = JSON.parse(dataStorage);

        if (configStorage && configStorage.fields && configStorage.fields.length > 0)
          fields = configStorage.fields;
      }

      onCargarFormato(fields, "Último formato utilizado");
    }
  }, [config, loading, onCargarFormato, pivotGridConfig, storageKey]);

  useEffect(() => {
    if (selectedTotalRowsItems && selectedTotalRowsItems.length > 0 && !estaCargado) {
      const item = selectedTotalRowsItems.find((i) => i.key === storageKey);

      if (item) {
        onSelectedTotalRowsChange(item.rows);
        setEstaCargado(true);
      }
    }
  }, [selectedTotalRowsItems, estaCargado, onSelectedTotalRowsChange, storageKey]);

  useEffect(() => {
    let rowFields = rowFieldsFromDataSource.current;

    if (rowFields && rowFields.length !== 0) {
      let expandedFields = rowFields.filter((field) => field.expanded);

      if (!expandedFields || expandedFields.length === 0) {
        let fieldName = rowFields[0].name;
        dataSource.field(fieldName, { expanded: true });
      }
    }
  }, [rowFieldsFromDataSource, dataSource]);

  useEffect(() => {
    if (!configFields) {
      const item = pivotGridConfigFields.find((c) => c.key === storageKey);
      const cf = item
        ? item.config
        : {
          showFields: false,
          visible: false,
          showRows: false,
          showFilter: false,
          showData: false,
          showColums: false
        };
      setConfigFields(cf);
    }
  }, [configFields, pivotGridConfigFields, storageKey]);

  useEffect(() => {
    if (configFields && configFields.showFields && orderedFields && orderedFields.length > 0) {
      orderedFields.forEach((field, index) => { dataSource.field(field.name, { areaIndex: index }); });
      dataSource.load();
    }
  }, [configFields, orderedFields, dataSource]);

  //#endregion

  return (
    <CustomPivotGrid
      title={() => title()}
      subTitle={() => subTitle()}
      dataSource={dataSource}
      loading={loading || customLoading}
      rawDatos={datos}
      rawFiltros={filtros}
      resetButton={false}
      exportToExcel={true}
      exportToPdf={true}
      fileName={'Informe 1'}
      sheetName={"Informe1"}
      storageKey={storageKey}
      onCellClick={onCellClick}
      onCellPrepared={onCellPrepared}
      showConfig={true}
      customizeTooltip={customizeTooltip}
      onUpdateDataSource={onUpdateDataSource}
      onCargarFormato={onCargarFormato}
      configFields={configFields}
      onContentReady={onContentReady}
      chartConfig={chartConfig}
      onViewChange={onViewChange}
      showChart={true}
      showChartIcon={false}
      rowsColumnsFixed={rowsColumnsFixed}
      onFixRowsColumns={onFixRowsColumns}
      height={rowsColumnsFixed ? "750px" : "100%"}
      containerWidth={rowsColumnsFixed ? window.innerWidth < 1350 ? "92.5vw" : window.innerWidth < 1900 ? "93.5vw" : "94.5vw" : "100%"}
      gridWidth={"100%"}
      minHeight={450}
      gridContainerOverflowX={gridContainerOverflowX}
      onLoadDataImport={onLoadDataImport}
      selectedTotalRows={selectedTotalRows}
      onSelectedTotalRowsChange={onSelectedTotalRowsChange}
      totalRowsOptions={rowFieldsFromDataSource && rowFieldsFromDataSource.current}
      isLoadingDataSource={isLoadingDataSource && isLoadingDataSource[0]}
      scrollingMode={scrollingMode}
    />
  )
}

export default PivotGridVentasPorTipoPago;