import React from 'react';
import { IconButton } from '@mui/material';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import IconConvertToExcel from '../../../../assets/images/IconConvertToExcel.png';

const ExportPivotGridToExcelIcon = (props) => {
  const { pivotGridRef, sheetName, fileName, disabled } = props;

  function getExcelCellFormat({ fill, font, bold }) {
    return {
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: fill } },
      font: { color: { argb: font }, bold },
      alignment: { horizontal: 'center', vertical: 'top', wrapText: true },
    };
  }

  const onExporting = (e) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(sheetName);

    if (!pivotGridRef || !pivotGridRef.current || !pivotGridRef.current.instance)
      return;

    const dataSource = pivotGridRef.current.instance.getDataSource();
    let rowFields = [];
    let dataFields = [];

    if (dataSource) {
      const fields = dataSource._fields;

      if (fields && fields.length !== 0) {
        rowFields = fields.filter((field) => field.area === "row");
        dataFields = fields.filter((field) => field.area === "data");

        if ((rowFields && rowFields.length !== 0) || (dataFields && dataFields.length !== 0)) {
          const columns = [];

          rowFields.forEach(element => {
            columns.push({ width: element.width ? element.width : 25 });
          });

          dataFields.forEach(element => {
            columns.push({ width: element.width ? element.width : 25 });
          });

          worksheet.columns = columns;
        }
      }
    }

    exportPivotGrid({
      component: pivotGridRef.current.instance,
      worksheet: worksheet,
      topLeftCell: { row: 3, column: 1 },
      keepColumnWidths: false,
      exportDataFieldHeaders: false,
      exportRowFieldHeaders: true,
      exportColumnFieldHeaders: false,
      exportFilterFieldHeaders: false,
      customizeCell: ({ pivotCell, excelCell }) => {
        const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
        excelCell.border = {
          bottom: borderStyle,
          left: borderStyle,
          right: borderStyle,
          top: borderStyle,
        };
      },
    })
      .then((cellRange) => {
        const headerRow = worksheet.getRow(4);
        const firstHeader = rowFields[0].caption;
        const appearance = { fill: 'F2F2F2', font: '3F3F3F', bold: true };
        let totalFields = dataFields.length;

        rowFields.forEach(element => {
          const headerCell = headerRow.getCell(element.areaIndex + 1);

          if (headerCell && headerCell.value && typeof headerCell.value === 'string') {
            if(headerCell.value.indexOf(firstHeader) > -1) {
              headerCell.value = element.caption.replace('-', '');
              totalFields += 1;
            }else{
              headerCell.value = headerCell.value.replace('-', '');
            }
          }
        });

        for (let index = 1; index <= totalFields; index++) {
          const headerCell = headerRow.getCell(index);

          if (headerCell) {
            Object.assign(headerCell, getExcelCellFormat(appearance));
          }
        }
      })
      .then(function () {
        workbook.xlsx.writeBuffer()
          .then(function (buffer) {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
          });
      });
    e.cancel = true;
  }

  return (
    <span title={disabled ? "Descargar en Excel (no hay datos)" : "Descargar en Excel"}>
      <IconButton disabled={disabled} onClick={(e) => onExporting(e)} style={{ marginRight: '10px' }} size="small">
        <img alt="" src={IconConvertToExcel} height={'35px'} />
      </IconButton>
    </span>
  )
}

export default ExportPivotGridToExcelIcon;