/*eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { seleccionarMoneda, loadMonedas } from '../../../store/slices/monedaSlice';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Spinner from '../Spinner/Spinner';
import { storageMonedaId, storageMonedaSigno, } from "../../../assets/shared/sessionData";

const MonedaSelector = (props) => {
  const dispatch = useDispatch();
  const onLoadMonedas = () => dispatch(loadMonedas());
  const onSeleccionarMoneda = (monedaId) => dispatch(seleccionarMoneda(monedaId));
  const [monedaId, setMonedaId] = useState(storageMonedaId() !== null ? storageMonedaId() : '');
  const monedas = useSelector(state => state.monedas.monedas);
  const loading = useSelector(state => state.monedas.loading);
  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    if (!loading && monedas.length === 0 && token !== null) 
      onLoadMonedas();
  }, [loading, monedas, token]);

  return loading ? (
    <Spinner personalSize="35px" />
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontSize: '20px' }}>{storageMonedaSigno()}</div>
      <FormControl style={{ margin: '0px 10px 0px 10px' }}>
        <Select
          style={{ minWidth: 120, color: props.letraColor }}
          labelId="monedaLabel"
          id="monedaSelect"
          value={monedas.length !== 0 ? monedaId : ''}
          onChange={(event) => {
            setMonedaId(event.target.value);
            onSeleccionarMoneda(event.target.value);
          }}
          variant='standard'          
          disabled={props.disabled ? props.disabled : false}
        >
          {monedas.map((moneda) => (
            <MenuItem key={moneda.idMoneda} value={moneda.idMoneda}>
              {moneda.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MonedaSelector;